import { Grid, IconButton, Modal } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  Button,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Select,
  Typography,
  Upload
} from "antd";
import Axios from "axios";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addInstitutionSurvey } from "../../../actions/instituteAction";
import isEmpty from "../../../utils/validation/is-empty";
import { selectedLanguage, ward } from "../../../variable/global";
import {
  add,
  address,
  count,
  type,
  instituteTitle,
  bankType,
  workingField,
  instituteHead,
  instituteService,
  name,
  phone,
  photo,
  position,
  mainArea,
  investment,
  annualTransaction,
  wardQuestion
} from "../../../variable/institute";
import { getBank } from "./api";

const { TextArea } = Input;
const { Option } = Select;

class Bank extends Component {
  random = (low, high) => {
    return Math.random() * (high - low) + low;
  };
  state = {
    // image upload state
    uniqueIdentifier:
      this.props.match.params.category +
      "_" +
      this.random(10000, 99999) +
      this.random(1000, 9999),
    previewVisible: false,
    previewImage: "",
    fileList: {
      instituteImage: []
    },
    loaded: 0,
    // other
    name: "",
    address: "",
    photo: "",
    ward: "",
    bankType: "",
    presidentName: "",
    presidentContact: "",
    bankService: "",
    investment: "",
    annualTransaction: "",
    area:""
  };

  // image functions uplocad
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true
    });
  };

  // imageUpload
  handleChangeFile = (name, value, imageName) => {
    //name ma house and value ma list of filelist
    console.log("CF", value);
    this.setState(
      prevState => ({
        ...prevState,
        fileList: {
          ...prevState.fileList,
          [name]: {
            image: value.fileList,
            fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg"
          }
        }
      }),
      () => {
        if (value.fileList.length > 0) this.handleUpload(name, imageName);
        if (value.fileList.length === 0) this.removeUpload(name, imageName);
      }
    );
  };

  // upload image
  handleUpload = (name, imageName) => {
    const fileList = this.state.fileList[name];
    const data = new FormData();
    const imageObject = fileList.image[0].originFileObj;

    let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
    data.append("file", imageObject, fileName);

    Axios.post("/api/imageUpload/upload", data, {
      onUploadProgress: ProgressEvent => {
        this.setState({
          loaded: Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
        });
      }
    })
      .then(res => {
        // then print response status
        message.success("upload successfully.");
        console.log(res.statusText);
      })
      .catch(err => message.error("upload failed."));
  };

  // remove uploaded image
  removeUpload = imageName => {
    let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

    Axios.get(`/api/imageUpload/delete/${fileName}`)
      .then(res => {
        // then print response status
        message.success("deleted successfully.");
        console.log(res.statusText);
      })
      .catch(err => message.error("upload failed."));
  };

  componentDidMount() {
    let bankId = this.props.match.params.id;
    if (bankId) {
      getBank(bankId).then(res =>
        this.setState({
          name: res.data.name,
          address: res.data.address,
          bankType: res.data.bankType,
          presidentName: res.data.president.name,
          presidentContact: res.data.president.contact,
          ward: res.data.ward,
          area: res.data.area,
          investement: res.data.investement,
          annualTransaction: res.data.annualTransaction,



          bankService: res.data.bankService,
          // image
          fileList: {
            tourism: {
              fileName: res.data.uniqueIdentifier + "_bank.jpg",
              image: [
                {
                  uid: "-1",
                  name: `${res.data.uniqueIdentifier}_bank.jpg`,
                  status: "done",
                  url: `/api/imageUpload/image/${res.data.uniqueIdentifier}_bank.jpg`
                }
              ]
            }
          }
        })
      );
    }
  }

  onSubmit = event => {
    event.preventDefault();
    const newInstitute = {
      name: this.state.name,
      uniqueIdentifier: this.state.uniqueIdentifier,
      ward: this.state.ward,
      address: this.state.address,
      bankType: this.state.bankType,
      presidentName: this.state.presidentName,
      presidentContact: this.state.presidentContact,
      bankService: this.state.bankService,
      area: this.state.area,
      investment: this.state.investment,
      annualTransaction: this.state.annualTransaction,

    };

    if (this.props.isUpdate) {
      newInstitute.id = this.props.match.params.id;
      this.props.addInstitutionSurvey(
        "bank",
        newInstitute,
        this.props.history,
        this.props.match.params.category
      );
    } else
      this.props.addInstitutionSurvey(
        "bank",
        newInstitute,
        this.props.history,
        this.props.match.params.category
      );
  };
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };
  handleSelectChange = (name, value) => {
    console.log("handle ", name, value);
    this.setState({
      [name]: value
    });
  };

  render() {
    const { classes, title } = this.props;
    const { previewVisible, previewImage } = this.state;
    const fileList = this.state.fileList;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <Paper className={classes.root} elevation={1}>
        <Typography variant="h5">{title}</Typography>
        <Form onSubmit={this.onSubmit}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item xs={6}>
              <Form.Item label={name[selectedLanguage]}>
                <Input
                  onChange={this.handleChange("name")}
                  value={this.state.name}
                  disabled={this.state.isView}
                />
              </Form.Item>
            </Grid>

            <Grid item xs={6}>
              <Form.Item label={address[selectedLanguage]}>
                <Input
                  onChange={this.handleChange("address")}
                  value={this.state.address}
                  disabled={this.state.isView}
                />
              </Form.Item>
            </Grid>

            <Grid item xs={6}>
              <Form.Item label={wardQuestion[selectedLanguage]}>
                <Select
                  placeholder="------------"
                  onChange={val => this.handleSelectChange("ward", val)}
                  value={this.state.ward}
                  disabled={this.state.isView}
                >
                  {ward.map(woda => (
                    <Option value={woda.value}>{woda.ward}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Grid>

            <Grid item xs={6}>
              <Form.Item label={type[selectedLanguage]}>
                <Select
                  placeholder="------------"
                  onChange={val => this.handleSelectChange("bankType", val)}
                  value={this.state.bankType}
                  disabled={this.state.isView}
                >
                  {bankType.map(type => (
                    <Option value={type.value}>{type[selectedLanguage]}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Grid>

            
            <Grid item xs={6}>
              <Form.Item label={instituteService[selectedLanguage]}>
                <TextArea
                  onChange={this.handleChange("bankService")}
                  value={this.state.bankService}
                />
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item label={annualTransaction[selectedLanguage]}>
                <Input
                  type="number"
                  min={0}
                  addonAfter="रु.हजारमा"
                  onChange={this.handleChange("annualTransaction")}
                  value={this.state.annualTransaction}
                />
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item label={investment[selectedLanguage]}>
                <Input
                  type="number"
                  min={0}
                  addonAfter="रु."
                  onChange={this.handleChange("investment")}
                  value={this.state.investment}
                />
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item label={mainArea[selectedLanguage]}>
                <Input
                 
                  onChange={this.handleChange("area")}
                  value={this.state.area}
                />
              </Form.Item>
            </Grid>

            <Divider orientation="left">
              {instituteHead[selectedLanguage]}
            </Divider>
            <Grid item xs={6}>
              <Form.Item label={name[selectedLanguage]}>
                <Input
                  onChange={this.handleChange("presidentName")}
                  value={this.state.presidentName}
                />
              </Form.Item>
            </Grid>

            <Grid item xs={6}>
              <Form.Item label={phone[selectedLanguage]}>
                <Input
                  type="number"
                  min={0}
                  onChange={this.handleChange("presidentContact")}
                  value={this.state.presidentContact}
                />
              </Form.Item>
            </Grid>

            <Divider />

            <Grid item xs={6}>
              <Form.Item label={photo[selectedLanguage]}>
                <Fragment>
                  {!this.state.isView ? (
                    <Upload
                      name="file"
                      // beforeUpload={this.props.beforeUpload}
                      multiple={false}
                      // action="/api/imageUpload/upload"
                      accept="image/*"
                      listType="picture-card"
                      fileList={
                        fileList.instituteImage && fileList.instituteImage.image
                      }
                      onPreview={this.handlePreview}
                      onChange={fileList =>
                        this.handleChangeFile(
                          "instituteImage",
                          fileList,
                          "bank"
                        )
                      }
                      disabled={this.state.isView}
                    >
                      {fileList &&
                      fileList.instituteImage &&
                      fileList.instituteImage.image &&
                      fileList.instituteImage.image.length === 1
                        ? null
                        : uploadButton}
                    </Upload>
                  ) : (
                    <Modal
                      visible={previewVisible}
                      footer={null}
                      onCancel={this.handleCancel}
                    >
                      <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={previewImage}
                      />
                    </Modal>
                  )}
                </Fragment>
              </Form.Item>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={this.state.isView}
              >
                {add[selectedLanguage]}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Paper>
    );
  }
}

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  }
});

const mapStateToProps = state => ({});

export default withStyles(styles)(
  connect(mapStateToProps, { addInstitutionSurvey })(withRouter(Bank))
);
