// @material-ui/icons
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import MapIcon from "@material-ui/icons/Map";
import LogIcon from "@material-ui/icons/Description";
import { gaupalikaWard, selectedLanguage } from "../../variable/global";
import Home from "../dashboard/home/Home";
import UserProfile from "../dashboard/user";
import Log from "../dashboard/log";
import Report from "../dashboard/report";
// import ToleReport from "../dashboard/report/toleReport";
import SearchIcon from "@material-ui/icons/Search";
import HouseSurveyIcon from "@material-ui/icons/Receipt";
import ErrorIcon from "@material-ui/icons/Error";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import InstitutionSurveyIcon from "@material-ui/icons/AccountBalance";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";
import HouseSurvey from "../dashboard/houseSurvey/";
import DaughterMarriage from "../dashboard/daughterMarriage/";
import InstitutionSurvey from "../dashboard/institutionSurvey/";
const dashboardRoutes = [
	{
		path: "/home",
		exact: true,
		hasChild: false,
		sidebarName: "गृह पृष्ठ",
		navbarName: gaupalikaWard[selectedLanguage],
		icon: HomeIcon,
		component: Home,
	},
	// {
	// 	sidebarName: "खोज्नुहोस",
	// 	icon: SearchIcon,
	// 	hasChild: true,
	// 	child: [
	// 		{
	// 			path: "/advance-search-house",
	// 			sidebarName: "घर विस्तृत खोजी",
	// 			navbarName: "घर विस्तृत खोजी",
	// 			icon: PersonIcon,
	// 			component: UserProfile,
	// 		},
	// 		{
	// 			path: "/search-by-death",
	// 			sidebarName: "मृतकको खोजी",
	// 			navbarName: "मृतकको खोजी",
	// 			icon: PersonIcon,
	// 			component: Home,
	// 		},
	// 	],
	// },
	{
		path: "/advance-search-house",
		hasChild: false,
		sidebarName: "घर विस्तृत खोजी",
		navbarName: "घर विस्तृत खोजी",
		icon: SearchIcon,
		component: UserProfile,
	},

	{
		path: "/survey-by-house",
		hasChild: false,
		sidebarName: "घर सर्वेक्षण",
		navbarName: "घर सर्वेक्षण",
		icon: HouseSurveyIcon,
		component: HouseSurvey,
	},
	// {
	// 	path: "/survey-by-daughterMarriage",
	// 	hasChild: false,
	// 	sidebarName: "वालविवाह सर्वेक्षण",
	// 	navbarName: "वालविवाह सर्वेक्षण",
	// 	icon: HouseSurveyIcon,
	// 	component: DaughterMarriage,
	// },
	{
		path: "/survey-by-institution",
		sidebarName: "संस्था सर्वेक्षण",
		navbarName: "संस्था सर्वेक्षण",
		icon: InstitutionSurveyIcon,
		hasChild: false,
	},
	// {
	// 	path: "/vital-registration",
	// 	sidebarName: "घटना दर्ता",
	// 	navbarName: "घटना दर्ता",
	// 	icon: AssignmentTurnedIn,
	// 	hasChild: false,
	// },
	{
		sidebarName: "SMS",
		icon: AnnouncementIcon,
		hasChild: true,
		child: [
			{
				path: "/sms/home",
				exact: true,
				sidebarName: "Home Page",
				navbarName: "Smart SMS",
				icon: HomeIcon,
			},
			{
				path: "/sms/message",
				sidebarName: "Message",
				navbarName: "Message",
				icon: PersonIcon,
				exact: true,
			},
			{
				path: "/sms/desgination",
				sidebarName: "Designation",
				navbarName: "Designation",
				icon: PersonIcon,
				exact: true,
			},
			{
				path: "/sms/people",
				sidebarName: "People",
				navbarName: "People",
				icon: PersonIcon,
				exact: true,
			},
			{
				path: "/sms/group",
				sidebarName: "Group",
				navbarName: "Group",
				icon: PersonIcon,
				exact: true,
			},
		],
	},
	{
		path: "/users",
		hasChild: false,
		sidebarName: "प्रयोगकर्ता",
		navbarName: "प्रयोगकर्ता",
		icon: HomeIcon,
		component: UserProfile,
	},
	{
		path: "/log",
		hasChild: false,
		sidebarName: "लग",
		navbarName: "लग",
		icon: HomeIcon,
		component: Log,
	},
	{
		sidebarName: "विवरण",
		icon: AnnouncementIcon,
		hasChild: true,
		child: [
			{
				path: "/report",
				sidebarName: "घरधुरी तथा पारिवारिक विवरण",
				navbarName: "घरधुरी तथा पारिवारिक विवरण",
				icon: PersonIcon,
				exact: true,
			},
			{
				path: "/agriculture-livestock-report",
				sidebarName: "कृषि तथा पशुपन्छी विवरण",
				navbarName: "कृषि तथा पशुपन्छी विवरण",
				icon: PersonIcon,
				exact: true,
			},
		],
	},
	{
		path: "/map",
		sidebarName: "नक्शा",
		navbarName: "नक्शा",
		icon: MapIcon,
		component: Map,
	},

	// { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" }
];

export default dashboardRoutes;
