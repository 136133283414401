import React from "react";
import { Breadcrumb, Col, Icon, Row, Typography } from "antd";
import "./index.css";
import vitalImage from "../../image/vitalRegistration.png";
import bornImage from "../../image/born.png";
import marriageImage from "../../image/marriage.webp";
import divorceImage from "../../image/divorce.webp";
import deathImage from "../../image/death.webp";
import migrationImage from "../../image/migration.png";
import CaretRightOutlined from "@ant-design/icons/CaretRightOutlined";
import { Link } from "react-router-dom";
const { Title } = Typography;
const VitalItem = ({
  item: { title, rightAlignImage, image, description, buttonTitle, id },
}) => {
  return (
    <Row
      type="flex"
      style={{
        marginTop: 120,
        paddingLeft: 20,
      }}
    >
      <Col
        order={rightAlignImage ? 2 : 1}
        span={8}
        style={{
          textAlign: "center",
        }}
      >
        <img
          src={image}
          style={{
            maxWidth: 250,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </Col>

      <Col
        order={rightAlignImage ? 1 : 2}
        span={16}
        style={{
          paddingTop: 20,
        }}
      >
        <Row>
          <Title
            level={3}
            style={{
              fontSize: 28,
              color: "#3d7ec5",
            }}
          >
            {title}
          </Title>
          <p
            style={{
              color: "#767474",
              lineHeight: 1.5,
              fontSize: 19,
              marginTop: "0 !important",
              paddingTop: "0 !important",
              fontSize: 18,
              margin: "0 auto 1.4em",
              fontFamily: "'Barlow', sans-serif",
              lineHeight: 1.4,
              WebkitFontSmoothing: "antialiased",
              marginBottom: 10,
              textAlign: "justify",
            }}
          >
            {description}
          </p>
          <br />
          {id ? (
            <Link to={`/vital-registration/${id}`}>
              <span className="button-action">
                <CaretRightOutlined
                  style={{
                    marginRight: "1em",
                  }}
                />
                {buttonTitle}
              </span>
            </Link>
          ) : (
            <span className="button-action" style={{ cursor: "not-allowed" }}>
              <CaretRightOutlined
                style={{
                  marginRight: "1em",
                }}
              />
              {buttonTitle}
            </span>
          )}
        </Row>
      </Col>
    </Row>
  );
};
export default function index() {
  return (
    <Row
      style={{
        marginBottom: 50,
      }}
    >
      <Row>
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <Icon type="home" />
          </Breadcrumb.Item>
          <Breadcrumb.Item
          //  href="/vital-registration"
          >
            <Icon
              component={() => (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                >
                  <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z" />
                </svg>
              )}
            />
            <span>घटना दर्ता</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row>
        <div className="hero">
          <div className="hero-image-container">
            <figure
              className="hero-image"
              style={{
                backgroundImage: `url(${vitalImage})`,
                maxHeight: 504,
              }}
            ></figure>
          </div>
        </div>
      </Row>
      <div class="divider-vital"></div>
      {[
        {
          title: "Add Member/New Born Baby/ Adopt Child",
          description:
            "Certainly, the issuance of a birth certificate is consistent with the Convention on the Rights of the Child that states that every child should be registered immediately after birth. And in almost all societies a birth certificate is a basic legal document that gives identity to a child, and automatically bestows a number of						rights such as the right to health care, nationality, schooling,						passport, property ownership, voting, formal employment, or access to banking services.",
          buttonTitle: "जन्म दर्ता गर्नुहोस",
          image: bornImage,
          id: "birth",
        },
        {
          title: "Marriage Registration",
          rightAlignImage: true,
          description:
            "Certainly, the issuance of a Marriage certificate is consistent with the Convention on the Rights of the Child that states that every child should be registered immediately after birth. And in almost all societies a birth certificate is a basic legal document that gives identity to a child, and automatically bestows a number of						rights such as the right to health care, nationality, schooling,						passport, property ownership, voting, formal employment, or access to banking services.",
          buttonTitle: "विबाह दर्ता गर्नुहोस",
          image: marriageImage,
          // id: "marriage",
        },
        {
          title: "Divorce Registration",
          description:
            "Certainly, the issuance of a birth certificate is consistent with the Convention on the Rights of the Child that states that every child should be registered immediately after birth. And in almost all societies a birth certificate is a basic legal document that gives identity to a child, and automatically bestows a number of						rights such as the right to health care, nationality, schooling,						passport, property ownership, voting, formal employment, or access to banking services.",
          buttonTitle: "सम्बन्ध विच्छेद दर्ता गर्नुहोस",
          image: divorceImage,
          // id: "birth",
        },
        {
          title: "Migration Registration",
          rightAlignImage: true,
          description:
            "Certainly, the issuance of a birth certificate is consistent with the Convention on the Rights of the Child that states that every child should be registered immediately after birth. And in almost all societies a birth certificate is a basic legal document that gives identity to a child, and automatically bestows a number of						rights such as the right to health care, nationality, schooling,						passport, property ownership, voting, formal employment, or access to banking services.",
          buttonTitle: "बसाइँ सराइ दर्ता गर्नुहोस",
          image: migrationImage,
          // id: "birth",
        },
        {
          title: "Death Registration",
          description:
            "Certainly, the issuance of a birth certificate is consistent with the Convention on the Rights of the Child that states that every child should be registered immediately after birth. And in almost all societies a birth certificate is a basic legal document that gives identity to a child, and automatically bestows a number of						rights such as the right to health care, nationality, schooling,						passport, property ownership, voting, formal employment, or access to banking services.",
          buttonTitle: "मृत्यु दर्ता गर्नुहोस",
          image: deathImage,
          // id: "birth",
        },
      ].map((each) => (
        <VitalItem item={each} />
      ))}
    </Row>
  );
}
