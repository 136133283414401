import { Grid, IconButton, Modal } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
	Button,
	Radio,
	Form,
	Icon,
	Input,
	message,
  Select,
  Divider,
	Typography,
	Upload,
} from "antd";
import Axios from "axios";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import CitizenAwareness from "../../../../../models/newInstitutions/CitizenAwareness";
import { addInstitutionSurvey } from "../../../actions/instituteAction";
import isEmpty from "../../../utils/validation/is-empty";
import { no, selectedLanguage, ward, yes } from "../../../variable/global";
import {
	add,
	wardQuestion,
	photo,
	name,
  memberTitle,
  descriptionTitle,
  address,
  male,
  female,
  familyTitle,
  dalit,
  janjati,
  others,
  citizenType,
  citizenTitle,
  amountQn,
  investment,
  areaQn
} from "../../../variable/institute";
import { getCitizenAwareness } from "./api";

const { TextArea } = Input;

const { Option } = Select;

class CitizenAwareness extends Component {
	random = (low, high) => {
		return Math.random() * (high - low) + low;
	};
	state = {
		// image upload state
		uniqueIdentifier:
			Number(this.random(10000, 99999)).toFixed(0) +
			Number(this.random(1000, 9999)).toFixed(0),
		previewVisible: false,
		previewImage: "",
		fileList: {
			busImage: [],
		},
		loaded: 0,
		// other
		ward: "",
		photo: "",
		name: "",
    address: "",
    citizenType: "",
    production: "",
    female: "",
    male: "",
    dalit: "",
    janjati: "",
    others: "",
    amount: "",
    investment: "",
    area:"",

	};

	// image functions uplocad
	handleCancel = () => this.setState({ previewVisible: false });

	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	};

	// imageUpload
	handleChangeFile = (name, value, imageName) => {
		//name ma house and value ma list of filelist
		console.log("CF", value);
		this.setState(
			(prevState) => ({
				...prevState,
				fileList: {
					...prevState.fileList,
					[name]: {
						image: value.fileList,
						fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg",
					},
				},
			}),
			() => {
				if (value.fileList.length > 0) this.handleUpload(name, imageName);
				if (value.fileList.length === 0) this.removeUpload(name, imageName);
			}
		);
	};

	// upload image
	handleUpload = (name, imageName) => {
		const fileList = this.state.fileList[name];
		const data = new FormData();
		const imageObject = fileList.image[0].originFileObj;

		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
		data.append("file", imageObject, fileName);

		Axios.post("/api/imageUpload/upload", data, {
			onUploadProgress: (ProgressEvent) => {
				this.setState({
					loaded: Math.round(
						(ProgressEvent.loaded / ProgressEvent.total) * 100
					),
				});
			},
		})
			.then((res) => {
				// then print response status
				message.success("upload successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	// remove uploaded image
	removeUpload = (imageName) => {
		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

		Axios.get(`/api/imageUpload/delete/${fileName}`)
			.then((res) => {
				// then print response status
				message.success("deleted successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	componentDidMount() {
		let busId = this.props.match.params.id;
		if (busId) {
			getCitizenAwareness(busId).then((res) =>
				this.setState({
					ward: res.data.ward,
          name: res.data.name,
					address: res.data.address,
          citizenType: res.data.citizenType,
					amount: res.data.amount,
					investment: res.data.investment,
					area: res.data.area,
          
					male: res.data.description.member.male,
          female: res.data.description.member.female,
          dalit: res.data.description.family.dalit,
          janjati: res.data.description.family.janjati,
          others: res.data.description.family.others,

          

          
					name: res.data.name,
					// image
					fileList: {
						busImage: {
							fileName: res.data.uniqueIdentifier + "_bus.jpg",
							image: [
								{
									uid: "-1",
									name: `${res.data.uniqueIdentifier}_bus.jpg`,
									status: "done",
									url: `/api/imageUpload/image/${res.data.uniqueIdentifier}_bus.jpg`,
								},
							],
						},
					},
				})
			);
		}
	}

	onSubmit = (event) => {
		event.preventDefault();
		const newInstitute = {
			uniqueIdentifier: this.state.uniqueIdentifier,
			ward: this.state.ward,
      name: this.state.name,
			address: this.state.address,
			citizenType: this.state.citizenType,
      male: this.state.male,
			area: this.state.area,
			amount: this.state.amount,
			investment: this.state.investment,
			dalit: this.state.dalit,
			janjati: this.state.janjati,
			others: this.state.others,
			female: this.state.female,
    };
    console.log("debug", this.props.match.params.category);

		if (this.props.isUpdate) {
			newInstitute.id = this.props.match.params.id;
			this.props.addInstitutionSurvey(
				"citizenAware",
				newInstitute,
				this.props.history,
				this.props.match.params.category
			);
		} else
			this.props.addInstitutionSurvey(
				"citizenAware",
				newInstitute,
				this.props.history,
				this.props.match.params.category
			);
	};
	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};
	handleSelectChange = (name, value) => {
		console.log("handle ", name, value);
		this.setState({
			[name]: value,
		});
	};

	render() {
		const { classes, title } = this.props;
		const { previewVisible, previewImage } = this.state;
		const fileList = this.state.fileList;
		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		return (
			<Paper className={classes.root} elevation={1}>
				<Typography variant="h5">{title}</Typography>
				<Form onSubmit={this.onSubmit}>
					<Grid
						container
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						spacing={3}
					>
					

						<Grid item xs={6}>
							<Form.Item label={wardQuestion[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={(val) => this.handleSelectChange("ward", val)}
									value={this.state.ward}
									disabled={this.state.isView}
								>
									{ward.map((woda) => (
										<Option value={woda.value}>{woda.ward}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>
            <Grid item xs={6}>
							<Form.Item label={name[selectedLanguage]}>
								<Input
									onChange={this.handleChange("name")}
									value={this.state.name}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={address[selectedLanguage]}>
								<Input
									onChange={this.handleChange("address")}
									value={this.state.address}
								/>
							</Form.Item>
            </Grid>
            <Grid item xs={6}>
							<Form.Item label={citizenTitle[selectedLanguage]}>
                <Select
                  placehlder="------"
									onChange={(val)=>this.handleSelectChange("citizenType",val)}
									value={this.state.citizenType}
                >
                  {citizenType.map((type) => (
                       <Option value= {type.value}>{type[selectedLanguage] }</Option>
                  ))}
               
                  </Select>
							</Form.Item>
            </Grid>
            <Divider orientation="left">{descriptionTitle[selectedLanguage] }</Divider>

            <Divider orientation="left">{memberTitle[selectedLanguage] }</Divider>
            <Grid item xs={6}>
							<Form.Item label={male[selectedLanguage]}>
                <Input
                  min={0}
                  type="number"
                 
									onChange={this.handleChange("male")}
									value={this.state.male}
								/>
							</Form.Item>
						</Grid>	<Grid item xs={6}>
							<Form.Item label={female[selectedLanguage]}>
                <Input
                   type="number"
                  min={0}
                  
									onChange={this.handleChange("female")}
									value={this.state.female}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={2}>
							<Form.Item label="जम्मा">
								{parseInt(this.state.male || 0) +	parseInt(this.state.female || 0) }
							</Form.Item>
							</Grid>
            <Divider orientation="left">{familyTitle[selectedLanguage] }</Divider>
            <Grid item xs={6}>
							<Form.Item label={janjati[selectedLanguage]}>
                <Input
                   type="number"
                  min={0}
                  
									onChange={this.handleChange("janjati")}
									value={this.state.janjati}
								/>
							</Form.Item>
						</Grid>	
            <Grid item xs={6}>
							<Form.Item label={dalit[selectedLanguage]}>
                <Input
                   type="number"
                  min={0}
                  
									onChange={this.handleChange("dalit")}
									value={this.state.dalit}
								/>
							</Form.Item>
            </Grid>
            <Grid item xs={6}>
							<Form.Item label={others[selectedLanguage]}>
                <Input
                   type="number"
                  min={0}
                  
									onChange={this.handleChange("others")}
									value={this.state.others}
								/>
							</Form.Item>
							</Grid>
							<Grid item xs={2}>
							<Form.Item label="जम्मा">
								{parseInt(this.state.dalit || 0) +
									parseInt(this.state.janjati || 0) +parseInt(this.state.others || 0) }
							</Form.Item>
						</Grid>
            <Divider></Divider>
            <Grid item xs={6}>
							<Form.Item label={amountQn[selectedLanguage]}>
                <Input
                   type="number"
                  min={0}
                  addonAfter="रु."
									onChange={this.handleChange("amount")}
									value={this.state.amount}
								/>
							</Form.Item>
            </Grid>
            <Grid item xs={6}>
							<Form.Item label={investment[selectedLanguage]}>
                <Input
                   type="number"
                  min={0}
                  addonAfter="रु."
									onChange={this.handleChange("investment")}
									value={this.state.investment}
								/>
							</Form.Item>
						</Grid>	 <Grid item xs={6}>
							<Form.Item label={areaQn[selectedLanguage]}>
                <Input
                  
									onChange={this.handleChange("area")}
									value={this.state.area}
								/>
							</Form.Item>
						</Grid>	

						<Grid item xs={6}>
							<Form.Item label={photo[selectedLanguage]}>
								<Fragment>
									{!this.state.isView ? (
										<Upload
											name="file"
											// beforeUpload={this.props.beforeUpload}
											multiple={false}
											// action="/api/imageUpload/upload"
											accept="image/*"
											listType="picture-card"
											fileList={fileList.busImage && fileList.busImage.image}
											onPreview={this.handlePreview}
											onChange={(fileList) =>
												this.handleChangeFile("busImage", fileList, "bus")
											}
											disabled={this.state.isView}
										>
											{fileList &&
											fileList.busImage &&
											fileList.busImage.image &&
											fileList.busImage.image.length === 1
												? null
												: uploadButton}
										</Upload>
									) : (
										<Modal
											visible={previewVisible}
											footer={null}
											onCancel={this.handleCancel}
										>
											<img
												alt="example"
												style={{ width: "100%" }}
												src={previewImage}
											/>
										</Modal>
									)}
								</Fragment>
							</Form.Item>
						</Grid>

						<Grid item xs={12}>
							<Button
								type="primary"
								htmlType="submit"
								disabled={this.state.isView}
							>
								{add[selectedLanguage]}
							</Button>
						</Grid>
					</Grid>
				</Form>
			</Paper>
		);
	}
}

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
});

const mapStateToProps = (state) => ({});

export default withStyles(styles)(
	connect(mapStateToProps, { addInstitutionSurvey })(withRouter(CitizenAwareness))
);
