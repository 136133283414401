import {
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
	Grid,
} from "@material-ui/core";
import Moment from "react-moment";
import "moment-timezone";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import Card from "../../common/Card/Card.jsx";
import CardBody from "../../common/Card/CardBody.jsx";
import CardHeader from "../../common/Card/CardHeader";
import GridContainer from "../../common/Grid/GridContainer.jsx";
// core components
import Axios from "axios";
import GridItem from "../../common/Grid/GridItem.jsx";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import TablePaginationActionsWrapped from "../search/death/TablePaginationActions";

class ListLog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 0,
			rowsPerPage: 100,
			sn: "",
			action: "",
			to: "",
			by: "",
			createdAt: "",
			logs: [],
			firstname: "",
			lastname: "",
		};
	}
	componentDidMount() {
		this.fetchLog();
	}

	fetchLog = () => {
		let page = this.state.page;
		let row = this.state.rowsPerPage;
		Axios.get(`/api/log/all/${page}/${row}`)
			.then((res) => {
				this.setState({
					logs: res.data,
				});
			})
			.catch((err) => {});
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};
	handleChangeRowsPerPage = (event) => {
		this.setState({ page: 0, rowsPerPage: event.target.value });
	};
	render() {
		const { classes } = this.props;
		const { logs } = this.state;
		const { page, rowsPerPage } = this.state;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardHeader color="primary">
							<Typography variant="h6" className={classes.cardTitleWhite}>
								लग व्यवस्थापन
							</Typography>
						</CardHeader>
						<CardBody>
							<Table className={classes.table}>
								<TableHead>
									<TableRow>
										<TableCell>क्र.स</TableCell>
										<TableCell>लग मेसेज</TableCell>
										<TableCell>गतिविधी</TableCell>
										<TableCell>द्वारा गतिविधि</TableCell>
										<TableCell>ip</TableCell>
										<TableCell>मिति</TableCell>
										{/* <TableCell>Action</TableCell> */}
									</TableRow>
								</TableHead>
								<TableBody>
									{logs && logs ? (
										logs
											.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											)
											.map((log, id) => {
												return (
													<TableRow key={id}>
														<TableCell
															scope="row"
															style={{ padding: "0px !important" }}
														>
															<Typography variant="body1">
																{getNepaliNumber(page * rowsPerPage + (id + 1))}
															</Typography>
														</TableCell>
														<TableCell
															style={{ padding: "0px !important" }}
															align="left"
														>
															<Typography variant="body1">
																{log && log.to}
															</Typography>
														</TableCell>
														<TableCell
															style={{ padding: "0px !important" }}
															align="left"
														>
															<Typography variant="body1">
																{log && log.action}
															</Typography>
														</TableCell>
														<TableCell
															style={{ padding: "0px !important" }}
															align="left"
														>
															<Typography variant="body1">
																{log && log.by}
															</Typography>
														</TableCell>
														<TableCell
															style={{ padding: "0px !important" }}
															align="left"
														>
															<Typography variant="body1">
																{log && log.ip}
															</Typography>
														</TableCell>
														<TableCell
															style={{ padding: "0px !important" }}
															align="left"
														>
															<Typography variant="body1">
																<Moment fromNow>{log && log.createdAt}</Moment>
																{/* {log && log.createdAt && log.createdAt} */}
															</Typography>
														</TableCell>
														{/* <TableCell align="left">
											<VisibilityIcon />
											<Icon />
											<DeleteIcon />
										</TableCell> */}
													</TableRow>
												);
											})
									) : (
										<TableRow>No data</TableRow>
									)}
								</TableBody>
								{logs && (
									<TableFooter>
										<TableRow>
											<TablePagination
												rowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
												colSpan={7}
												count={logs.length}
												rowsPerPage={rowsPerPage}
												page={page}
												SelectProps={{
													native: true,
												}}
												onChangePage={this.handleChangePage}
												onChangeRowsPerPage={this.handleChangeRowsPerPage}
												ActionsComponent={TablePaginationActionsWrapped}
											/>
										</TableRow>
									</TableFooter>
								)}
							</Table>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}
const styles = (theme) => ({
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
			color: "rgba(255,255,255,.62)",
			margin: "0",
			fontSize: "14px",
			marginTop: "0",
			marginBottom: "0",
		},
		"& a,& a:hover,& a:focus": {
			color: "#FFFFFF",
		},
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1",
		},
	},
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3,
	},
	table: {
		minWidth: 500,
	},
	tableWrapper: {
		overflowX: "auto",
	},
	fab: {
		float: "right",
	},
});

export default withStyles(styles)(ListLog);
