import {
	Divider,
	Grid,
	IconButton,
	InputBase,
	Paper,
	Snackbar,
	NativeSelect,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// import {  } from "../../actions/authAction";
import PhoneIphone from "@material-ui/icons/PhoneIphone";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import {
	clearMemberList,
	getMemberByPhone,
} from "../../../../../actions/searchAction";
import isEmpty from "../../../../../utils/validation/is-empty";
import {
	phoneNumber,
	selectedLanguage,
	searchByPhone,
} from "../../../../../variable/global";
import MySnackbarContentWrapper from "../../../../common/MySnackbarContentWrapper";
import GetFamilyMemberDetail from "../name/GetFamilyMemberDetail";

class Index extends Component {
	constructor(props) {
		super(props);
		props.clearMemberList();
	}
	state = {
		phone: "",
		ward: 0,
		snack: false,
		snackVariant: "",
		snackMessage: "",
	};

	fetchData = () => {
		this.props.getMemberByPhone(this.state.phone, this.state.ward);
	};

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};
	onSubmit = () => {
		// event.preventDefault();
		const phone = this.state.phone;
		const ward = this.state.ward;
		if (!isEmpty(phone) && !isEmpty(ward)) this.fetchData();
		else {
			return this.setState({
				snack: true,
				snackVariant: "error",
				snackMessage: "कृपया नम्बर भर्नुहोस्",
			});
		}
	};

	keyPress = (e) => {
		if (e.keyCode == 13) {
			this.onSubmit();
			// put the login here
		}
	};

	handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		this.setState({ snack: false });
	};

	render() {
		const { classes, memberList, loading } = this.props;
		return (
			<Grid container className={classes.container}>
				<Paper className={classes.root} elevation={2}>
					<PhoneIphone className={classes.homeIcon} />
					<InputBase
						onKeyDown={this.keyPress}
						name="phone"
						value={this.state.phone}
						onChange={this.handleChange}
						className={classes.input}
						placeholder={phoneNumber[selectedLanguage]}
					/>

					<Divider className={classes.divider} />

					<NativeSelect
						className={classes.selectEmpty}
						value={this.state.ward}
						name="ward"
						onChange={this.handleChange}
						inputProps={{ "aria-label": "age" }}
					>
						<option value="" disabled>
							वडा नं
						</option>
						<option value={0}>भुम्लु गाउँपलिका </option>
						<option value={1}>१</option>
						<option value={2}>२</option>
						<option value={3}>३</option>
						<option value={4}>४</option>
						<option value={5}>५</option>
						<option value={6}>६</option>
						<option value={7}>७</option>
						<option value={8}>८</option>
						<option value={9}>९</option>
						<option value={10}>१०</option>
					</NativeSelect>
					<Divider className={classes.divider} />

					<IconButton
						onClick={this.onSubmit}
						color="primary"
						className={classes.iconButton}
						aria-label="Search"
					>
						<SearchIcon />
					</IconButton>
				</Paper>
				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					open={this.state.snack}
					autoHideDuration={4000}
					onClose={this.handleClose}
				>
					<MySnackbarContentWrapper
						onClose={this.handleClose}
						variant={this.state.snackVariant}
						message={this.state.snackMessage}
					/>
				</Snackbar>

				{loading ? (
					<Grid
						container
						stretch
						direction="row"
						justify="center"
						style={{ height: "50vh" }}
						alignItems="center"
					>
						<Grid item>
							<ClipLoader
								sizeUnit={"px"}
								size={84}
								color={"#00ACC1"}
								loading={loading}
							/>
						</Grid>
					</Grid>
				) : (
					memberList && (
						<GetFamilyMemberDetail
							title={searchByPhone[selectedLanguage]}
							memberList={memberList}
						/>
					)
				)}
			</Grid>
		);
	}
}

const styles = (theme) => ({
	container: {},
	root: {
		marginTop: theme.spacing.unit * 5,
		// padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		width: "auto",
	},
	input: {
		marginLeft: 8,
		flex: 1,
	},
	iconButton: {
		padding: 10,
	},
	divider: {
		width: 1,
		height: 28,
		margin: 4,
	},
	homeIcon: {
		marginLeft: 5,
		marginRight: 5,
		color: "#673ab7",
	},
	selectEmpty: {
		marginTop: theme.spacing(1),
		padding: "5px 5px 5px 5px",
	},
});

Index.propTypes = {
	classes: PropTypes.object.isRequired,
	memberList: PropTypes.object.isRequired,
};
const mapStateToProps = ({ search: { memberList, loading } }) => ({
	memberList,
	loading,
});

export default withStyles(styles)(
	connect(mapStateToProps, { getMemberByPhone, clearMemberList })(Index)
);
