import { Grid, IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
	Button,
	Divider,
	Form,
	Icon,
	Input,
	InputNumber,
	message,
	Modal,
	Radio,
	Select,
	Typography,
	Upload,
	Tooltip,
} from "antd";
import Axios from "axios";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addInstitutionSurvey } from "../../../actions/instituteAction";
import {
	add,
	address,
	name,
	rate,
	typeTitle,
	unit,
	wardQuestion,
	watertype,
	areaList,
} from "../../../variable/institute";
import isEmpty from "../../../utils/validation/is-empty";
import { no, selectedLanguage, ward, yes } from "../../../variable/global";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import { getDisasterWater } from "./api";

const { Option } = Select;

class DisasterWaterResource extends Component {
	random = (low, high) => {
		return Math.random() * (high - low) + low;
	};
	state = {
		// image upload state
		// uniqueIdentifier:
		//   this.props.match.params.category +
		//   "_" +
		//   this.random(10000, 99999) +
		//   this.random(1000, 9999),
		// previewVisible: false,
		// previewImage: "",
		//loaded: 0,
		name: "",
		ward: "",
		address: "",
		fileList: {
			instituteImage: [],
		},
		rate: "",
		watertype: "",
		unit: "",
	};

	handleSelectChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	handleInputChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	componentDidMount() {
		let drinkingWaterId = this.props.match.params.id;

		if (drinkingWaterId) {
			getDisasterWater(drinkingWaterId).then((res) =>
				this.setState({
					name: res.data.name,
					address: res.data.address,
					ward: res.data.ward,
					rate: res.data.rate,
					watertype: res.data.type,

					unit: res.data.unit,
				})
			);
		}
	}

	onSubmit = (event) => {
		event.preventDefault();
		const newInstitute = {
			name: this.state.name,
			// uniqueIdentifier: this.state.uniqueIdentifier,
			ward: this.state.ward,
			address: this.state.address,
			rate: this.state.rate,
			type: this.state.watertype,

			unit: this.state.unit,
		};

		if (this.props.isUpdate) {
			newInstitute.id = this.props.match.params.id;
			this.props.addInstitutionSurvey(
				"disasterWater",
				newInstitute,
				this.props.history,
				this.props.match.params.category
			);
		} else
			this.props.addInstitutionSurvey(
				"disasterWater",
				newInstitute,
				this.props.history,
				this.props.match.params.category
			);
	};

	render() {
		const { classes, title } = this.props;

		return (
			<Paper className={classes.root} elevation={1}>
				<Typography variant="h5">{title}</Typography>
				<Form onSubmit={this.onSubmit}>
					<Grid
						container
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						spacing={3}
					>
						<Grid item xs={6}>
							<Form.Item label={wardQuestion[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={(val) => this.handleSelectChange("ward", val)}
									value={this.state.ward}
									disabled={this.state.isView}
								>
									{ward.map((woda) => (
										<Option value={woda.value}>{woda.ward}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={name[selectedLanguage]}>
								<Input
									onChange={this.handleInputChange("name")}
									value={this.state.name}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={typeTitle[selectedLanguage]}>
								<Select
									onChange={(val) => this.handleSelectChange("watertype", val)}
									value={this.state.watertype}
									disabled={this.state.isView}
								>
									{watertype.map((result) => (
										<Option value={result.value}>
											{result[selectedLanguage]}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={address[selectedLanguage]}>
								<Input
									onChange={this.handleInputChange("address")}
									value={this.state.address}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={unit[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={(val) => this.handleSelectChange("unit", val)}
									value={this.state.unit}
									disabled={this.state.isView}
								>
									{areaList.map((alist) => (
										<Option value={alist.value}>{alist.nepali}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={rate[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									onChange={this.handleInputChange("rate")}
									value={this.state.rate}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={12}>
							<Button
								type="primary"
								htmlType="submit"
								disabled={this.state.isView}
							>
								{this.props.isUpdate ? "update" : add[selectedLanguage]}
							</Button>
						</Grid>
					</Grid>
				</Form>
			</Paper>
		);
	}
}

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
});

const mapStateToProps = (state) => ({});

export default withStyles(styles)(
	connect(mapStateToProps, { addInstitutionSurvey })(
		withRouter(DisasterWaterResource)
	)
);
