import { Grid, IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
	Button,
	Divider,
	Form,
	Icon,
	Input,
	InputNumber,
	message,
	Modal,
	Radio,
	Select,
	Typography,
	Upload,
	Tooltip,
	Table,
} from "antd";
import Axios from "axios";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addInstitutionSurvey } from "../../../actions/instituteAction";
import CodeInput from "../../common/codeInput/";
import {
	add,
	remove,
	address,
	femalestudent,
	malestudent,
	name,
	wardQuestion,
	collegelevel,
	collegePhysicalStatus,
	collegeSubject,
	photo,
	student,
	teacher,
	teacherMale,
	teacherFemale,
	email,
	phone,
	kachiCount,
	pakkiCount,
	kachiRoomCount,
	pakkiRoomCount,
	area,
	benchCapacity,
	facultyQn,
	position,
	gharTitle,
	collegeTypeQn,
	collegeType,
	isCertifiedCollege,
	collegeBhogChalanHeader,
	collegeElaani,
	collegeNambari,
	collegeArea,
	collegeLandCertificate,
	establish,
	compoundWallQn,
	permanentAgreement,
	suggestionQn,
	collegeSpecificationQn,
	collegeSpecification,
	wasteManagementQn,
} from "../../../variable/institute";
import isEmpty from "../../../utils/validation/is-empty";
import { no, selectedLanguage, ward, yes } from "../../../variable/global";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import { getCollege } from "./api";
const { Option } = Select;
const { TextArea } = Input;
const RadioGroup = Radio.Group;

class College extends Component {
	random = (low, high) => {
		return Math.random() * (high - low) + low;
	};
	state = {
		// image upload state
		uniqueIdentifier:
			Number(this.random(10000, 99999)).toFixed(0) +
			Number(this.random(1000, 9999)).toFixed(0),
		previewVisible: false,
		previewImage: "",
		loaded: 0,
		collegeName: "",
		collegeEmail: "",
		collegePhoneNumber: "",
		establishedAt: "",
		collegeType: "",
		elaani: "",
		nambari: "",
		collegeArea: "",
		isCollegeLandCertificate: "",
		isCertifiedCollege: "",
		kacchiCount: "",
		pakkiCount: "",
		roomKacchiCount: "",
		roomPakkiCount: "",
		benchCapacity: "",
		groundArea: "",
		compoundWall: "",
		wasteDisposal: "",
		specification: [],
		maxEmployeeIndex: 1,
		employeeDetail: [],
		ward: "",
		address: "",
		faculty: "",
		level: "",
		mainSubject: "",
		maleStudent: "",
		femaleStudent: "",
		maleTeacher: "",
		femaleTeacher: "",
		fileList: {
			collegeImage: [],
		},
	};

	// image functions uplocad
	handleCancel = () => this.setState({ previewVisible: false });

	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	};

	// imageUpload
	handleChangeFile = (name, value, imageName) => {
		//name ma house and value ma list of filelist
		this.setState(
			(prevState) => ({
				...prevState,
				fileList: {
					...prevState.fileList,
					[name]: {
						image: value.fileList,
						fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg",
					},
				},
			}),
			() => {
				if (value.fileList.length > 0) this.handleUpload(name, imageName);
				if (value.fileList.length === 0) this.removeUpload(name, imageName);
			}
		);
	};

	// upload image
	handleUpload = (name, imageName) => {
		const fileList = this.state.fileList[name];
		const data = new FormData();
		const imageObject = fileList.image[0].originFileObj;

		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
		data.append("file", imageObject, fileName);

		Axios.post("/api/imageUpload/upload", data, {
			onUploadProgress: (ProgressEvent) => {
				this.setState({
					loaded: Math.round(
						(ProgressEvent.loaded / ProgressEvent.total) * 100
					),
				});
			},
		})
			.then((res) => {
				// then print response status
				message.success("upload successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	// remove uploaded image
	removeUpload = (imageName) => {
		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

		Axios.get(`/api/imageUpload/delete/${fileName}`)
			.then((res) => {
				// then print response status
				message.success("deleted successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	handleSelectChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	handleRadioChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	handleReactInputCode = (name) => (value) => {
		this.setState({
			[name]: value,
		});
	};

	handleInputChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	addDhikka = () => {
		this.setState((prevState) => ({
			maxEmployeeIndex: prevState.maxEmployeeIndex + 1,
		}));
	};

	removeRow = (index) => () => {
		this.setState((prevState) => {
			const employeeDetail = [...prevState.employeeDetail];
			employeeDetail.splice(index, 1);
			return {
				employeeDetail,
				maxEmployeeIndex: prevState.maxEmployeeIndex - 1,
			};
		});
	};

	getEmployeeUI = () => {
		const { maxEmployeeIndex, employeeDetail } = this.state;
		const columns = [
			{
				title: name[selectedLanguage],
				dataIndex: "sn",
				key: "name",
				render: (each) => (
					<Input
						name="name"
						onChange={this.handleNameChange(each)}
						value={
							employeeDetail &&
							employeeDetail[each] &&
							employeeDetail[each].name
						}
					/>
				),
			},
			{
				title: position[selectedLanguage],
				dataIndex: "sn",
				key: "designation",
				render: (each) => (
					<Input
						name="designation"
						onChange={this.handleDesignationChange(each)}
						value={
							employeeDetail &&
							employeeDetail[each] &&
							employeeDetail[each].designation
						}
					/>
				),
			},
			{
				title: permanentAgreement[selectedLanguage],
				dataIndex: "sn",
				key: "permanentAgreement",
				render: (each) => (
					<Input
						name="permanentAgreement"
						onChange={this.handleAgreementChange(each)}
						value={
							employeeDetail &&
							employeeDetail[each] &&
							employeeDetail[each].permanentAgreement
						}
					/>
				),
			},
			{
				title: facultyQn[selectedLanguage],
				dataIndex: "sn",
				key: "faculty",
				render: (each) => (
					<Input
						name="faculty"
						onChange={this.handleFacultyChange(each)}
						value={
							employeeDetail &&
							employeeDetail[each] &&
							employeeDetail[each].faculty
						}
					/>
				),
			},
			{
				title: phone[selectedLanguage],
				dataIndex: "sn",
				key: "phoneNumber",
				render: (each) => (
					<Input
						type="number"
						name="phoneNumber"
						onChange={this.handlePhoneChange(each)}
						value={
							employeeDetail &&
							employeeDetail[each] &&
							employeeDetail[each].phoneNumber
						}
					/>
				),
			},
			{
				title: email[selectedLanguage],
				dataIndex: "sn",
				key: "email",
				render: (each) => (
					<Input
						name="email"
						onChange={this.handleEmailChange(each)}
						value={
							employeeDetail &&
							employeeDetail[each] &&
							employeeDetail[each].email
						}
					/>
				),
			},
			{
				title: suggestionQn[selectedLanguage],
				dataIndex: "sn",
				key: "suggestion",
				render: (each) => (
					<Input
						name="suggestion"
						onChange={this.handleSuggestionChange(each)}
						value={
							employeeDetail &&
							employeeDetail[each] &&
							employeeDetail[each].suggestion
						}
					/>
				),
			},
			{
				title: "Action",
				key: "action",
				dataIndex: "sn",
				render: (index) =>
					parseInt(index) === maxEmployeeIndex - 1 ? (
						<Button type="dashed" onClick={this.addDhikka}>
							<Icon type="plus" />
							{add[selectedLanguage]}
						</Button>
					) : (
						<Button type="dashed" onClick={this.removeRow(index)}>
							<Icon style={{ color: "red" }} type="minus" />
							{remove[selectedLanguage]}
						</Button>
					),
			},
		];

		const dataSource = [...Array(maxEmployeeIndex).keys()].map((sn) => ({
			sn,
			level: "",
		}));
		return (
			<Grid item xs={12}>
				<Table dataSource={dataSource} columns={columns} pagination={false} />
			</Grid>
		);
	};

	handleNameChange = (index) => (event) => {
		const name = event.target.value;
		this.setState((prevState) => {
			const employeeDetail = [...prevState.employeeDetail];

			const data = {};
			data.name = name;
			data.email = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].email;
			data.designation = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].designation;
			data.permanentAgreement = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].permanentAgreement;
			data.faculty = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].faculty;
			data.phoneNumber = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].phoneNumber;
			data.suggestion = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].suggestion;

			employeeDetail.splice(index, 1, data);
			return { employeeDetail };
		});
	};

	handleDesignationChange = (index) => (event) => {
		const designation = event.target.value;
		this.setState((prevState) => {
			const employeeDetail = [...prevState.employeeDetail];

			const data = {};
			data.designation = designation;
			data.email = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].email;
			data.name = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].name;
			data.permanentAgreement = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].permanentAgreement;
			data.faculty = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].faculty;
			data.phoneNumber = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].phoneNumber;
			data.suggestion = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].suggestion;

			employeeDetail.splice(index, 1, data);
			return { employeeDetail };
		});
	};

	handleAgreementChange = (index) => (event) => {
		const permanentAgreement = event.target.value;
		this.setState((prevState) => {
			const employeeDetail = [...prevState.employeeDetail];

			const data = {};
			data.permanentAgreement = permanentAgreement;
			data.email = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].email;
			data.name = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].name;
			data.designation = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].designation;
			data.faculty = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].faculty;
			data.phoneNumber = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].phoneNumber;
			data.suggestion = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].suggestion;

			employeeDetail.splice(index, 1, data);
			return { employeeDetail };
		});
	};

	handleFacultyChange = (index) => (event) => {
		const faculty = event.target.value;
		this.setState((prevState) => {
			const employeeDetail = [...prevState.employeeDetail];

			const data = {};
			data.faculty = faculty;
			data.email = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].email;
			data.name = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].name;
			data.designation = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].designation;
			data.permanentAgreement = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].permanentAgreement;
			data.phoneNumber = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].phoneNumber;
			data.suggestion = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].suggestion;

			employeeDetail.splice(index, 1, data);
			return { employeeDetail };
		});
	};

	handlePhoneChange = (index) => (event) => {
		const phoneNumber = event.target.value;
		this.setState((prevState) => {
			const employeeDetail = [...prevState.employeeDetail];

			const data = {};
			data.phoneNumber = phoneNumber;
			data.email = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].email;
			data.name = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].name;
			data.designation = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].designation;
			data.permanentAgreement = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].permanentAgreement;
			data.faculty = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].faculty;
			data.suggestion = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].suggestion;

			employeeDetail.splice(index, 1, data);
			return { employeeDetail };
		});
	};

	handleEmailChange = (index) => (event) => {
		const email = event.target.value;
		this.setState((prevState) => {
			const employeeDetail = [...prevState.employeeDetail];

			const data = {};
			data.email = email;
			data.phoneNumber = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].phoneNumber;
			data.name = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].name;
			data.designation = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].designation;
			data.permanentAgreement = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].permanentAgreement;
			data.faculty = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].faculty;
			data.suggestion = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].suggestion;

			employeeDetail.splice(index, 1, data);
			return { employeeDetail };
		});
	};

	handleSuggestionChange = (index) => (event) => {
		const suggestion = event.target.value;
		this.setState((prevState) => {
			const employeeDetail = [...prevState.employeeDetail];

			const data = {};
			data.suggestion = suggestion;
			data.phoneNumber = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].phoneNumber;
			data.name = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].name;
			data.designation = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].designation;
			data.permanentAgreement = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].permanentAgreement;
			data.faculty = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].faculty;
			data.email = isEmpty(employeeDetail[index])
				? ""
				: employeeDetail[index].email;

			employeeDetail.splice(index, 1, data);
			return { employeeDetail };
		});
	};

	componentDidMount() {
		let collegeId = this.props.match.params.id;
		if (collegeId) {
			getCollege(collegeId).then((res) =>
				this.setState({
					collegeName: res.data.name,
					isCollegeLandCertificate:
						res.data.isCollegeLandCertificate === true ? "yes" : "no",
					maxEmployeeIndex:
						res.data.employeeDetail && res.data.employeeDetail.length,
					collegeEmail: res.data.email,
					collegeType: res.data.collegeType,
					collegePhoneNumber: res.data.phoneNumber,
					establishedAt: res.data.establishedAt,
					collegeArea: res.data.collegeArea,
					isCollegeLandCertificate:
						res.data.isCollegeLandCertificate === true ? "yes" : "no",
					specification: res.data.specification,
					elaani: res.data.bhogChalanArea && res.data.bhogChalanArea.elaani,
					nambari: res.data.bhogChalanArea && res.data.bhogChalanArea.nambari,
					kacchiCount: res.data.building && res.data.building.kacchiCount,
					pakkiCount: res.data.building && res.data.building.pakkiCount,
					roomKacchiCount: res.data.room && res.data.room.roomKacchiCount,
					roomPakkiCount: res.data.room && res.data.room.roomPakkiCount,
					benchCapacity: res.data.room && res.data.room.benchCapacity,
					ward: res.data.ward,
					groundArea: res.data.groundArea,
					compoundWall: res.data.compoundWall,
					wasteDisposal: res.data.wasteDisposal,
					employeeDetail: res.data.employeeDetail,
					address: res.data.address,
					faculty: res.data.faculty,
					level: res.data.level,
					mainSubject: res.data.mainSubject,
					maleStudent: res.data.student.maleStudent,
					femaleStudent: res.data.student.femaleStudent,
					maleTeacher: res.data.teacher.maleTeacher,
					femaleTeacher: res.data.teacher.femaleTeacher,
					// image
					fileList: {
						collegeImage: {
							fileName: res.data.uniqueIdentifier + "_college.jpg",
							image: [
								{
									uid: "-1",
									name: `${res.data.uniqueIdentifier}_college.jpg`,
									status: "done",
									url: `/api/imageUpload/image/${res.data.uniqueIdentifier}_college.jpg`,
								},
							],
						},
					},
				})
			);
		}
	}

	onSubmit = (event) => {
		event.preventDefault();
		const newInstitute = {
			name: this.state.collegeName,
			email: this.state.collegeEmail,
			phoneNumber: this.state.collegePhoneNumber,
			collegeType: this.state.collegeType,
			establishedAt: this.state.establishedAt,
			elaani: this.state.elaani,
			nambari: this.state.nambari,
			collegeArea: this.state.collegeArea,
			isCollegeLandCertificate:
				this.state.isCollegeLandCertificate === "yes" ? true : false,
			isCertifiedCollege:
				this.state.isCertifiedCollege === "yes" ? true : false,
			specification: this.state.specification,
			kacchiCount: this.state.kacchiCount,
			pakkiCount: this.state.pakkiCount,
			roomKacchiCount: this.state.roomKacchiCount,
			roomPakkiCount: this.state.roomPakkiCount,
			benchCapacity: this.state.benchCapacity,
			groundArea: this.state.groundArea,
			compoundWall: this.state.compoundWall,
			wasteDisposal: this.state.wasteDisposal,
			employeeDetail: this.state.employeeDetail,
			ward: this.state.ward,
			uniqueIdentifier: this.state.uniqueIdentifier,
			address: this.state.address,
			faculty: this.state.faculty,
			level: this.state.level,
			mainSubject: this.state.mainSubject,
			maleStudent: this.state.maleStudent,
			femaleStudent: this.state.femaleStudent,
			maleTeacher: this.state.maleTeacher,
			femaleTeacher: this.state.femaleTeacher,
			category: this.props.category,
		};

		if (this.props.isUpdate) {
			newInstitute.id = this.props.match.params.id;
			this.props.addInstitutionSurvey(
				"college",
				newInstitute,
				this.props.history,
				this.props.match.params.category
			);
		} else
			this.props.addInstitutionSurvey(
				"college",
				newInstitute,
				this.props.history,
				this.props.match.params.category
			);
	};

	render() {
		const { classes, title } = this.props;
		const { previewVisible, previewImage } = this.state;
		const fileList = this.state.fileList;
		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		return (
			<Paper className={classes.root} elevation={1}>
				<Typography variant="h5">{title}</Typography>
				<Form onSubmit={this.onSubmit}>
					<Grid
						container
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						spacing={3}
					>
						<Grid item xs={6}>
							<Form.Item label={name[selectedLanguage]}>
								<Input
									onChange={this.handleInputChange("collegeName")}
									value={this.state.collegeName}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={wardQuestion[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={(val) => this.handleSelectChange("ward", val)}
									value={this.state.ward}
									disabled={this.state.isView}
								>
									{ward.map((woda) => (
										<Option value={woda.value}>{woda.ward}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={collegeTypeQn[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={(val) =>
										this.handleSelectChange("collegeType", val)
									}
									value={this.state.collegeType}
									disabled={this.state.isView}
								>
									{collegeType.map((type) => (
										<Option value={type.value}>{type.nepali}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={address[selectedLanguage]}>
								<Input
									onChange={this.handleInputChange("address")}
									value={this.state.address}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={email[selectedLanguage]}>
								<Input
									onChange={this.handleInputChange("collegeEmail")}
									value={this.state.collegeEmail}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={phone[selectedLanguage]}>
								<Input
									onChange={this.handleInputChange("collegePhoneNumber")}
									value={this.state.collegePhoneNumber}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={isCertifiedCollege[selectedLanguage]}>
								<RadioGroup
									name="isCertifiedCollege"
									onChange={this.handleRadioChange}
									value={this.state.isCertifiedCollege}
								>
									<Radio value="yes" disabled={this.state.isView}>
										{yes[selectedLanguage]}
									</Radio>
									<Radio value="no" disabled={this.state.isView}>
										{no[selectedLanguage]}
									</Radio>
								</RadioGroup>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={establish[selectedLanguage]}>
								<CodeInput
									fields={4}
									type="number"
									values={this.state.establishedAt + ""}
									onComplete={this.handleReactInputCode("establishedAt")}
									autoFocus="false"
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={collegeArea[selectedLanguage]}>
								<Input
									onChange={this.handleInputChange("collegeArea")}
									value={this.state.collegeArea}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={collegeLandCertificate[selectedLanguage]}>
								<RadioGroup
									name="isCollegeLandCertificate"
									onChange={this.handleRadioChange}
									value={this.state.isCollegeLandCertificate}
								>
									<Radio value="yes" disabled={this.state.isView}>
										{yes[selectedLanguage]}
									</Radio>
									<Radio value="no" disabled={this.state.isView}>
										{no[selectedLanguage]}
									</Radio>
								</RadioGroup>
							</Form.Item>
						</Grid>

						<Divider orientation="left">
							{collegePhysicalStatus[selectedLanguage]}
						</Divider>

						<Grid item xs={6}>
							<Form.Item label={collegeSpecificationQn[selectedLanguage]}>
								<Select
									mode="multiple"
									placeholder="------------"
									onChange={(val) =>
										this.handleSelectChange("specification", val)
									}
									value={this.state.specification}
									disabled={this.state.isView}
								>
									{collegeSpecification.map((type) => (
										<Option value={type.value}>{type.nepali}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={kachiCount[selectedLanguage]}>
								<Input
									type="number"
									onChange={this.handleInputChange("kacchiCount")}
									value={this.state.kacchiCount}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={pakkiCount[selectedLanguage]}>
								<Input
									type="number"
									onChange={this.handleInputChange("pakkiCount")}
									value={this.state.pakkiCount}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={kachiRoomCount[selectedLanguage]}>
								<Input
									type="number"
									onChange={this.handleInputChange("roomKacchiCount")}
									value={this.state.roomKacchiCount}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={pakkiRoomCount[selectedLanguage]}>
								<Input
									type="number"
									onChange={this.handleInputChange("roomPakkiCount")}
									value={this.state.roomPakkiCount}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={benchCapacity[selectedLanguage]}>
								<Input
									type="number"
									onChange={this.handleInputChange("benchCapacity")}
									value={this.state.benchCapacity}
								/>
							</Form.Item>
						</Grid>
						<Divider />

						{/* <Divider orientation="left">{ground[selectedLanguage]}</Divider> */}
						<Grid item xs={6}>
							<Form.Item label={area[selectedLanguage]}>
								<Input
									addonAfter="square meter"
									onChange={this.handleInputChange("groundArea")}
									value={this.state.groundArea}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={compoundWallQn[selectedLanguage]}>
								<Input
									onChange={this.handleInputChange("compoundWall")}
									value={this.state.compoundWall}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={wasteManagementQn[selectedLanguage]}>
								<Input
									onChange={this.handleInputChange("wasteDisposal")}
									value={this.state.wasteDisposal}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Divider orientation="left">
							{collegeBhogChalanHeader[selectedLanguage]}
						</Divider>
						<Grid item xs={6}>
							<Form.Item label={collegeElaani[selectedLanguage]}>
								<Input
									// type="number"
									// addonAfter="square meter"
									onChange={this.handleInputChange("elaani")}
									value={this.state.elaani}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={collegeNambari[selectedLanguage]}>
								<Input
									// type="number"
									// addonAfter="square meter"
									onChange={this.handleInputChange("nambari")}
									value={this.state.nambari}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Divider />

						<Grid item xs={6}>
							<Form.Item label={photo[selectedLanguage]}>
								<Fragment>
									{!this.state.isView ? (
										<Upload
											name="file"
											// beforeUpload={this.props.beforeUpload}
											multiple={false}
											// action="/api/imageUpload/upload"
											accept="image/*"
											listType="picture-card"
											fileList={
												fileList.collegeImage && fileList.collegeImage.image
											}
											onPreview={this.handlePreview}
											onChange={(fileList) =>
												this.handleChangeFile(
													"collegeImage",
													fileList,
													"college"
												)
											}
											disabled={this.state.isView}
										>
											{fileList &&
											fileList.collegeImage &&
											fileList.collegeImage.image &&
											fileList.collegeImage.image.length === 1
												? null
												: uploadButton}
										</Upload>
									) : (
										<Modal
											visible={previewVisible}
											footer={null}
											onCancel={this.handleCancel}
										>
											<img
												alt="example"
												style={{ width: "100%" }}
												src={previewImage}
											/>
										</Modal>
									)}
								</Fragment>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={facultyQn[selectedLanguage]}>
								<Tooltip
									trigger={["focus"]}
									title="Please insert comma seperated value"
									placement="topLeft"
									// overlayClassName="numeric-input"
								>
									<TextArea
										onChange={this.handleChange("faculty")}
										value={this.state.faculty}
										rows={4}
									/>
								</Tooltip>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={collegelevel[selectedLanguage]}>
								<Tooltip
									trigger={["focus"]}
									title="Please insert comma seperated value"
									placement="topLeft"
									// overlayClassName="numeric-input"
								>
									<TextArea
										onChange={this.handleChange("level")}
										value={this.state.level}
										rows={4}
									/>
								</Tooltip>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={collegeSubject[selectedLanguage]}>
								<Tooltip
									trigger={["focus"]}
									title="Please insert comma seperated value"
									placement="topLeft"
									// overlayClassName="numeric-input"
								>
									<TextArea
										onChange={this.handleChange("mainSubject")}
										value={this.state.mainSubject}
										rows={4}
									/>
								</Tooltip>
							</Form.Item>
						</Grid>
						<Divider orientation="left">{student[selectedLanguage]}</Divider>
						<Grid item xs={5}>
							<Form.Item label={malestudent[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									onChange={this.handleInputChange("maleStudent")}
									value={this.state.maleStudent}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={5}>
							<Form.Item label={femalestudent[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									onChange={this.handleInputChange("femaleStudent")}
									value={this.state.femaleStudent}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={2}>
							<Form.Item label="जम्मा">
								{parseInt(this.state.maleStudent || 0) +
									parseInt(this.state.femaleStudent || 0)}
							</Form.Item>
						</Grid>
						<Divider orientation="left">{teacher[selectedLanguage]}</Divider>
						<Grid item xs={5}>
							<Form.Item label={teacherMale[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									onChange={this.handleInputChange("maleTeacher")}
									value={this.state.maleTeacher}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={5}>
							<Form.Item label={teacherFemale[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									onChange={this.handleInputChange("femaleTeacher")}
									value={this.state.femaleTeacher}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={2}>
							<Form.Item label="जम्मा">
								{parseInt(this.state.maleTeacher || 0) +
									parseInt(this.state.femaleTeacher || 0)}
							</Form.Item>
						</Grid>
						<Divider />

						<Grid item xs={12}>
							{this.getEmployeeUI()}
						</Grid>
						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								disabled={this.state.isView}
							>
								{this.props.isUpdate ? "update" : add[selectedLanguage]}
							</Button>
						</Form.Item>
					</Grid>
				</Form>
			</Paper>
		);
	}
}

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
});

const mapStateToProps = (state) => ({});

export default withStyles(styles)(
	connect(mapStateToProps, { addInstitutionSurvey })(withRouter(College))
);
