import React, { Component } from "react";
import MapLayer from "./MapLayer";
import MapSidebar from "./MapSidebar";
import { Grid } from "@material-ui/core/es";
import Axios from "axios";
import { infastructure, disaster, geoCode } from "../../../variable/global";
import { totalWard } from "../../../config";
export default class index extends Component {
	state = {
		geoJsonDataWard: [],
		wardSelected: [...Array(totalWard).keys()].map((each) => each + 1),
		infastructureSelected: [7],
		geoJsonDataInfastructure: [],
		wholeGeoJSON: {
			// ward,boundar
		},
		checkbox: {},
		showGeoJSON: [],
		boundaryChecked: false,
		wardCheckAll: false,
		indeterminateWard: false,
	};

	isGeoJsonAlreadyFetched = (field) => {
		const { wholeGeoJSON } = this.state;
		return Boolean(wholeGeoJSON[field]);
	};

	fetchGeoJSON = (changedData) => {
		const body = {
			ward: "fetch/ward",
			boundary: "fetch/local_level",
			river: "fetch/river",
			road: "fetch/road",
			// forest: "fetch/forest",
			house: "house",
			// school: "school",
			// club: "club",
			// tourismplaces: "tourismplaces",
			// items: "items",
			cultural_heritage: "institute/cultural_heritage",
			education: "institute/education",
			infrastructure: "institute/infrastructure",
			natural_resources: "institute/natural_resources",
			health: "institute/health",
			gov_office: "institute/gov_office",
			public_places: "institute/public_places",
			offices: "institute/offices",
			forest: "institute/forest",
			palika_related_office: "institute/palika_related_office",
			industries: "institute/industries",
			economic: "institute/economic",
			security: "institute/security",
		};

		return new Promise((resolve, reject) => {
			Axios.get(`/api/geojson/${body[changedData]}`)
				.then((result) => {
					const wholeGeoJSON = this.state.wholeGeoJSON;
					console.log("simana", result.data);
					this.setState(
						{
							wholeGeoJSON: {
								...wholeGeoJSON,
								[changedData]: result.data,
							},
						},
						() => resolve()
					);
					console.log("wholejson", wholeGeoJSON);
				})
				.catch((err) => reject());
		});
	};

	arrangeAllOption = async () => {
		const {
			// boundaryChecked,
			checkbox,
			wardSelected,
			wardCheckAll,
			// wholeGeoJSON
			// showGeoJSON
		} = this.state;
		const showGeoJSON = [];

		// ward
		// fetch the geojson and store in wholeGeoJSON[changedData]
		if (!this.isGeoJsonAlreadyFetched("ward")) await this.fetchGeoJSON("ward");
		// if geojson has been fetched
		showGeoJSON.push(
			wardSelected.map((each) => this.state.wholeGeoJSON.ward[each - 1])
		);

		// other geojson
		let dataToCheck = [
			{
				value: "boundary",
				english: "Boundary",
				nepali: "सिमाना",
			},
		];

		dataToCheck = dataToCheck.concat(infastructure, disaster);
		const data = dataToCheck.map(async (each, i) => {
			// data to checked
			if (checkbox[each.value]) {
				// fetch the geojson and store in wholeGeoJSON[changedData]
				if (!this.isGeoJsonAlreadyFetched(each.value))
					await this.fetchGeoJSON(each.value);
				// if geojson has been fetched

				if (wardCheckAll) showGeoJSON.push(this.state.wholeGeoJSON[each.value]);
				else
					wardSelected.map((eachWard) => {
						showGeoJSON.push(
							this.state.wholeGeoJSON[each.value].filter(
								(eachType) => eachType.wardNo == eachWard
							)
						);
					});
				console.log(showGeoJSON);
				// showGeoJSON.push(this.state.wholeGeoJSON[each.value]);
				// console.log("uptodate", i);
			}
		});
		// wait until all promises resolve
		const results = await Promise.all(data);

		this.setState({ showGeoJSON });
	};

	// handleCheckbox = (name, value) => {
	// 	console.log("handleCheckbox", name, value);
	// 	this.setState({ [name]: [value] }, this.fetchType);
	// };

	handleWardChange = (checkedValue) => {
		this.setState(
			{
				wardSelected: checkedValue,
				indeterminateWard:
					!!checkedValue.length &&
					checkedValue.length > 0 &&
					checkedValue.length < totalWard,
				wardCheckAll: checkedValue.length === totalWard,
			},
			this.arrangeAllOption
		);
	};

	onCheckAllWardChange = (e) => {
		this.setState(
			{
				wardSelected: e.target.checked
					? [...Array(totalWard).keys()].map((each) => each + 1)
					: [],
				indeterminateWard: false,
				wardCheckAll: e.target.checked,
			},
			this.arrangeAllOption
		);
	};

	handleInfastructureChange = (checkedValue) => {
		this.setState({ infastructureSelected: checkedValue });
	};

	handleChange = (name, value) => {
		this.setState(
			({ checkbox }) => ({
				checkbox: {
					...checkbox,
					[name]: value,
				},
			}),
			this.arrangeAllOption
		);
	};

	componentDidMount() {
		this.arrangeAllOption();
	}
	render() {
		return (
			<Grid container direction="row">
				<Grid container item md={11}>
					<MapLayer
						style={{ zIndex: 1 }}
						showGeoJSON={this.state.showGeoJSON}
						geoJsonDataInfastructure={this.state.geoJsonDataInfastructure}
						geoJsonDataWard={this.state.geoJsonDataWard}
						position={[geoCode.lat, geoCode.lng]}
					/>
				</Grid>
				<Grid
					container
					item
					md={1}
					style={{
						postion: "relative",
						backgroundColor: "#000000",
						zIndex: 400,
						paddingTop: 15,
					}}
				>
					<MapSidebar
						otherCheckbox={this.state.checkbox}
						handleChange={this.handleChange}
						boundaryChecked={this.state.boundaryChecked}
						wardCheckAll={this.state.wardCheckAll}
						onCheckAllWardChange={this.onCheckAllWardChange}
						infastructureSelected={this.state.infastructureSelected}
						wardSelected={this.state.wardSelected}
						indeterminateWard={this.state.indeterminateWard}
						// handleCheckbox={this.handleCheckbox}
						handleWardChange={this.handleWardChange}
						handleInfastructureChange={this.handleInfastructureChange}
					/>
				</Grid>
			</Grid>
		);
	}

	// componentDidMount() {
	// 	this.fetch();
	// }

	fetch = () => {
		Axios.get("/api/geojson/level/ward")
			.then((result) => {
				this.setState({ "wholeGeoJSON.ward": result.data }, () =>
					this.arrangeData("ward")
				);
			})
			.catch((err) => {});
	};

	fetchType = () => {
		var data = [];
		const { infastructureSelected } = this.state;
		infastructureSelected.map((each) => data.push(each));
		Axios.post("/api/geojson/fetchType", data)
			.then((result) => {
				console.log("fetchtype", result.data, data);
				this.setState(
					{ "wholeGeoJSON.geoJsonDataInfastructure": result.data },
					this.arrangeData
				);
			})
			.catch((err) => {});
	};
}
