import { Grid, IconButton, Modal } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
	Button,
	Radio,
	Form,
	Icon,
	Input,
	message,
  Select,
  Divider,
	Typography,
	Upload,
} from "antd";
import Axios from "axios";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import CitizenAwareness from "../../../../../models/newInstitutions/CitizenAwareness";
import { addInstitutionSurvey } from "../../../actions/instituteAction";
import isEmpty from "../../../utils/validation/is-empty";
import { no, selectedLanguage, ward, yes } from "../../../variable/global";
import {
	add,
	wardQuestion,
	photo,

  communityOrg,
  forestOrg,
  farmerOrg,
  tolSudhar,
  governmentOrg,
  club,
  traditionalOrg,
  amaOrg,
  others,
  citizenship,
  udhyami
} from "../../../variable/institute";
import { getCommunityOrganization } from "./api";

const { TexttolSudhar } = Input;

const { Option } = Select;

class CommunityOrganization extends Component {
	random = (low, high) => {
		return Math.random() * (high - low) + low;
	};
	state = {
		// image upload state
		uniqueIdentifier:
			Number(this.random(10000, 99999)).toFixed(0) +
			Number(this.random(1000, 9999)).toFixed(0),
		previewVisible: false,
		previewImage: "",
		fileList: {
			busImage: [],
		},
		loaded: 0,
		// other
		ward: "",
		photo: "",
		
    communityOrg: "",
  
    
    farmerOrg: "",
    forestOrg: "",
    governmentOrg: "",
    club: "",
    traditionalOrg: "",
    amaOrg: "",
    citizenship: "",
    tolSudhar: "",
    udhyami:"",
    others:"",


	};

	// image functions uplocad
	handleCancel = () => this.setState({ previewVisible: false });

	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	};

	// imageUpload
	handleChangeFile = (name, value, imageName) => {
		//name ma house and value ma list of filelist
		console.log("CF", value);
		this.setState(
			(prevState) => ({
				...prevState,
				fileList: {
					...prevState.fileList,
					[name]: {
						image: value.fileList,
						fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg",
					},
				},
			}),
			() => {
				if (value.fileList.length > 0) this.handleUpload(name, imageName);
				if (value.fileList.length === 0) this.removeUpload(name, imageName);
			}
		);
	};

	// upload image
	handleUpload = (name, imageName) => {
		const fileList = this.state.fileList[name];
		const data = new FormData();
		const imageObject = fileList.image[0].originFileObj;

		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
		data.append("file", imageObject, fileName);

		Axios.post("/api/imageUpload/upload", data, {
			onUploadProgress: (ProgressEvent) => {
				this.setState({
					loaded: Math.round(
						(ProgressEvent.loaded / ProgressEvent.total) * 100
					),
				});
			},
		})
			.then((res) => {
				// then print response status
				message.success("upload successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	// remove uploaded image
	removeUpload = (imageName) => {
		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

		Axios.get(`/api/imageUpload/delete/${fileName}`)
			.then((res) => {
				// then print response status
				message.success("deleted successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	componentDidMount() {
		let busId = this.props.match.params.id;
		if (busId) {
			getCommunityOrganization(busId).then((res) =>
				this.setState({
					ward: res.data.ward,
       
					communityOrg: res.data.communityOrg,
        
					amaOrg: res.data.amaOrg,
					citizenship: res.data.citizenship,
			
          
					forestOrg: res.data.forestOrg,
          farmerOrg: res.data.farmerOrg,
          governmentOrg: res.data.governmentOrg,
          club: res.data.club,
          traditionalOrg: res.data.traditionalOrg,
          amaOrg: res.data.amaOrg,
          citizenship: res.data.citizenship,
          tolSudhar: res.data.tolSudhar,
          others: res.data.others,
          udhyami: res.data.udhyami,


          

          
	
					// image
					fileList: {
						busImage: {
							fileName: res.data.uniqueIdentifier + "_bus.jpg",
							image: [
								{
									uid: "-1",
									name: `${res.data.uniqueIdentifier}_bus.jpg`,
									status: "done",
									url: `/api/imageUpload/image/${res.data.uniqueIdentifier}_bus.jpg`,
								},
							],
						},
					},
				})
			);
		}
	}

	onSubmit = (event) => {
		event.preventDefault();
		const newInstitute = {
			uniqueIdentifier: this.state.uniqueIdentifier,
			ward: this.state.ward,
      amaOrg: this.state.amaOrg,
			communityOrg: this.state.communityOrg,
			
      forestOrg: this.state.forestOrg,
			
			
			citizenship: this.state.citizenship,
			governmentOrg: this.state.governmentOrg,
			club: this.state.club,
			traditionalOrg: this.state.traditionalOrg,
      farmerOrg: this.state.farmerOrg,
		
			tolSudhar: this.state.tolSudhar,
			udhyami: this.state.udhyami,
			others: this.state.others,
      
    };
    console.log("debug", this.props.match.params.category);

		if (this.props.isUpdate) {
			newInstitute.id = this.props.match.params.id;
			this.props.addInstitutionSurvey(
				"communityOrg",
				newInstitute,
				this.props.history,
				this.props.match.params.category
			);
		} else
			this.props.addInstitutionSurvey(
				"communityOrg",
				newInstitute,
				this.props.history,
				this.props.match.params.category
			);
	};
	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};
	handleSelectChange = (name, value) => {
		console.log("handle ", name, value);
		this.setState({
			[name]: value,
		});
	};

	render() {
		const { classes, title } = this.props;
		const { previewVisible, previewImage } = this.state;
		const fileList = this.state.fileList;
		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		return (
			<Paper className={classes.root} elevation={1}>
				<Typography variant="h5">{title}</Typography>
				<Form onSubmit={this.onSubmit}>
					<Grid
						container
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						spacing={3}
					>
					

						<Grid item xs={6}>
							<Form.Item label={wardQuestion[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={(val) => this.handleSelectChange("ward", val)}
									value={this.state.ward}
									disabled={this.state.isView}
								>
									{ward.map((woda) => (
										<Option value={woda.value}>{woda.ward}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>
            <Grid item xs={6}>
							<Form.Item label={farmerOrg[selectedLanguage]}>
								<Input
                  min={0}
                  type="number"
									onChange={this.handleChange("farmerOrg")}
									value={this.state.farmerOrg}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={communityOrg[selectedLanguage]}>
                <Input
                    min={0}
                    type="number"
									onChange={this.handleChange("communityOrg")}
									value={this.state.communityOrg}
								/>
							</Form.Item>
            </Grid>
           
         
            <Grid item xs={6}>
							<Form.Item label={forestOrg[selectedLanguage]}>
                <Input
                  min={0}
                  type="number"
                 
									onChange={this.handleChange("forestOrg")}
									value={this.state.forestOrg}
								/>
							</Form.Item>
						</Grid>	<Grid item xs={6}>
							<Form.Item label={governmentOrg[selectedLanguage]}>
                <Input
                   type="number"
                  min={0}
                  
									onChange={this.handleChange("governmentOrg")}
									value={this.state.governmentOrg}
								/>
							</Form.Item>
            </Grid>
           
            <Grid item xs={6}>
							<Form.Item label={club[selectedLanguage]}>
                <Input
                   type="number"
                  min={0}
                  
									onChange={this.handleChange("club")}
									value={this.state.club}
								/>
							</Form.Item>
						</Grid>	
            <Grid item xs={6}>
							<Form.Item label={traditionalOrg[selectedLanguage]}>
                <Input
                   type="number"
                  min={0}
                  
									onChange={this.handleChange("traditionalOrg")}
									value={this.state.traditionalOrg}
								/>
							</Form.Item>
            </Grid>
            <Grid item xs={6}>
							<Form.Item label={amaOrg[selectedLanguage]}>
                <Input
                   type="number"
                  min={0}
                  
									onChange={this.handleChange("amaOrg")}
									value={this.state.amaOrg}
								/>
							</Form.Item>
            </Grid>
           
            <Grid item xs={6}>
							<Form.Item label={citizenship[selectedLanguage]}>
                <Input
                   type="number"
                  min={0}
                  
									onChange={this.handleChange("citizenship")}
									value={this.state.citizenship}
								/>
							</Form.Item>
            </Grid>
            <Grid item xs={6}>
							<Form.Item label={tolSudhar[selectedLanguage]}>
                <Input
                   type="number"
                  min={0}
                
									onChange={this.handleChange("tolSudhar")}
									value={this.state.tolSudhar}
								/>
							</Form.Item>
						</Grid>	 <Grid item xs={6}>
							<Form.Item label={udhyami[selectedLanguage]}>
                <Input
                    min={0}
                    type="number"
									onChange={this.handleChange("udhyami")}
									value={this.state.udhyami}
								/>
							</Form.Item>
            </Grid>
            <Grid item xs={6}>
							<Form.Item label={others[selectedLanguage]}>
                <Input
                    min={0}
                    type="number"
									onChange={this.handleChange("others")}
									value={this.state.others}
								/>
							</Form.Item>
						</Grid>	

						<Grid item xs={6}>
							<Form.Item label={photo[selectedLanguage]}>
								<Fragment>
									{!this.state.isView ? (
										<Upload
											name="file"
											// beforeUpload={this.props.beforeUpload}
											multiple={false}
											// action="/api/imageUpload/upload"
											accept="image/*"
											listType="picture-card"
											fileList={fileList.busImage && fileList.busImage.image}
											onPreview={this.handlePreview}
											onChange={(fileList) =>
												this.handleChangeFile("busImage", fileList, "bus")
											}
											disabled={this.state.isView}
										>
											{fileList &&
											fileList.busImage &&
											fileList.busImage.image &&
											fileList.busImage.image.length === 1
												? null
												: uploadButton}
										</Upload>
									) : (
										<Modal
											visible={previewVisible}
											footer={null}
											onCancel={this.handleCancel}
										>
											<img
												alt="example"
												style={{ width: "100%" }}
												src={previewImage}
											/>
										</Modal>
									)}
								</Fragment>
							</Form.Item>
						</Grid>

						<Grid item xs={12}>
							<Button
								type="primary"
								htmlType="submit"
								disabled={this.state.isView}
							>
								{add[selectedLanguage]}
							</Button>
						</Grid>
					</Grid>
				</Form>
			</Paper>
		);
	}
}

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
});

const mapStateToProps = (state) => ({});

export default withStyles(styles)(
	connect(mapStateToProps, { addInstitutionSurvey })(withRouter(CommunityOrganization))
);
