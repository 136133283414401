import { Grid, IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
	Button,
	Divider,
	Form,
	Icon,
	Input,
	message,
	Modal,
	Radio,
	Select,
	Typography,
	Upload,
	Tooltip,
} from "antd";
import Axios from "axios";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addInstitutionSurvey } from "../../../actions/instituteAction";
import isEmpty from "../../../utils/validation/is-empty";
import { no, selectedLanguage, ward, yes } from "../../../variable/global";
import {
	add,
	wardQuestion,
	helplessWomen,
	countTitle,
	patron,
	helplessChild,
	helplessOld,
	helplessDiable,
	photo,
} from "../../../variable/institute";
import { getHelpless } from "./api";

const { TextArea } = Input;
const { Option } = Select;
const RadioGroup = Radio.Group;

class Helpless extends Component {
	random = (low, high) => {
		return Math.random() * (high - low) + low;
	};

	state = {
		// image upload state
		uniqueIdentifier:
			Number(this.random(10000, 99999)).toFixed(0) +
			Number(this.random(1000, 9999)).toFixed(0),
		previewVisible: false,
		previewImage: "",
		fileList: {
			helplessImage: [],
		},
		loaded: 0,
		// other
		ward: "",
		wcount: "",
		wpatron: "",
		ccount: "",
		cpatron: "",
		scount: "",
		spatron: "",
		hcount: "",
		hpatron: "",
	};

	// image functions uplocad
	handleCancel = () => this.setState({ previewVisible: false });

	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	};

	// imageUpload
	handleChangeFile = (name, value, imageName) => {
		//name ma house and value ma list of filelist
		console.log("CF", value);
		this.setState(
			(prevState) => ({
				...prevState,
				fileList: {
					...prevState.fileList,
					[name]: {
						image: value.fileList,
						fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg",
					},
				},
			}),
			() => {
				if (value.fileList.length > 0) this.handleUpload(name, imageName);
				if (value.fileList.length === 0) this.removeUpload(name, imageName);
			}
		);
	};

	// upload image
	handleUpload = (name, imageName) => {
		const fileList = this.state.fileList[name];
		const data = new FormData();
		const imageObject = fileList.image[0].originFileObj;

		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
		data.append("file", imageObject, fileName);

		Axios.post("/api/imageUpload/upload", data, {
			onUploadProgress: (ProgressEvent) => {
				this.setState({
					loaded: Math.round(
						(ProgressEvent.loaded / ProgressEvent.total) * 100
					),
				});
			},
		})
			.then((res) => {
				// then print response status
				message.success("upload successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	// remove uploaded image
	removeUpload = (imageName) => {
		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

		Axios.get(`/api/imageUpload/delete/${fileName}`)
			.then((res) => {
				// then print response status
				message.success("deleted successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	componentDidMount() {
		let helplessId = this.props.match.params.id;
		if (helplessId) {
			getHelpless(helplessId).then((res) =>
				this.setState({
					ward: res.data.ward,
					wcount: res.data.women.wcount,
					wpatron: res.data.women.wpatron,
					ccount: res.data.children.count.ccount,
					cpatron: res.data.children.count.cpatron,
					scount: res.data.seniorCitizen.scount,
					spatron: res.data.seniorCitizen.spatron,
					hcount: res.data.handicap.hcount,
					hpatron: res.data.handicap.hpatron,
					// image
					fileList: {
						helplessImage: {
							fileName: res.data.uniqueIdentifier + "_helpless.jpg",
							image: [
								{
									uid: "-1",
									name: `${res.data.uniqueIdentifier}_helpless.jpg`,
									status: "done",
									url: `/api/imageUpload/image/${res.data.uniqueIdentifier}_helpless.jpg`,
								},
							],
						},
					},
				})
			);
		}
	}

	onSubmit = (event) => {
		event.preventDefault();

		let newHelpless = {
			ward: this.state.ward,
			uniqueIdentifier: this.state.uniqueIdentifier,
			wcount: this.state.wcount,
			wpatron: this.state.wpatron,
			ccount: this.state.ccount,
			cpatron: this.state.cpatron,
			scount: this.state.scount,
			spatron: this.state.spatron,
			hcount: this.state.hcount,
			hpatron: this.state.hpatron,
		};
		console.log("Helpless detail", newHelpless);
		if (this.props.isUpdate) {
			newHelpless.id = this.props.match.params.id;
			this.props.addInstitutionSurvey(
				"helpless",
				newHelpless,
				this.props.history,
				this.props.match.params.category
			);
		} else {
			this.props.addInstitutionSurvey(
				"helpless",
				newHelpless,
				this.props.history,
				this.props.match.params.category
			);
		}
	};

	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	handleSelectChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	render() {
		const { classes, title } = this.props;
		const { previewVisible, previewImage } = this.state;
		const fileList = this.state.fileList;
		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		return (
			<Paper className={classes.root} elevation={1}>
				<Typography variant="h5">{title}</Typography>
				<Form onSubmit={this.onSubmit}>
					<Grid
						container
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						spacing={3}
					>
						<Grid item xs={6}>
							<Form.Item label={wardQuestion[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={(val) => this.handleSelectChange("ward", val)}
									value={this.state.ward}
									disabled={this.state.isView}
								>
									{ward.map((woda) => (
										<Option value={woda.value}>{woda.ward}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>
						<Divider orientation="left">
							{helplessWomen[selectedLanguage]}
						</Divider>
						<Grid item xs={6}>
							<Form.Item label={countTitle[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									onChange={this.handleChange("wcount")}
									value={this.state.wcount}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={patron[selectedLanguage]}>
								<Input
									onChange={this.handleChange("wpatron")}
									value={this.state.wpatron}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Divider orientation="left">
							{helplessChild[selectedLanguage]}
						</Divider>
						<Grid item xs={6}>
							<Form.Item label={countTitle[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									onChange={this.handleChange("ccount")}
									value={this.state.ccount}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={patron[selectedLanguage]}>
								<Input
									onChange={this.handleChange("cpatron")}
									value={this.state.cpatron}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Divider orientation="left">
							{helplessOld[selectedLanguage]}
						</Divider>
						<Grid item xs={6}>
							<Form.Item label={countTitle[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									onChange={this.handleChange("scount")}
									value={this.state.scount}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={patron[selectedLanguage]}>
								<Input
									onChange={this.handleChange("spatron")}
									value={this.state.spatron}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Divider orientation="left">
							{helplessDiable[selectedLanguage]}
						</Divider>
						<Grid item xs={6}>
							<Form.Item label={countTitle[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									onChange={this.handleChange("hcount")}
									value={this.state.hcount}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={patron[selectedLanguage]}>
								<Input
									onChange={this.handleChange("hpatron")}
									value={this.state.hpatron}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid
							container
							direction="row"
							justify="flex-start"
							alignItems="flex-start"
							spacing={3}
						>
							<Grid item xs={6}>
								<Form.Item label={photo[selectedLanguage]}>
									<Fragment>
										{!this.state.isView ? (
											<Upload
												name="file"
												// beforeUpload={this.props.beforeUpload}
												multiple={false}
												// action="/api/imageUpload/upload"
												accept="image/*"
												listType="picture-card"
												fileList={
													fileList.helplessImage && fileList.helplessImage.image
												}
												onPreview={this.handlePreview}
												onChange={(fileList) =>
													this.handleChangeFile(
														"helplessImage",
														fileList,
														"helpless"
													)
												}
												disabled={this.state.isView}
											>
												{fileList &&
												fileList.helplessImage &&
												fileList.helplessImage.image &&
												fileList.helplessImage.image.length === 1
													? null
													: uploadButton}
											</Upload>
										) : (
											<Modal
												visible={previewVisible}
												footer={null}
												onCancel={this.handleCancel}
											>
												<img
													alt="example"
													style={{ width: "100%" }}
													src={previewImage}
												/>
											</Modal>
										)}
									</Fragment>
								</Form.Item>
							</Grid>

							<Form.Item>
								<Button
									type="primary"
									htmlType="submit"
									disabled={this.state.isView}
								>
									{this.props.isUpdate ? "update" : add[selectedLanguage]}
								</Button>
							</Form.Item>
						</Grid>
					</Grid>
				</Form>
			</Paper>
		);
	}
}

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
});

const mapStateToProps = (state) => ({});

export default withStyles(styles)(
	connect(mapStateToProps, { addInstitutionSurvey })(withRouter(Helpless))
);
