import api from "axios";

export const getSchool = (schoolId) =>
	api.get(`/api/institute/school/fetch/${schoolId}`);

export const getDisasterEffect = (disasterEffectId) =>
	api.get(`/api/institute/disasterEffect/fetch/${disasterEffectId}`);

export const getPhysicalResource = (physicalId) =>
	api.get(`/api/institute/physicalResource/fetch/${physicalId}`);

export const getDisasterWater = (waterId) =>
	api.get(`/api/institute/disasterWater/fetch/${waterId}`);

export const getDisasterInfra = (infraId) =>
	api.get(`/api/institute/disasterInfrastructure/fetch/${infraId}`);
export const getCitizenAwareness = (awareId) =>
	api.get(`/api/institute/citizenAware/fetch/${awareId}`);

export const getSocialResource = (socialId) =>
	api.get(`/api/institute/socialResources/fetch/${socialId}`);

export const getDisasterOrganization = (disasId) =>
	api.get(`/api/institute/disasterorg/fetch/${disasId}`);
export const getEconomicalResources = (exonomyId) =>
	api.get(`/api/institute/economicalResources/fetch/${exonomyId}`);

export const getWasteMgmt = (wasteId) =>
	api.get(`/api/institute/waste/fetch/${wasteId}`);

export const getCooperativeInstitute = (cooperateId) =>
	api.get(`/api/institute/cooperativeInstitute/fetch/${cooperateId}`);

export const getFarmerSaving = (farmerId) =>
	api.get(`/api/institute/farmerSaving/fetch/${farmerId}`);

export const getCommunityOrganization = (orgId) =>
	api.get(`/api/institute/communityOrg/fetch/${orgId}`);

export const getSabdaha = (sabdahaId) =>
	api.get(`/api/institute/sabdaha/fetch/${sabdahaId}`);
export const getJungle = (jungleId) =>
	api.get(`/api/institute/jungle/fetch/${jungleId}`);
export const getAyurvedic = (ayurvedicId) =>
	api.get(`/api/institute/ayurvedic/fetch/${ayurvedicId}`);

export const getBusRoute = (busRouteId) =>
	api.get(`/api/institute/busRoute/fetch/${busRouteId}`);

export const getBusStation = (busStationId) =>
	api.get(`/api/institute/busStation/fetch/${busStationId}`);

export const getPersonalIncident = (personalIncidentId) =>
	api.get(`/api/institute/personalIncident/fetch/${personalIncidentId}`);

export const getGoatFarm = (goatFarmId) =>
	api.get(`/api/institute/goatFarm/fetch/${goatFarmId}`);

export const getProductionTourism = (productionId) =>
	api.get(`/api/institute/productionTourism/fetch/${productionId}`);

export const getCowFarm = (cowFarmId) =>
	api.get(`/api/institute/cowFarm/fetch/${cowFarmId}`);

export const getFarmHr = (farmHrId) =>
	api.get(`/api/institute/farmHr/fetch/${farmHrId}`);

export const getMill = (millId) =>
	api.get(`/api/institute/MILL/fetch/${millId}`);

export const getIrrigation = (irrigationId) =>
	api.get(`/api/institute/irrigation/fetch/${irrigationId}`);

export const getBusiness = (businessId) =>
	api.get(`/api/institute/businessInstitute/fetch/${businessId}`);

export const getFishFarm = (fishFarmId) =>
	api.get(`/api/institute/fishfarm/fetch/${fishFarmId}`);

export const getMedical = (medicalId) =>
	api.get(`/api/institute/medical/fetch/${medicalId}`);
export const getSports = (sportsId) =>
	api.get(`/api/institute/sports/fetch/${sportsId}`);

export const getSocialSecurity = (socialSecurityId) =>
	api.get(`/api/institute/socialSecurity/fetch/${socialSecurityId}`);
export const getDrinkingWater = (drinkingWaterId) =>
	api.get(`/api/institute/drinkingWater/fetch/${drinkingWaterId}`);

export const getHospital = (hospitalId) =>
	api.get(`/api/institute/hospital/fetch/${hospitalId}`);

export const getHealthPost = (hospitalId) =>
	api.get(`/api/institute/healthPost/fetch/${hospitalId}`);

export const getClub = (clubId) =>
	api.get(`/api/institute/club/fetch/${clubId}`);

export const getInstitute = (instituteId) =>
	api.get(`/api/institute/institution/fetch/${instituteId}`);

export const getPolice = (policeId) =>
	api.get(`/api/institute/police/fetch/${policeId}`);

export const getBank = (bankId) =>
	api.get(`/api/institute/bank/fetch/${bankId}`);

export const getCommunityHall = (hallId) =>
	api.get(`/api/institute/communityBuilding/fetch/${hallId}`);

export const getQuarter = (quarterId) =>
	api.get(`/api/institute/quarter/fetch/${quarterId}`);

export const getTourismPlace = (tourismId) =>
	api.get(`/api/institute/tourismPlace/fetch/${tourismId}`);

export const getPark = (parkId) =>
	api.get(`/api/institute/park/fetch/${parkId}`);

export const getHotel = (hotelId) =>
	api.get(`/api/institute/hotel/fetch/${hotelId}`);

export const getMine = (mineId) =>
	api.get(`/api/institute/mine/fetch/${mineId}`);

export const getForest = (forestId) =>
	api.get(`/api/institute/forest/fetch/${forestId}`);

export const getNikunja = (nikunjaId) =>
	api.get(`/api/institute/nikunja/fetch/${nikunjaId}`);

export const getBridge = (bridgeId) =>
	api.get(`/api/institute/bridge/fetch/${bridgeId}`);

export const getGround = (groundId) =>
	api.get(`/api/institute/ground/fetch/${groundId}`);

export const getMarket = (marketId) =>
	api.get(`/api/institute/market/fetch/${marketId}`);

export const getHerb = (herbId) =>
	api.get(`/api/institute/herbs/fetch/${herbId}`);

export const getCableCar = (cableCarId) =>
	api.get(`/api/institute/cableCar/fetch/${cableCarId}`);

export const getTuin = (tuinId) =>
	api.get(`/api/institute/tuin/fetch/${tuinId}`);

export const getMainRiver = (mainRiverId) =>
	api.get(`/api/institute/mainRiver/fetch/${mainRiverId}`);

export const getTower = (towerId) =>
	api.get(`/api/institute/tower/fetch/${towerId}`);

export const getFmStation = (fmId) =>
	api.get(`/api/institute/fmStation/fetch/${fmId}`);

export const getNewspaper = (newspaperId) =>
	api.get(`/api/institute/newspaper/fetch/${newspaperId}`);

export const getCableNetwork = (cableId) =>
	api.get(`/api/institute/cableNetwork/fetch/${cableId}`);

export const getTemple = (templeId) =>
	api.get(`/api/institute/temple/fetch/${templeId}`);

export const getIndustry = (industryId) =>
	api.get(`/api/institute/mainBusiness/fetch/${industryId}`);

export const getFestival = (festivalId) =>
	api.get(`/api/institute/festival/fetch/${festivalId}`);

export const getBusTerminal = (busId) =>
	api.get(`/api/institute/busTerminal/fetch/${busId}`);

export const getPublicToilet = (toiletId) =>
	api.get(`/api/institute/publicToilet/fetch/${toiletId}`);

export const getRoadNetwork = (roadId) =>
	api.get(`/api/institute/roadNetwork/fetch/${roadId}`);

export const getFreeWifi = (wifiId) =>
	api.get(`/api/institute/freeWifi/fetch/${wifiId}`);

export const getAnimal = (animalId) =>
	api.get(`/api/institute/animal/fetch/${animalId}`);

export const getGrazingField = (fieldId) =>
	api.get(`/api/institute/grazingField/fetch/${fieldId}`);

export const getAnimalFarm = (farmId) =>
	api.get(`/api/institute/animalFarm/fetch/${farmId}`);

export const getServiceCenter = (serviceCenterId) =>
	api.get(`/api/institute/serviceCenter/fetch/${serviceCenterId}`);

export const getRmFamily = (rmFamilyId) =>
	api.get(`/api/institute/rmFamily/fetch/${rmFamilyId}`);

export const getRmStaff = (rmStaffId) =>
	api.get(`/api/institute/rmStaff/fetch/${rmStaffId}`);

export const getElectricity = (electricityId) =>
	api.get(`/api/institute/electricity/fetch/${electricityId}`);

export const getTransmissionLine = (lineId) =>
	api.get(`/api/institute/transmissionLine/fetch/${lineId}`);

export const getCollege = (collegeId) =>
	api.get(`/api/institute/college/fetch/${collegeId}`);

export const getDisasterInformation = (disasterId) =>
	api.get(`/api/institute/disasterInformation/fetch/${disasterId}`);

export const getNaturalDisaster = (naturalId) =>
	api.get(`/api/institute/naturalDisaster/fetch/${naturalId}`);

export const getTechnicalEducation = (technicalId) =>
	api.get(`/api/institute/technicalEducation/fetch/${technicalId}`);

export const getWorker = (workerId) =>
	api.get(`/api/institute/worker/fetch/${workerId}`);

export const getChildHome = (childHomeId) =>
	api.get(`/api/institute/childHome/fetch/${childHomeId}`);

export const getOldAgeHome = (oldAgeHomeId) =>
	api.get(`/api/institute/oldAgeHome/fetch/${oldAgeHomeId}`);

export const getRehabCenter = (rehabCenterId) =>
	api.get(`/api/institute/rehabCenter/fetch/${rehabCenterId}`);

export const getHelpless = (helplessId) =>
	api.get(`/api/institute/helpless/fetch/${helplessId}`);

export const getGenderCrime = (genderCrimeId) =>
	api.get(`/api/institute/genderCrime/fetch/${genderCrimeId}`);

export const getPavement = (pavementId) =>
	api.get(`/api/institute/pavement/fetch/${pavementId}`);

export const getAgricultureMarket = (agriId) =>
	api.get(`/api/institute/agricultureMarket/fetch/${agriId}`);

export const getInsidePalikaDisease = (marketId) =>
	api.get(`/api/institute/insidePalikaDisease/fetch/${marketId}`);

export const getPalikaOffice = (palikaId) =>
	api.get(`/api/institute/palikaOffice/fetch/${palikaId}`);
export const getSkilledManpower = (skilledManpowerId) =>
	api.get(`/api/institute/skilledManpower/fetch/${skilledManpowerId}`);

export const getWomenSaving = (womenSavingId) =>
	api.get(`/api/institute/womenSaving/fetch/${womenSavingId}`);

export const getSafeMaternity = (safeMaternityId) =>
	api.get(`/api/institute/safeMaternity/fetch/${safeMaternityId}`);

export const getFemaleChildHealth = (femaleChildHealthId) =>
	api.get(`/api/institute/femaleChildHealth/fetch/${femaleChildHealthId}`);

export const getWardDetail = (wardDetailId) =>
	api.get(`/api/institute/wardDetail/fetch/${wardDetailId}`);
