import { Grid, IconButton, Modal } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
	Button,
	Radio,
	Form,
	Icon,
	Input,
	message,
	Select,
	Typography,
	Upload,
	Divider,
	Table,
} from "antd";
import Axios from "axios";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addInstitutionSurvey } from "../../../actions/instituteAction";
import isEmpty from "../../../utils/validation/is-empty";
import { no, selectedLanguage, ward, yes } from "../../../variable/global";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import {
	add,
	wardQuestion,
	photo,
	address,
	riskArea,
	male,
	female,
	effectFromDisasterQn,
	manpowers,
	effectofDeath,
	effectofInjured,
	remove,
} from "../../../variable/institute";
import { getSkilledManpower } from "./api";

const { TextArea } = Input;
const RadioGroup = Radio.Group;

const { Option } = Select;

class SkilledManpower extends Component {
	random = (low, high) => {
		return Math.random() * (high - low) + low;
	};
	state = {
		// image upload state
		uniqueIdentifier:
			Number(this.random(10000, 99999)).toFixed(0) +
			Number(this.random(1000, 9999)).toFixed(0),
		previewVisible: false,
		previewImage: "",
		fileList: {
			skilledManpowerImage: [],
		},
		loaded: 0,
		// other
		photo: "",
		ward: "",
		manpower: [],
		maxRiskIndex: 1,
	};

	// image functions uplocad
	handleCancel = () => this.setState({ previewVisible: false });

	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	};

	// imageUpload
	handleChangeFile = (name, value, imageName) => {
		//name ma house and value ma list of filelist
		console.log("CF", value);
		this.setState(
			(prevState) => ({
				...prevState,
				fileList: {
					...prevState.fileList,
					[name]: {
						image: value.fileList,
						fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg",
					},
				},
			}),
			() => {
				if (value.fileList.length > 0) this.handleUpload(name, imageName);
				if (value.fileList.length === 0) this.removeUpload(name, imageName);
			}
		);
	};

	// upload image
	handleUpload = (name, imageName) => {
		const fileList = this.state.fileList[name];
		const data = new FormData();
		const imageObject = fileList.image[0].originFileObj;

		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
		data.append("file", imageObject, fileName);

		Axios.post("/api/imageUpload/upload", data, {
			onUploadProgress: (ProgressEvent) => {
				this.setState({
					loaded: Math.round(
						(ProgressEvent.loaded / ProgressEvent.total) * 100
					),
				});
			},
		})
			.then((res) => {
				// then print response status
				message.success("upload successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	// remove uploaded image
	removeUpload = (imageName) => {
		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

		Axios.get(`/api/imageUpload/delete/${fileName}`)
			.then((res) => {
				// then print response status
				message.success("deleted successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	componentDidMount() {
		let naturalId = this.props.match.params.id;
		if (naturalId) {
			getSkilledManpower(naturalId).then((res) =>
				this.setState({
					manpower: res.data.manpower,
					ward: res.data.ward,
					maxRiskIndex:
						res.data.manpower && res.data.manpower.length,
					// image
					fileList: {
						skilledManpowerImage: {
							fileName: res.data.uniqueIdentifier + "_skilledManpower.jpg",
							image: [
								{
									uid: "-1",
									name: `${res.data.uniqueIdentifier}_skilledManpower.jpg`,
									status: "done",
									url: `/api/imageUpload/image/${res.data.uniqueIdentifier}_skilledManpower.jpg`,
								},
							],
						},
					},
				})
			);
		}
	}

	onSubmit = (event) => {
		event.preventDefault();
		const newSkilledManpower = {
			uniqueIdentifier: this.state.uniqueIdentifier,
			ward: this.state.ward,
			manpower: this.state.manpower,
		};

		if (this.props.isUpdate) {
			newSkilledManpower.id = this.props.match.params.id;
			this.props.addInstitutionSurvey(
				"skilledManpower",
				newSkilledManpower,
				this.props.history,
				this.props.match.params.category
			);
		} else
			this.props.addInstitutionSurvey(
				"skilledManpower",
				newSkilledManpower,
				this.props.history,
				this.props.match.params.category
			);
	};
	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};
	handleSelectChange = (name, value) => {
		console.log("handle ", name, value);
		this.setState({
			[name]: value,
		});
	};

	addDhikka = () => {
		this.setState((prevState) => ({
			maxRiskIndex: prevState.maxRiskIndex + 1,
		}));
	};

	removeRow = (index) => () => {
		this.setState((prevState) => {
			const manpower = [...prevState.manpower];
			manpower.splice(index, 1);
			return { manpower, maxRiskIndex: prevState.maxRiskIndex - 1 };
		});
	};

	handleSkillChange = (index) => (event) => {
		// arr.splice(2, 1, "Lene");
		// event.persist();

		const skill = event.target.value;
		this.setState((prevState) => {
			const manpower = [...prevState.manpower];

			const data = {};
			data.skill = skill;
			data.male = isEmpty(manpower[index])
				? ""
				: manpower[index].male;
			data.female = isEmpty(manpower[index])
				? ""
				: manpower[index].female;

			// data.effectOption = isEmpty(manpower[index])
			//   ? ""
			//   : manpower[index].effectOption;
			manpower.splice(index, 1, data);
			return { manpower };
		});
	};

	handleMaleChange = (index) => (event) => {
		const male = event.target.value;
		this.setState((prevState) => {
			const manpower = [...prevState.manpower];

			const data = {};
			data.male = male;
			data.skill = isEmpty(manpower[index])
				? ""
				: manpower[index].skill;
			data.female = isEmpty(manpower[index])
				? ""
				: manpower[index].female;

			manpower.splice(index, 1, data);
			return { manpower };
		});
	};

	handleFemaleChange = (index) => (event) => {
		const female = event.target.value;
		this.setState((prevState) => {
			const manpower = [...prevState.manpower];

			const data = {};
			data.female = female;
			data.skill = isEmpty(manpower[index])
				? ""
				: manpower[index].skill;
			data.male = isEmpty(manpower[index])
				? ""
				: manpower[index].male;

			manpower.splice(index, 1, data);
			return { manpower };
		});
	};

	handleOptionChange = (index) => (event) => {
		console.log(event);
		const effectOption = event.target.value;
		this.setState((prevState) => {
			const manpower = [...prevState.manpower];

			const data = {};
			data.effectOption = effectOption;
			data.skill = isEmpty(manpower[index])
				? ""
				: manpower[index].skill;
			manpower.splice(index, 1, data);
			return { manpower };
		});
	};

	getRiskUI = () => {
		const { maxRiskIndex, manpower } = this.state;
		const columns = [
			{
				title: "S.N",
				dataIndex: "sn",
				key: "sn",
				render: (index) => <p>{getNepaliNumber(index + 1)}</p>,
			},
			{
				title: "Skill",
				dataIndex: "sn",
				key: "skill",
				render: (each) => (
					<Select
						placeholder="------------"
						name="skill"
						value={
							manpower &&
							manpower[each] &&
							manpower[each].skill
						}
						onChange={(value) =>
							this.handleSkillChange(each)({
								target: { name: "skill", value },
							})
						}
						style={{ minWidth: 100 }}
					>
						{manpowers.map((level) => (
							<Option value={level.value}>{level[selectedLanguage]}</Option>
						))}
					</Select>
				),
			},
			{
				title: "Male",
				dataIndex: "sn",
				key: "male",
				render: (each) => (
					<Form.Item label={male[selectedLanguage]}>
						<Input
							type="number"
							name="male"
							onChange={this.handleMaleChange(each)}
							value={
								this.state.manpower &&
								this.state.manpower[each] &&
								this.state.manpower[each].male
							}
						/>
					</Form.Item>
				),
			},
			{
				title: "Female",
				dataIndex: "sn",
				key: "female",
				render: (each) => (
					<Form.Item label={female[selectedLanguage]}>
						<Input
							type="number"
							name="female"
							onChange={this.handleFemaleChange(each)}
							value={
								this.state.manpower &&
								this.state.manpower[each] &&
								this.state.manpower[each].female
							}
						/>
					</Form.Item>
				),
			},
			{
				title: "Action",
				key: "action",
				dataIndex: "sn",
				render: (index) =>
					parseInt(index) === maxRiskIndex - 1 ? (
						<Button type="dashed" onClick={this.addDhikka}>
							<Icon type="plus" />
							{add[selectedLanguage]}
						</Button>
					) : (
						<Button type="dashed" onClick={this.removeRow(index)}>
							<Icon style={{ color: "red" }} type="minus" />
							{remove[selectedLanguage]}
						</Button>
					),
			},
		];

		const dataSource = [...Array(maxRiskIndex).keys()].map((sn) => ({
			sn,
			level: "",
		}));
		return (
			<Grid item xs={12}>
				<Table dataSource={dataSource} columns={columns} pagination={false} />
			</Grid>
		);
	};

	render() {
		const { classes, title } = this.props;
		const { previewVisible, previewImage } = this.state;
		const fileList = this.state.fileList;
		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		return (
			<Paper className={classes.root} elevation={1}>
				<Typography variant="h5">{title}</Typography>
				<Form onSubmit={this.onSubmit}>
					<Grid
						container
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						spacing={3}
					>

						<Grid item xs={6}>
							<Form.Item label={wardQuestion[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={(val) => this.handleSelectChange("ward", val)}
									value={this.state.ward}
								>
									{ward.map((woda) => (
										<Option value={woda.value}>{woda.ward}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={photo[selectedLanguage]}>
								<Fragment>
									{!this.state.isView ? (
										<Upload
											name="file"
											// beforeUpload={this.props.beforeUpload}
											multiple={false}
											// action="/api/imageUpload/upload"
											accept="image/*"
											listType="picture-card"
											fileList={
												fileList.skilledManpowerImage &&
												fileList.skilledManpowerImage.image
											}
											onPreview={this.handlePreview}
											onChange={(fileList) =>
												this.handleChangeFile(
													"skilledManpowerImage",
													fileList,
													"skilledManpower"
												)
											}
											disabled={this.state.isView}
										>
											{fileList &&
											fileList.skilledManpowerImage &&
											fileList.skilledManpowerImage.image &&
											fileList.skilledManpowerImage.image.length === 1
												? null
												: uploadButton}
										</Upload>
									) : (
										<Modal
											visible={previewVisible}
											footer={null}
											onCancel={this.handleCancel}
										>
											<img
												alt="example"
												style={{ width: "100%" }}
												src={previewImage}
											/>
										</Modal>
									)}
								</Fragment>
							</Form.Item>
						</Grid>
						<Grid item xs={12}>
							{this.getRiskUI()}
						</Grid>

						<Grid item xs={12}>
							<Button
								type="primary"
								htmlType="submit"
								disabled={this.state.isView}
							>
								{add[selectedLanguage]}
							</Button>
						</Grid>
					</Grid>
				</Form>
			</Paper>
		);
	}
}

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
});

const mapStateToProps = (state) => ({});

export default withStyles(styles)(
	connect(mapStateToProps, { addInstitutionSurvey })(
		withRouter(SkilledManpower)
	)
);
