import { Grid, IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";

import {
	Button,
	Divider,
	Form,
	Icon,
	Input,
	InputNumber,
	message,
	Modal,
	Select,
	Typography,
	Upload,
	Table,
} from "antd";
import Axios from "axios";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addInstitutionSurvey } from "../../../actions/instituteAction";
import {
	add,
	remove,
	wardQuestion,
	photo,
	incidentTitle,
	incidentList,
	fiscalYear,
} from "../../../variable/institute";
import isEmpty from "../../../utils/validation/is-empty";
import { no, selectedLanguage, ward, yes } from "../../../variable/global";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import CodeInput from "../../common/codeInput/";
import { getPersonalIncident } from "./api";

const { Option } = Select;
//const {TextArea} = Input;

class PersonalIncident extends Component {
	random = (low, high) => {
		return Math.random() * (high - low) + low;
	};
	state = {
		// image upload state
		// uniqueIdentifier:
		//   this.props.match.params.category +
		//   "_" +
		//   this.random(10000, 99999) +
		//   this.random(1000, 9999),
		previewVisible: false,
		previewImage: "",
		loaded: 0,
		ward: "",
		fileList: {
			instituteImage: [],
		},
		maxPopulationsIndex: [1],
		populations: [],

		fiscalYear: [],

		maxYearIndex: 1,
		//incidents: ""
	};

	handleCancel = () =>
		this.setState({
			previewVisible: false,
		});
	capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};
	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	};

	// imageUpload
	// handleChangeFile = (name, value, imageName) => {
	//   //name ma house and value ma list of filelist
	//   this.setState(
	//     prevState => ({
	//       ...prevState,
	//       fileList: {
	//         ...prevState.fileList,
	//         [name]: {
	//           image: value.fileList,
	//           fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg"
	//         }
	//       }
	//     }),
	//     () => {
	//       if (value.fileList.length > 0) this.handleUpload(name, imageName);
	//       if (value.fileList.length === 0) this.removeUpload(name, imageName);
	//     }
	//   );
	// };
	// upload image
	// handleUpload = (name, imageName) => {
	//   const fileList = this.state.fileList[name];
	//   const data = new FormData();
	//   const imageObject = fileList.image[0].originFileObj;

	//   let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
	//   data.append("file", imageObject, fileName);

	//   Axios.post("/api/imageUpload/upload", data, {
	//     onUploadProgress: ProgressEvent => {
	//       this.setState({
	//         loaded: Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
	//       });
	//     }
	//   })
	//     .then(res => {
	//       // then print response status
	//       message.success("upload successfully.");
	//       console.log(res.statusText);
	//     })
	//     .catch(err => message.error("upload failed."));
	// };

	// remove uploaded image
	// removeUpload = imageName => {
	//   let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

	//   Axios.get(`/api/imageUpload/delete/${fileName}`)
	//     .then(res => {
	//       // then print response status
	//       message.success("deleted successfully.");
	//       console.log(res.statusText);
	//     })
	//     .catch(err => message.error("upload failed."));
	// };
	handleDateChange = (name, value) => {
		this.setState({ [name]: value });
	};

	componentDidMount() {
		let personalIncidentId = this.props.match.params.id;
		console.log("m", personalIncidentId);
		if (personalIncidentId) {
			getPersonalIncident(personalIncidentId).then((res) =>
				this.setState(
					{
						ward: res.data.ward,
						fiscalYear: res.data.fiscalYear,

						populations: res.data.population,
						maxPopulationsIndex: res.data.maxPopulationsIndex,
						maxYearIndex: res.data.fiscalYear && res.data.fiscalYear.length,

						// fileList: {
						//   personalIncident: {
						//     fileName: res.data.uniqueIdentifier + "_peronalIncident.jpg",
						//     image: [
						//       {
						//         uid: "-1",
						//         name: `${res.data.uniqueIdentifier}_personalIncident.jpg`,
						//         status: "done",
						//         url: `/api/imageUpload/image/${res.data.uniqueIdentifier}_personalIncident.jpg`
						//       }
						//     ]
						//   }
						// }
					},
					() => console.log("in cmds", this.state)
				)
			);
		}
	}
	onSubmit = (event) => {
		event.preventDefault();
		const newPersonalIncident = {
			// uniqueIdentifier: this.state.uniqueIdentifier,
			// category: this.props.category,
			fiscalYear: this.state.fiscalYear,
			populations: this.state.populations,
			ward: this.state.ward,
			//incidents: this.state.incidents,
		};
		if (this.props.isUpdate) {
			newPersonalIncident.id = this.props.match.params.id;
			this.props.addInstitutionSurvey(
				"personalIncident",
				newPersonalIncident,
				this.props.history,
				this.props.match.params.category
			);
		} else
			this.props.addInstitutionSurvey(
				"personalIncident",
				newPersonalIncident,
				this.props.history,
				this.props.match.params.category
			);
	};
	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};
	handleSelectChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};
	handleInputChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};
	handleReactInputCode = (name) => (value) => {
		this.setState({
			[name]: value,
		});
	};
	// addSpec = () => {
	//   this.setState(prevState => ({
	//     maxSpecIndex: prevState.maxSpecIndex + 1
	//   }));
	// };
	addDhikka = (stateName, yearIndex) => () => {
		var maxIndex = `max${this.capitalizeFirstLetter(stateName)}Index`;
		if (yearIndex === undefined) {
			this.setState((prevState) => {
				// var incidents = prevState.incidents;
				// incidents.splice(yearIndex + 1, 0, []);
				return {
					[maxIndex]: prevState[maxIndex] + 1,
					maxPopulationsIndex: [...(prevState.maxPopulationsIndex + 1)],
				};
			});
		} else {
			this.setState((prevState) => {
				const indexMax = prevState[maxIndex] || [];
				console.log("before indexMax", indexMax);
				indexMax[yearIndex] = parseInt(parseInt(indexMax[yearIndex] || 0) + 1);
				console.log("after indexMax", indexMax);
				return { [maxIndex]: indexMax };
			});
		}
	};
	// handleNameSpec = index => event => {
	// arr.splice(2, 1, "Lene");
	// event.persist();

	//   const name = event.target.value;
	//   this.setState(prevState => {
	//     const spec = [...prevState.spec];

	//     const data = {};
	//     data.name = name;
	//     data.value = isEmpty(spec[index]) ? "" : spec[index].value;
	//     spec.splice(index, 1, data);
	//     return { spec };
	//   });
	// };
	// handleValueSpec = index => event => {
	//   const value = event.target.value;
	//   this.setState(prevState => {
	//     const spec = [...prevState.spec];

	//     const data = {};
	//     data.value = value;
	//     data.name = isEmpty(spec[index]) ? "" : spec[index].name;
	//     spec.splice(index, 1, data);
	//     return { spec };
	//   });
	// };
	handleMultipleInput = (stateName, year, index, name) => (value) => {
		// const { value, name } = evt.target;
		this.setState((prevState) => {
			const schoolPeople = prevState[stateName];
			if (schoolPeople[year] === undefined) schoolPeople[year] = [];
			if (schoolPeople[year][index] === undefined)
				schoolPeople[year][index] = [];
			const data = { ...schoolPeople[year][index], [name]: value };
			schoolPeople[year].splice(index, 1, data);
			return { [stateName]: schoolPeople };
		});
	};
	capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};
	removePeoplRow = (stateName, year, index) => () => {
		this.setState((prevState) => {
			const schoolPeople = prevState[stateName];
			if (schoolPeople[year] === undefined) schoolPeople[year] = [];
			if (schoolPeople[year][index] === undefined)
				schoolPeople[year][index] = [];
			schoolPeople[year].splice(index, 1);
			// incidents.splice(index, 1);
			var maxIndex = `max${this.capitalizeFirstLetter(stateName)}Index`;
			const indexMax = prevState[maxIndex] || [];
			indexMax[year] = parseInt(parseInt(indexMax[year] || 1) - 1);
			return { [stateName]: schoolPeople, [maxIndex]: indexMax };
		});
	};

	getRemovedPeopleData = (stateName, year) => {
		var schoolPeople = this.state[stateName];
		// if (schoolPeople[year] === undefined) schoolPeople[year] = [];
		schoolPeople.splice(year, 1);
		return schoolPeople;
	};
	getRemovedPeopleMaxIndex = (stateName, year) => {
		var maxIndex = `max${this.capitalizeFirstLetter(stateName)}Index`;
		var indexMax = this.state[maxIndex] || [];
		indexMax.splice(year, 1);
		return indexMax;
	};
	removeMainDhikka = (year) => async () => {
		const populations = this.getRemovedPeopleData("populations", year);
		const maxPopulationsIndex = this.getRemovedPeopleMaxIndex(
			"populations",
			year
		);
		const fiscalYear = this.state.fiscalYear;
		const maxYearIndex = this.state.maxYearIndex;
		fiscalYear.splice(year, 1);
		this.setState({
			maxPopulationsIndex,
			populations,

			fiscalYear,
			maxYearIndex: maxYearIndex - 1,
		});
	};

	handleFiscalYearChange = (year) => (data) => {
		this.setState((prevState) => {
			let fiscalYear = prevState.fiscalYear || [];
			fiscalYear[year] = data;
			return { fiscalYear };
		});
	};

	getPopulationUI = (yearIndex) => {
		const { maxPopulationsIndex, populations } = this.state;
		const columns = [
			{
				title: "S.N",
				dataIndex: "sn",
				key: "sn",
				render: (index) => <p>{getNepaliNumber(index + 1)}</p>,
			},
			{
				title: "व्यक्तिगत घटना",
				dataIndex: "sn",
				key: "incidents",
				render: (each) => (
					<Select
						placeholder="------------"
						value={
							populations &&
							populations[yearIndex] &&
							populations[yearIndex][each] &&
							populations[yearIndex][each].incidents
						}
						name="incidents"
						onChange={this.handleMultipleInput(
							"populations",
							yearIndex,
							each,
							"incidents"
						)}
						style={{ minWidth: 100 }}
					>
						{incidentList.map((info) => (
							<Option value={info.value}>{info[selectedLanguage]}</Option>
						))}
					</Select>
				),
			},
			{
				title: "जम्मा",
				dataIndex: "sn",
				key: "total",
				render: (each) => (
					<InputNumber
						name="total"
						type="number"
						min={0}
						value={
							populations &&
							populations[yearIndex] &&
							populations[yearIndex][each] &&
							populations[yearIndex][each].total
						}
						onChange={this.handleMultipleInput(
							"populations",
							yearIndex,
							each,
							"total"
						)}
						margin="normal"
						variant="outlined"
					/>
				),
			},
			{
				title: "Action",
				key: "action",
				dataIndex: "sn",
				render: (index) => (
					<Button
						type="dashed"
						onClick={this.removePeoplRow("populations", yearIndex, index)}
					>
						<Icon style={{ color: "red" }} type="minus" />
						{remove[selectedLanguage]}
					</Button>
				),
			},
		];

		const dataSource = [...Array(maxPopulationsIndex[yearIndex]).keys()].map(
			(sn) => ({
				sn,
				level: "",
			})
		);
		let total =
			populations && populations[yearIndex] && populations[yearIndex].length > 0
				? populations[yearIndex].reduce(
						(acc, cur) => {
							acc.male += parseInt(cur.male || 0);
							acc.female += parseInt(cur.female || 0);
							return acc;
						},
						{ male: 0, female: 0 }
				  )
				: { male: 0, female: 0 };

		return (
			<Grid xs={12}>
				<Form.Item label={fiscalYear[selectedLanguage]}>
					<CodeInput
						fields={4}
						type="number"
						name="fiscalYear"
						// values={[2, 0, 7, 5]}
						values={(() => {
							let data =
								this.state.fiscalYear &&
								this.state.fiscalYear[yearIndex] &&
								this.state.fiscalYear[yearIndex].split("");
							data = data || [0, 0, 0, 0];
							return data;
						})()}
						onChange={this.handleFiscalYearChange(yearIndex)}
					/>
				</Form.Item>
				<Table
					dataSource={dataSource}
					columns={columns}
					footer={() => (
						<p>
							Total Male: {getNepaliNumber(total.male)} and Total female:{" "}
							{getNepaliNumber(total.female)}
						</p>
					)}
					pagination={false}
					title={() => (
						<Button
							type="dashed"
							onClick={this.addDhikka("populations", yearIndex)}
						>
							<Icon type="plus" />
							{add[selectedLanguage]}
						</Button>
					)}
				/>
			</Grid>
		);
	};
	getYearUI = () => {
		const { maxYearIndex } = this.state;
		return [...Array(maxYearIndex).keys()].map((yearIndex) => (
			<Grid>
				<Divider orientation="left">{incidentTitle[selectedLanguage]}</Divider>
				{this.getPopulationUI(yearIndex)}
				{parseInt(yearIndex) === maxYearIndex - 1 ? (
					<Grid container spacing={2}>
						{}{" "}
						<Button
							type="dashed"
							style={{
								marginTop: "20px",
								marginLeft: "auto",
								marginRight: "auto",
							}}
							onClick={this.addDhikka("year")}
						>
							<Icon type="plus" />
							{add[selectedLanguage]}
						</Button>
					</Grid>
				) : (
					<Grid container spacing={2}>
						<Button
							style={{
								marginTop: "20px",
								marginLeft: "auto",
								marginRight: "auto",
							}}
							type="dashed"
							onClick={this.removeMainDhikka(yearIndex)}
						>
							<Icon style={{ color: "red" }} type="minus" />
							{remove[selectedLanguage]}
						</Button>
					</Grid>
				)}
			</Grid>
		));
	};

	render() {
		const { classes, title } = this.props;
		const { previewVisible, previewImage } = this.state;
		const fileList = this.state.fileList;
		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		return (
			<Paper className={classes.root} elevation={1}>
				<Typography variant="h5">{title}</Typography>
				<Form {...formItemLayout} onSubmit={this.onSubmit}>
					<Grid
						container
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
					>
						<Grid xs={6}>
							<Form.Item label={wardQuestion[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={(val) => this.handleSelectChange("ward", val)}
									value={this.state.ward}
									disabled={this.state.isView}
								>
									{ward.map((woda) => (
										<Option value={woda.value}>{woda.ward}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>

						{/* <Grid xs={6}>
              <Form.Item label={photo[selectedLanguage]}>
                <Fragment>
                  {!this.state.isView ? (
                    <Upload
                      name="file"
                      // beforeUpload={this.props.beforeUpload}
                      multiple={false}
                      // action="/api/imageUpload/upload"
                      accept="image/*"
                      listType="picture-card"
                      fileList={
                        fileList.instituteImage && fileList.instituteImage.image
                      }
                      onPreview={this.handlePreview}
                      onChange={fileList =>
                        this.handleChangeFile("instituteImage", fileList, "personalIncident")
                      }
                      disabled={this.state.isView}
                    >
                      {fileList &&
                      fileList.instituteImage &&
                      fileList.instituteImage.image &&
                      fileList.instituteImage.image.length === 1
                        ? null
                        : uploadButton}
                    </Upload>
                  ) : (
                    <Modal
                      visible={previewVisible}
                      footer={null}
                      onCancel={this.handleCancel}
                    >
                      <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={previewImage}
                      />
                    </Modal>
                  )}
                </Fragment>
              </Form.Item>
            </Grid>
            */}

						<Grid xs={12}>{this.getYearUI()}</Grid>

						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								disabled={this.state.isView}
							>
								{this.props.isUpdate ? "update" : add[selectedLanguage]}
							</Button>
						</Form.Item>
					</Grid>
				</Form>
			</Paper>
		);
	}
}
const styles = (theme) => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
});

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 },
	},
};
const mapStateToProps = (state) => ({});

export default withStyles(styles)(
	connect(mapStateToProps, { addInstitutionSurvey })(
		withRouter(PersonalIncident)
	)
);
