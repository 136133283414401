import { Grid, IconButton, Modal } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
	Button,
	Radio,
	Form,
  Icon,
  Divider,
	Input,
	message,
	Select,
	Typography,
	Upload,
	Table
} from "antd";
import Axios from "axios";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addInstitutionSurvey } from "../../../actions/instituteAction";
import isEmpty from "../../../utils/validation/is-empty";
import { no, selectedLanguage, ward, yes } from "../../../variable/global";
import {
	add,
	wardQuestion,
	photo,
  disasterTitle,
  disasterType,
  family,
	amount,
	disasterEffectQn,

	descriptions,
	disasterEffectTitle,
  name,
  disasterQn, remove,
} from "../../../variable/institute";
import { getDisasterEffect } from "./api";

const { TextArea } = Input;

const { Option } = Select;

class DisasterEffect extends Component {
	random = (low, high) => {
		return Math.random() * (high - low) + low;
	};
	state = {
		// image upload state
		uniqueIdentifier:
			Number(this.random(10000, 99999)).toFixed(0) +
			Number(this.random(1000, 9999)).toFixed(0),
		previewVisible: false,
		previewImage: "",
		fileList: {
			busImage: [],
		},
		loaded: 0,
		// other
		ward: "",
		photo: "",
    description: [],
		maxRiskIndex: 1,
		disasterType:"",
    disasterTypeOther:""
    
	};

	// image functions uplocad
	handleCancel = () => this.setState({ previewVisible: false });

	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	};

	// imageUpload
	handleChangeFile = (name, value, imageName) => {
		//name ma house and value ma list of filelist
		console.log("CF", value);
		this.setState(
			(prevState) => ({
				...prevState,
				fileList: {
					...prevState.fileList,
					[name]: {
						image: value.fileList,
						fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg",
					},
				},
			}),
			() => {
				if (value.fileList.length > 0) this.handleUpload(name, imageName);
				if (value.fileList.length === 0) this.removeUpload(name, imageName);
			}
		);
	};

	// upload image
	handleUpload = (name, imageName) => {
		const fileList = this.state.fileList[name];
		const data = new FormData();
		const imageObject = fileList.image[0].originFileObj;

		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
		data.append("file", imageObject, fileName);

		Axios.post("/api/imageUpload/upload", data, {
			onUploadProgress: (ProgressEvent) => {
				this.setState({
					loaded: Math.round(
						(ProgressEvent.loaded / ProgressEvent.total) * 100
					),
				});
			},
		})
			.then((res) => {
				// then print response status
				message.success("upload successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	// remove uploaded image
	removeUpload = (imageName) => {
		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

		Axios.get(`/api/imageUpload/delete/${fileName}`)
			.then((res) => {
				// then print response status
				message.success("deleted successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	componentDidMount() {
		let busId = this.props.match.params.id;
		if (busId) {
			getDisasterEffect(busId).then((res) =>
				this.setState({
					ward: res.data.ward,
          description: res.data.description,
          maxRiskIndex:res.data.description && res.data.description.length,
					disasterType: res.data.disasterType,
				
					// image
					fileList: {
						busImage: {
							fileName: res.data.uniqueIdentifier + "_bus.jpg",
							image: [
								{
									uid: "-1",
									name: `${res.data.uniqueIdentifier}_bus.jpg`,
									status: "done",
									url: `/api/imageUpload/image/${res.data.uniqueIdentifier}_bus.jpg`,
								},
							],
						},
					},
				})
			);
		}
	}

	onSubmit = (event) => {
		event.preventDefault();
		const newInstitute = {
			uniqueIdentifier: this.state.uniqueIdentifier,
			ward: this.state.ward,
      disasterTypeOther: this.state.disasterTypeOther,
			disasterType: this.state.disasterType,
			description: this.state.description,
		
		};

		if (this.props.isUpdate) {
			newInstitute.id = this.props.match.params.id;
			this.props.addInstitutionSurvey(
				"disasterEffect",
				newInstitute,
				this.props.history,
				this.props.match.params.category
			);
		} else
			this.props.addInstitutionSurvey(
				"disasterEffect",
				newInstitute,
				this.props.history,
				this.props.match.params.category
			);
	};
	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};
	handleSelectChange = (name, value) => {
		console.log("handle ", name, value);
		this.setState({
			[name]: value,
		});
  };
  addDhikka = () => {
		this.setState((prevState) => ({
			maxRiskIndex: prevState.maxRiskIndex + 1,
		}));
  };
  removeRow = (index) => () => {
		this.setState((prevState) => {
			const description = [...prevState.description];
			description.splice(index, 1);
			return { description, maxRiskIndex: prevState.maxRiskIndex - 1 };
		});
	};

  handleDisasterChange = (index) => (event) => {
		// arr.splice(2, 1, "Lene");
		// event.persist();

		const name = event.target.value;
		this.setState((prevState) => {
			const description = [...prevState.description];

			const data = {};
			data.name = name;
			data.amount = isEmpty(description[index])
				? ""
				: description[index].amount;
			data.family = isEmpty(description[index])
				? ""
				: description[index].family;

			// data.effectOption = isEmpty(description[index])
			//   ? ""
			//   : description[index].effectOption;
			description.splice(index, 1, data);
			return { description };
		});
	};
	handleDeathChange = (index) => (event) => {
		const amount = event.target.value;
		this.setState((prevState) => {
			const description = [...prevState.description];

			const data = {};
			data.amount = amount;
			data.name = isEmpty(description[index])
				? ""
				: description[index].name;
			data.count = isEmpty(description[index])
				? ""
				: description[index].count;

			description.splice(index, 1, data);
			return { description };
		});
	};

	handleInjuredChange = (index) => (event) => {
		const family = event.target.value;
		this.setState((prevState) => {
			const description = [...prevState.description];

			const data = {};
			data.family = family;
			data.name = isEmpty(description[index])
				? ""
				: description[index].name;
			data.amount = isEmpty(description[index])
				? ""
				: description[index].amount;

			description.splice(index, 1, data);
			return { description };
		});
	};

	handleOptionChange = (index) => (event) => {
		console.log(event);
		const effectOption = event.target.value;
		this.setState((prevState) => {
			const description = [...prevState.description];

			const data = {};
			data.effectOption = effectOption;
			data.name = isEmpty(description[index])
				? ""
				: description[index].name;
			description.splice(index, 1, data);
			return { description };
		});
	};

	getRiskUI = () => {
		const { maxRiskIndex, description } = this.state;
		const columns = [
			// {
			// 	title: "S.N",
			// 	dataIndex: "sn",
			// 	key: "sn",
			// 	render: (index) => <p>{getNepaliNumber(index + 1)}</p>,
			// },
			{
				title: "name",
				dataIndex: "sn",
				key: "name",
				render: (each) => (
					<Select
						placeholder="------------"
						name="name"
						value={
							description &&
							description[each] &&
							description[each].name
						}
						onChange={(value) =>
							this.handleDisasterChange(each)({
								target: { name: "name", value },
							})
						}
						style={{ minWidth: 100 }}
					>
						{descriptions.map((level) => (
							<Option value={level.value}>{level[selectedLanguage]}</Option>
						))}
					</Select>
				),
			},
			{
				title: "Amount",
				dataIndex: "sn",
				key: "amount",
				render: (each) => (
					<Form.Item label={amount[selectedLanguage]}>
						<Input
							type="number"
							name="amount"
							onChange={this.handleDeathChange(each)}
							value={
								this.state.description &&
								this.state.description[each] &&
								this.state.description[each].amount
							}
						/>
					</Form.Item>
				),
			},
			{
				title: "family",
				dataIndex: "sn",
				key: "family",
				render: (each) => (
					<Form.Item label={family[selectedLanguage]}>
						<Input
							type="number"
							name="family"
							onChange={this.handleInjuredChange(each)}
							value={
								this.state.description &&
								this.state.description[each] &&
								this.state.description[each].family
							}
						/>
					</Form.Item>
				),
			},
			{
				title: "Action",
				key: "action",
				dataIndex: "sn",
				render: (index) =>
					parseInt(index) === maxRiskIndex - 1 ? (
						<Button type="dashed" onClick={this.addDhikka}>
							<Icon type="plus" />
							{add[selectedLanguage]}
						</Button>
					) : (
						<Button type="dashed" onClick={this.removeRow(index)}>
							<Icon style={{ color: "red" }} type="minus" />
							{remove[selectedLanguage]}
						</Button>
					),
			},
		];

		const dataSource = [...Array(maxRiskIndex).keys()].map((sn) => ({
			sn,
			level: "",
		}));
		return (
			<Grid item xs={12}>
				<Table dataSource={dataSource} columns={columns} pagination={false} />
			</Grid>
		);
	};

	render() {
		const { classes, title } = this.props;
		const { previewVisible, previewImage } = this.state;
		const fileList = this.state.fileList;
		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		return (
			<Paper className={classes.root} elevation={1}>
				<Typography variant="h5">{title}</Typography>
				<Form onSubmit={this.onSubmit}>
					<Grid
						container
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						spacing={3}
					>
					

						<Grid item xs={6}>
							<Form.Item label={wardQuestion[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={(val) => this.handleSelectChange("ward", val)}
									value={this.state.ward}
									disabled={this.state.isView}
								>
									{ward.map((woda) => (
										<Option value={woda.value}>{woda.ward}</Option>
									))}
								</Select>
							</Form.Item>
            </Grid>
            <Grid item xs={6}>
							<Form.Item label={disasterEffectQn[selectedLanguage]}>
                <Select
                  placeholder="----------"
									onChange={(val)=>this.handleSelectChange("disasterType",val)}
									value={this.state.disasterType}
                >{
                    disasterType.map((type) =>(
                      <Option value={type.value}>{ type[selectedLanguage]}</Option>))
                }
                  </Select>
							</Form.Item>
						</Grid>
					{this.state.disasterType  === "others" &&	<Grid item xs={6}>
							<Form.Item label={disasterEffectQn[selectedLanguage]}>
                <Input
                 
									onChange={this.handleChange("disasterTypeOther")}
									value={this.state.disasterTypeOther}
                />
							</Form.Item>
            </Grid>}
         
         
         

						<Grid item xs={6}>
							<Form.Item label={photo[selectedLanguage]}>
								<Fragment>
									{!this.state.isView ? (
										<Upload
											name="file"
											// beforeUpload={this.props.beforeUpload}
											multiple={false}
											// action="/api/imageUpload/upload"
											accept="image/*"
											listType="picture-card"
											fileList={fileList.busImage && fileList.busImage.image}
											onPreview={this.handlePreview}
											onChange={(fileList) =>
												this.handleChangeFile("busImage", fileList, "bus")
											}
											disabled={this.state.isView}
										>
											{fileList &&
											fileList.busImage &&
											fileList.busImage.image &&
											fileList.busImage.image.length === 1
												? null
												: uploadButton}
										</Upload>
									) : (
										<Modal
											visible={previewVisible}
											footer={null}
											onCancel={this.handleCancel}
										>
											<img
												alt="example"
												style={{ width: "100%" }}
												src={previewImage}
											/>
										</Modal>
									)}
								</Fragment>
							</Form.Item>
						</Grid>
						
						<Divider orientation="left">
							{disasterEffectTitle[selectedLanguage]}
						</Divider>
						<Grid item xs={12}>
							{this.getRiskUI()}
						</Grid>

						<Grid item xs={12}>
							<Button
								type="primary"
								htmlType="submit"
								disabled={this.state.isView}
							>
								{add[selectedLanguage]}
							</Button>
						</Grid>
					</Grid>
				</Form>
			</Paper>
		);
	}
}

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
});

const mapStateToProps = (state) => ({});

export default withStyles(styles)(
	connect(mapStateToProps, { addInstitutionSurvey })(withRouter(DisasterEffect))
);
