import { Grid, IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
	Button,
	Divider,
	Form,
	Icon,
	Input,
	message,
	Modal,
	Radio,
	Select,
	Typography,
	Upload,
	Tooltip,
} from "antd";
import Axios from "axios";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addInstitutionSurvey } from "../../../actions/instituteAction";
import isEmpty from "../../../utils/validation/is-empty";
import { no, selectedLanguage, ward, yes } from "../../../variable/global";
import {
	add,
	agriMarketName,
	agriMarketType,
	agriMarketTypeQn,
	agriMarketHaatName,
	agriMarketHaatWard,
	agriMarketInfra,
	agriHaatMarketOpen,
	marketOpenDay,
	wardQuestion,
	photo,
} from "../../../variable/institute";
import { getAgricultureMarket } from "./api";

const { TextArea } = Input;
const { Option } = Select;
const RadioGroup = Radio.Group;

class AgricultureMarket extends Component {
	random = (low, high) => {
		return Math.random() * (high - low) + low;
	};
	state = {
		// image upload state
		uniqueIdentifier:
			Number(this.random(10000, 99999)).toFixed(0) +
			Number(this.random(1000, 9999)).toFixed(0),
		previewVisible: false,
		previewImage: "",
		loaded: 0,
		// other
		thokBazarName: "",
		thokBazarWard: "",
		thokBazarInfra: "",
		thokHaatBazarName: "",
		thokHaatBazarWard: "",
		thokHaatBazarMarket: "",
		thokHaatBazarInfra: "",
		thokBazarType: "",
		khudraName: "",
		khudraWard: "",
		khudraInfrastructure: "",
		khudraHaatBazarName: "",
		khudraHaatBazarWard: "",
		khudraHaatBazarMarket: "",
		khudraHaatBazarInfra: "",
		khudraBazarType: "",
		fileList: {
			agriMarketImage: {},
		},
	};

	// image functions uplocad
	handleCancel = () => this.setState({ previewVisible: false });

	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	};

	handleInputChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	// imageUpload
	handleChangeFile = (name, value, imageName) => {
		//name ma house and value ma list of filelist
		console.log("CF", value);
		this.setState(
			(prevState) => ({
				...prevState,
				fileList: {
					...prevState.fileList,
					[name]: {
						image: value.fileList,
						fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg",
					},
				},
			}),
			() => {
				if (value.fileList.length > 0) this.handleUpload(name, imageName);
				if (value.fileList.length === 0) this.removeUpload(name, imageName);
			}
		);
	};

	// upload image
	handleUpload = (name, imageName) => {
		const fileList = this.state.fileList[name];
		const data = new FormData();
		const imageObject = fileList.image[0].originFileObj;

		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
		data.append("file", imageObject, fileName);

		Axios.post("/api/imageUpload/upload", data, {
			onUploadProgress: (ProgressEvent) => {
				this.setState({
					loaded: Math.round(
						(ProgressEvent.loaded / ProgressEvent.total) * 100
					),
				});
			},
		})
			.then((res) => {
				// then print response status
				message.success("upload successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	// remove uploaded image
	removeUpload = (imageName) => {
		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

		Axios.get(`/api/imageUpload/delete/${fileName}`)
			.then((res) => {
				// then print response status
				message.success("deleted successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	componentDidMount() {
		let marketId = this.props.match.params.id;
		if (marketId) {
			getAgricultureMarket(marketId).then((res) =>
				this.setState({
					name: res.data.name,
					ward: res.data.ward,
					bazarType: res.data.bazarType,
					infrastructure: res.data.infrastructure,
					haatBazarName: res.data.haatBazar && res.data.haatBazar.name,
					haatBazarWard: res.data.haatBazar && res.data.haatBazar.ward,
					haatBazarMarket:
						res.data.haatBazar && res.data.haatBazar.marketOpenDay,
					haatBazarInfra:
						res.data.haatBazar && res.data.haatBazar.infrastructure,

					// image
					fileList: {
						agriMarketImage: {
							fileName: res.data.uniqueIdentifier + "_agriMarket.jpg",
							image: [
								{
									uid: "-1",
									name: `${res.data.uniqueIdentifier}_agriMarket.jpg`,
									status: "done",
									url: `/api/imageUpload/image/${res.data.uniqueIdentifier}_agriMarket.jpg`,
								},
							],
						},
					},
				})
			);
		}
	}

	onSubmit = (event) => {
		event.preventDefault();

		let newAgriMarket = {
			name: this.state.name,
			ward: this.state.ward,
			infrastructure: this.state.infrastructure,
			haatBazarName: this.state.haatBazarName,
			haatBazarWard: this.state.haatBazarWard,
			haatBazarMarket: this.state.haatBazarMarket,
			haatBazarInfra: this.state.haatBazarInfra,
			bazarType: this.state.bazarType,
		};
		if (this.props.isUpdate) {
			newAgriMarket.id = this.props.match.params.id;
			this.props.addInstitutionSurvey(
				"agricultureMarket",
				newAgriMarket,
				this.props.history,
				this.props.match.params.category
			);
		} else {
			this.props.addInstitutionSurvey(
				"agricultureMarket",
				newAgriMarket,
				this.props.history,
				this.props.match.params.category
			);
		}
	};

	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	handleSelectChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	render() {
		const { classes, title } = this.props;
		const { previewVisible, previewImage } = this.state;
		const fileList = this.state.fileList;
		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		return (
			<Paper className={classes.root} elevation={1}>
				<Typography variant="h5">{title}</Typography>
				<Form onSubmit={this.onSubmit}>
					<Grid
						container
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						spacing={3}
					>
						<Grid item xs={6}>
							<Form.Item label={agriMarketName[selectedLanguage]}>
								<Input
									onChange={this.handleChange("name")}
									value={this.state.name}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={wardQuestion[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={(val) => this.handleSelectChange("ward", val)}
									value={this.state.ward}
								>
									{ward.map((woda) => (
										<Option value={woda.value}>{woda.ward}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={agriMarketInfra[selectedLanguage]}>
								<Tooltip
									trigger={["focus"]}
									title="Please insert comma seperated value"
									placement="topLeft"
									// overlayClassName="numeric-input"
								>
									<TextArea
										name="infrastructure"
										onChange={this.handleInputChange("infrastructure")}
										value={this.state.infrastructure}
										rows={4}
									/>
								</Tooltip>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={agriMarketHaatName[selectedLanguage]}>
								<Input
									onChange={this.handleChange("haatBazarName")}
									value={this.state.haatBazarName}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={agriMarketHaatWard[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={(val) =>
										this.handleSelectChange("haatBazarWard", val)
									}
									value={this.state.haatBazarWard}
									disabled={this.state.isView}
								>
									{ward.map((woda) => (
										<Option value={woda.value}>{woda.ward}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={agriHaatMarketOpen[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={(val) =>
										this.handleSelectChange("haatBazarMarket", val)
									}
									value={this.state.haatBazarMarket}
									disabled={this.state.isView}
								>
									{marketOpenDay.map((woda) => (
										<Option value={woda.value}>{woda.nepali}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={agriMarketInfra[selectedLanguage]}>
								<Tooltip
									trigger={["focus"]}
									title="Please insert comma seperated value"
									placement="topLeft"
									// overlayClassName="numeric-input"
								>
									<TextArea
										name="thokHaatBazarInfra"
										onChange={this.handleInputChange("haatBazarInfra")}
										value={this.state.haatBazarInfra}
										rows={4}
									/>
								</Tooltip>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={agriMarketTypeQn[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={(val) => this.handleSelectChange("bazarType", val)}
									value={this.state.bazarType}
									disabled={this.state.isView}
								>
									{agriMarketType.map((market) => (
										<Option value={market.value}>{market.nepali}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={photo[selectedLanguage]}>
								<Fragment>
									{!this.state.isView ? (
										<Upload
											name="file"
											// beforeUpload={this.props.beforeUpload}
											multiple={false}
											// action="/api/imageUpload/upload"
											accept="image/*"
											listType="picture-card"
											fileList={
												fileList.agriMarketImage &&
												fileList.agriMarketImage.image
											}
											onPreview={this.handlePreview}
											onChange={(fileList) =>
												this.handleChangeFile(
													"agriMarketImage",
													fileList,
													"agriMarket"
												)
											}
											disabled={this.state.isView}
										>
											{fileList &&
											fileList.agriMarketImage &&
											fileList.agriMarketImage.image &&
											fileList.agriMarketImage.image.length === 1
												? null
												: uploadButton}
										</Upload>
									) : (
										<Modal
											visible={previewVisible}
											footer={null}
											onCancel={this.handleCancel}
										>
											<img
												alt="example"
												style={{ width: "100%" }}
												src={previewImage}
											/>
										</Modal>
									)}
								</Fragment>
							</Form.Item>
						</Grid>

						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								disabled={this.state.isView}
							>
								{this.props.isUpdate ? "update" : add[selectedLanguage]}
							</Button>
						</Form.Item>
					</Grid>
				</Form>
			</Paper>
		);
	}
}

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
});

const mapStateToProps = (state) => ({});

export default withStyles(styles)(
	connect(mapStateToProps, { addInstitutionSurvey })(
		withRouter(AgricultureMarket)
	)
);
