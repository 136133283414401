import { Grid, IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
	Button,
	Divider,
	Form,
	Icon,
	Input,
	message,
	Modal,
	Radio,
	Select,
	Typography,
	Upload,
	Tooltip,
} from "antd";
import Axios from "axios";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addInstitutionSurvey } from "../../../actions/instituteAction";
import isEmpty from "../../../utils/validation/is-empty";
import { no, selectedLanguage, ward, yes } from "../../../variable/global";
import {
	add,
	photo,
	wardQuestion,
	maternityDeathSixWeek,
	aliveChild,
	deathChild,
	deathChildTwnetyNineDays,
	femaleCountQs,
	permanent,
	temporary,
	femaleHealthWorker,
} from "../../../variable/institute";
import { getFemaleChildHealth } from "./api";

const { TextArea } = Input;
const { Option } = Select;
const RadioGroup = Radio.Group;

class FemaleChildHealth extends Component {
	random = (low, high) => {
		return Math.random() * (high - low) + low;
	};

	state = {
		// image upload state
		uniqueIdentifier:
			Number(this.random(10000, 99999)).toFixed(0) +
			Number(this.random(1000, 9999)).toFixed(0),
		previewVisible: false,
		previewImage: "",
		fileList: {
			femaleChildHealthImage: [],
		},
		loaded: 0,
		// other
		ward: "",
		maternityDeathSixWeek: "",
		aliveChild: "",
		deathChild: "",
		deathChildTwnetyNineDays: "",
		femaleCount: "",
		femalePermanentPlan: "",
		femaleTemporaryPlan: "",
		malePermanentPlan: "",
		maleTemporaryPlan: "",
		femaleHealthWorker: "",
	};

	// image functions uplocad
	handleCancel = () => this.setState({ previewVisible: false });

	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	};

	// imageUpload
	handleChangeFile = (name, value, imageName) => {
		//name ma house and value ma list of filelist
		console.log("CF", value);
		this.setState(
			(prevState) => ({
				...prevState,
				fileList: {
					...prevState.fileList,
					[name]: {
						image: value.fileList,
						fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg",
					},
				},
			}),
			() => {
				if (value.fileList.length > 0) this.handleUpload(name, imageName);
				if (value.fileList.length === 0) this.removeUpload(name, imageName);
			}
		);
	};

	// upload image
	handleUpload = (name, imageName) => {
		const fileList = this.state.fileList[name];
		const data = new FormData();
		const imageObject = fileList.image[0].originFileObj;

		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
		data.append("file", imageObject, fileName);

		Axios.post("/api/imageUpload/upload", data, {
			onUploadProgress: (ProgressEvent) => {
				this.setState({
					loaded: Math.round(
						(ProgressEvent.loaded / ProgressEvent.total) * 100
					),
				});
			},
		})
			.then((res) => {
				// then print response status
				message.success("upload successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	// remove uploaded image
	removeUpload = (imageName) => {
		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

		Axios.get(`/api/imageUpload/delete/${fileName}`)
			.then((res) => {
				// then print response status
				message.success("deleted successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	componentDidMount() {
		let femaleChildHealthId = this.props.match.params.id;
		if (femaleChildHealthId) {
			getFemaleChildHealth(femaleChildHealthId).then((res) =>
				this.setState({
					ward: res.data.ward,
					maternityDeathSixWeek: res.data.maternityDeathSixWeek,
					aliveChild: res.data.aliveChild,
					deathChild: res.data.deathChild,
					deathChildTwnetyNineDays: res.data.deathChildTwnetyNineDays,
					femaleCount: res.data.femaleCount,
					femalePermanentPlan: res.data.familyPlanning.female.permanentPlan,
					femaleTemporaryPlan: res.data.familyPlanning.female.temporaryPlan,
					malePermanentPlan: res.data.familyPlanning.male.permanentPlan,
					maleTemporaryPlan: res.data.familyPlanning.male.temporaryPlan,
					femaleHealthWorker: res.data.femaleHealthWorker,
					// image
					fileList: {
						femaleChildHealthImage: {
							fileName: res.data.uniqueIdentifier + "_femaleChildHealth.jpg",
							image: [
								{
									uid: "-1",
									name: `${res.data.uniqueIdentifier}_femaleChildHealth.jpg`,
									status: "done",
									url: `/api/imageUpload/image/${res.data.uniqueIdentifier}_femaleChildHealth.jpg`,
								},
							],
						},
					},
				})
			);
		}
	}

	onSubmit = (event) => {
		event.preventDefault();

		let newFemaleChildHealth = {
			ward: this.state.ward,
			uniqueIdentifier: this.state.uniqueIdentifier,
			maternityDeathSixWeek: this.state.maternityDeathSixWeek,
			aliveChild: this.state.aliveChild,
			deathChild: this.state.deathChild,
			deathChildTwnetyNineDays: this.state.deathChildTwnetyNineDays,
			femaleCount: this.state.femaleCount,
			femalePermanentPlan: this.state.femalePermanentPlan,
			femaleTemporaryPlan: this.state.femaleTemporaryPlan,
			malePermanentPlan: this.state.malePermanentPlan,
			maleTemporaryPlan: this.state.maleTemporaryPlan,
			femaleHealthWorker: this.state.femaleHealthWorker,
		};
		console.log("FemaleChildHealth detail", newFemaleChildHealth);
		if (this.props.isUpdate) {
			newFemaleChildHealth.id = this.props.match.params.id;
			this.props.addInstitutionSurvey(
				"femaleChildHealth",
				newFemaleChildHealth,
				this.props.history,
				this.props.match.params.category
			);
		} else {
			this.props.addInstitutionSurvey(
				"femaleChildHealth",
				newFemaleChildHealth,
				this.props.history,
				this.props.match.params.category
			);
		}
	};

	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	handleSelectChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	render() {
		const { classes, title } = this.props;
		const { previewVisible, previewImage } = this.state;
		const fileList = this.state.fileList;
		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		return (
			<Paper className={classes.root} elevation={1}>
				<Typography variant="h5">{title}</Typography>
				<Form onSubmit={this.onSubmit}>
					<Grid
						container
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						spacing={3}
					>
						<Grid item xs={6}>
							<Form.Item label={wardQuestion[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={(val) => this.handleSelectChange("ward", val)}
									value={this.state.ward}
									disabled={this.state.isView}
								>
									{ward.map((woda) => (
										<Option value={woda.value}>{woda.ward}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={maternityDeathSixWeek[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									value={this.state.maternityDeathSixWeek}
									onChange={this.handleChange("maternityDeathSixWeek")}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={aliveChild[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									onChange={this.handleChange("aliveChild")}
									value={this.state.aliveChild}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={deathChild[selectedLanguage]}>
								<Input
									onChange={this.handleChange("deathChild")}
									value={this.state.deathChild}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={deathChildTwnetyNineDays[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									value={this.state.deathChildTwnetyNineDays}
									onChange={this.handleChange("deathChildTwnetyNineDays")}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={femaleCountQs[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									value={this.state.femaleCount}
									onChange={this.handleChange("femaleCount")}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Divider orientation="left">
							परिवार नियोजनको साधन प्रयोग गर्ने
						</Divider>
						<Divider orientation="left">महिला</Divider>

						<Grid item xs={6}>
							<Form.Item label={permanent[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									value={this.state.femalePermanentPlan}
									onChange={this.handleChange("femalePermanentPlan")}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={temporary[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									value={this.state.femaleTemporaryPlan}
									onChange={this.handleChange("femaleTemporaryPlan")}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Divider orientation="left">पुरुष</Divider>

						<Grid item xs={6}>
							<Form.Item label={permanent[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									value={this.state.malePermanentPlan}
									onChange={this.handleChange("malePermanentPlan")}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={temporary[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									value={this.state.maleTemporaryPlan}
									onChange={this.handleChange("maleTemporaryPlan")}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={femaleHealthWorker[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									value={this.state.femaleHealthWorker}
									onChange={this.handleChange("femaleHealthWorker")}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={photo[selectedLanguage]}>
								<Fragment>
									{!this.state.isView ? (
										<Upload
											name="file"
											// beforeUpload={this.props.beforeUpload}
											multiple={false}
											// action="/api/imageUpload/upload"
											accept="image/*"
											listType="picture-card"
											fileList={
												fileList.femaleChildHealthImage &&
												fileList.femaleChildHealthImage.image
											}
											onPreview={this.handlePreview}
											onChange={(fileList) =>
												this.handleChangeFile(
													"femaleChildHealthImage",
													fileList,
													"femaleChildHealth"
												)
											}
											disabled={this.state.isView}
										>
											{fileList &&
											fileList.femaleChildHealthImage &&
											fileList.femaleChildHealthImage.image &&
											fileList.femaleChildHealthImage.image.length === 1
												? null
												: uploadButton}
										</Upload>
									) : (
										<Modal
											visible={previewVisible}
											footer={null}
											onCancel={this.handleCancel}
										>
											<img
												alt="example"
												style={{ width: "100%" }}
												src={previewImage}
											/>
										</Modal>
									)}
								</Fragment>
							</Form.Item>
						</Grid>

						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								disabled={this.state.isView}
							>
								{this.props.isUpdate ? "update" : add[selectedLanguage]}
							</Button>
						</Form.Item>
					</Grid>
				</Form>
			</Paper>
		);
	}
}

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
});

const mapStateToProps = (state) => ({});

export default withStyles(styles)(
	connect(mapStateToProps, { addInstitutionSurvey })(
		withRouter(FemaleChildHealth)
	)
);
