import { Grid, IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
	Button,
	Divider,
	Form,
	Icon,
	Input,
	message,
	Modal,
	Radio,
	Select,
	Typography,
	Upload,
	Tooltip,
} from "antd";
import Axios from "axios";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addInstitutionSurvey } from "../../../actions/instituteAction";
import isEmpty from "../../../utils/validation/is-empty";
import { no, selectedLanguage, ward, yes } from "../../../variable/global";
import {
	add,
	address,
	clubActive,
	darta,
	presidentName,
	presidentContact,
	establish,
	latitude,
	longitude,
	memberMaleCount,
	memberFemaleCount,
	name,
	clubHead,
	photo,
	clubMainWork,
	clubUpdateQn,
	clubType,
	wardQuestion,
	clubTypeQn,
} from "../../../variable/institute";
import { getClub } from "./api";

const { TextArea } = Input;
const { Option } = Select;
const RadioGroup = Radio.Group;

class Club extends Component {
	random = (low, high) => {
		return Math.random() * (high - low) + low;
	};

	state = {
		// image upload state
		uniqueIdentifier:
			Number(this.random(10000, 99999)).toFixed(0) +
			Number(this.random(1000, 9999)).toFixed(0),
		previewVisible: false,
		previewImage: "",
		fileList: {
			clubImage: [],
		},
		loaded: 0,
		// other
		name: "",
		address: "",
		memberCountMale: "",
		memberCountFemale: "",
		clubUpdate: "",
		photo: "",
		clubMainWork: "",
		ward: "",
		presidentName: "",
		presidentContact: "",
		establishedAt: "",
		clubType: "",
	};

	// image functions uplocad
	handleCancel = () => this.setState({ previewVisible: false });

	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	};

	// imageUpload
	handleChangeFile = (name, value, imageName) => {
		//name ma house and value ma list of filelist
		console.log("CF", value);
		this.setState(
			(prevState) => ({
				...prevState,
				fileList: {
					...prevState.fileList,
					[name]: {
						image: value.fileList,
						fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg",
					},
				},
			}),
			() => {
				if (value.fileList.length > 0) this.handleUpload(name, imageName);
				if (value.fileList.length === 0) this.removeUpload(name, imageName);
			}
		);
	};

	// upload image
	handleUpload = (name, imageName) => {
		const fileList = this.state.fileList[name];
		const data = new FormData();
		const imageObject = fileList.image[0].originFileObj;

		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
		data.append("file", imageObject, fileName);

		Axios.post("/api/imageUpload/upload", data, {
			onUploadProgress: (ProgressEvent) => {
				this.setState({
					loaded: Math.round(
						(ProgressEvent.loaded / ProgressEvent.total) * 100
					),
				});
			},
		})
			.then((res) => {
				// then print response status
				message.success("upload successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	// remove uploaded image
	removeUpload = (imageName) => {
		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

		Axios.get(`/api/imageUpload/delete/${fileName}`)
			.then((res) => {
				// then print response status
				message.success("deleted successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	componentDidMount() {
		let clubId = this.props.match.params.id;
		if (clubId) {
			getClub(clubId).then((res) =>
				this.setState({
					name: res.data.name,
					address: res.data.address,
					establishedAt: moment(res.data.establishedAt).format("YYYY-MM-DD"),
					clubUpdate: res.data.clubUpdate === true ? "yes" : "no",
					ward: res.data.ward,
					memberCountFemale: res.data.memberCountFemale,
					memberCountMale: res.data.memberCountMale,
					clubMainWork: res.data.clubMainWork,
					clubType: res.data.clubType,
					presidentName: res.data.president && res.data.president.name,
					presidentContact: res.data.president && res.data.president.contact,
					// image
					fileList: {
						clubImage: {
							fileName: res.data.uniqueIdentifier + "_club.jpg",
							image: [
								{
									uid: "-1",
									name: `${res.data.uniqueIdentifier}_club.jpg`,
									status: "done",
									url: `/api/imageUpload/image/${res.data.uniqueIdentifier}_club.jpg`,
								},
							],
						},
					},
				})
			);
		}
	}

	onSubmit = (event) => {
		event.preventDefault();

		let newClub = {
			name: this.state.name,
			ward: this.state.ward,
			uniqueIdentifier: this.state.uniqueIdentifier,
			establishedAt: this.state.establishedAt,
			clubUpdate: this.state.clubUpdate,
			address: this.state.address,
			clubMainWork: this.state.clubMainWork,
			presidentName: this.state.presidentName,
			memberCountMale: this.state.memberCountMale,
			memberCountFemale: this.state.memberCountFemale,
			presidentContact: this.state.presidentContact,
			clubType: this.state.clubType,
		};
		console.log("club detail", newClub);
		if (this.props.isUpdate) {
			newClub.id = this.props.match.params.id;
			this.props.addInstitutionSurvey(
				"club",
				newClub,
				this.props.history,
				this.props.match.params.category
			);
		} else {
			this.props.addInstitutionSurvey(
				"club",
				newClub,
				this.props.history,
				this.props.match.params.category
			);
		}
	};

	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	handleSelectChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	render() {
		const { classes, title } = this.props;
		const { previewVisible, previewImage } = this.state;
		const fileList = this.state.fileList;
		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		return (
			<Paper className={classes.root} elevation={1}>
				<Typography variant="h5">{title}</Typography>
				<Form onSubmit={this.onSubmit}>
					<Grid
						container
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						spacing={3}
					>
						<Grid item xs={6}>
							<Form.Item label={name[selectedLanguage]}>
								<Input
									onChange={this.handleChange("name")}
									value={this.state.name}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={clubTypeQn[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={(val) => this.handleSelectChange("clubType", val)}
									value={this.state.clubType}
								>
									{clubType.map((woda) => (
										<Option value={woda.value}>{woda[selectedLanguage]}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={wardQuestion[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={(val) => this.handleSelectChange("ward", val)}
									value={this.state.ward}
									disabled={this.state.isView}
								>
									{ward.map((woda) => (
										<Option value={woda.value}>{woda.ward}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={address[selectedLanguage]}>
								<Input
									onChange={this.handleChange("address")}
									value={this.state.address}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={establish[selectedLanguage]}>
								<Input
									type="date"
									name="establishedAt"
									onChange={this.handleChange("establishedAt")}
									value={this.state.establishedAt}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={memberMaleCount[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									value={this.state.memberCountMale}
									onChange={this.handleChange("memberCountMale")}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={memberFemaleCount[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									value={this.state.memberCountFemale}
									onChange={this.handleChange("memberCountFemale")}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={clubUpdateQn[selectedLanguage]}>
								<RadioGroup
									onChange={this.handleChange("clubUpdate")}
									value={this.state.clubUpdate}
								>
									<Radio value="yes" disabled={this.state.isView}>
										{yes[selectedLanguage]}
									</Radio>
									<Radio value="no" disabled={this.state.isView}>
										{no[selectedLanguage]}
									</Radio>
								</RadioGroup>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={photo[selectedLanguage]}>
								<Fragment>
									{!this.state.isView ? (
										<Upload
											name="file"
											// beforeUpload={this.props.beforeUpload}
											multiple={false}
											// action="/api/imageUpload/upload"
											accept="image/*"
											listType="picture-card"
											fileList={fileList.clubImage && fileList.clubImage.image}
											onPreview={this.handlePreview}
											onChange={(fileList) =>
												this.handleChangeFile("clubImage", fileList, "club")
											}
											disabled={this.state.isView}
										>
											{fileList &&
											fileList.clubImage &&
											fileList.clubImage.image &&
											fileList.clubImage.image.length === 1
												? null
												: uploadButton}
										</Upload>
									) : (
										<Modal
											visible={previewVisible}
											footer={null}
											onCancel={this.handleCancel}
										>
											<img
												alt="example"
												style={{ width: "100%" }}
												src={previewImage}
											/>
										</Modal>
									)}
								</Fragment>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={clubMainWork[selectedLanguage]}>
								<Tooltip
									trigger={["focus"]}
									title="Please insert comma seperated value"
									placement="topLeft"
									// overlayClassName="numeric-input"
								>
									<TextArea
										onChange={this.handleChange("clubMainWork")}
										value={this.state.clubMainWork}
										disabled={this.state.isView}
									/>
								</Tooltip>
							</Form.Item>
						</Grid>

						<Divider orientation="left">{clubHead[selectedLanguage]}</Divider>
						<Grid item xs={6}>
							<Form.Item label={presidentName[selectedLanguage]}>
								<Input
									onChange={this.handleChange("presidentName")}
									value={this.state.presidentName}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={presidentContact[selectedLanguage]}>
								<Input
									type="number"
									onChange={this.handleChange("presidentContact")}
									value={this.state.presidentContact}
								/>
							</Form.Item>
						</Grid>

						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								disabled={this.state.isView}
							>
								{this.props.isUpdate ? "update" : add[selectedLanguage]}
							</Button>
						</Form.Item>
					</Grid>
				</Form>
			</Paper>
		);
	}
}

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
});

const mapStateToProps = (state) => ({});

export default withStyles(styles)(
	connect(mapStateToProps, { addInstitutionSurvey })(withRouter(Club))
);
