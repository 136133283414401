import { constant } from "lodash";

export const name = { nepali: "नाम", english: "Name" };
export const category = { nepali: "कुन किसिम", english: "Category" };
export const establish = { nepali: "निर्माण भएको बर्ष", english: "Establish" };
export const description = { nepali: "विवरण", english: "description" };
export const member = { nepali: "कर्मचारी संख्या", english: "Member" };
export const issuedNumber = {
	nepali: "दर्ता नं",
	english: "Issued Number",
};
export const disasterinfra = {
	nepali: "बिपद्को क्षेत्र अनुसार क्षति विवरण ",
	english: "disasterinfra",
};
export const availableQn = {
	nepali: "सिंचाईको उपलब्धता ",
	english: "Available",
};
export const availability = [
	{
		nepali: "बर्षैभरि ",
		english: "year",
		value: "year",
	},
	{
		nepali: "मौसमी",
		english: "month",
		value: "month",
	},
];
export const selfShelter = {
	nepali: "सामाजिक श्रोत तथा सामाग्रीको व्यवस्था  ",
	english: "selfShelter",
};
export const othercapacity = {
	nepali: "आपतको बेला अटाउने क्षमता (जना ) ",
	english: "othercapacity",
};
export const helipad = {
	nepali: "हेलीकप्टर  अवतरणको स्थानहरु ",
	english: "helipad",
};

export const annualTitle = {
	nepali: "वार्षिक उत्पादन ",
	english: "annual Title",
};
export const busStationName = {
	nepali: "तोकीएको बसपार्क स्थान ",
	english: "",
};
export const parkingCount = {
	nepali: "पार्किंग क्षमता  (संख्या) ",
	english: "",
};
export const parkingType = {
	nepali: "पार्किंग को प्रकार ",
	english: "",
};
export const motorCycleCount = {
	nepali: "मोटर साईकल ",
	english: "",
};
export const otherServiceCount = {
	nepali: "साना सवारी साधन  ",
	english: "",
};
export const startAddress = {
	nepali: "सुरुको स्थान  ",
	english: "startAddress",
};
export const organization = {
	nepali: "दैनिक संचालन हुने सवारी संस्था  ",
	english: "organization",
};
export const busStopCount = {
	nepali: "बस स्टप संख्या  ",
	english: "busStopCount",
};
export const endAddress = {
	nepali: "पुग्ने अन्तिम स्थान",
	english: "endAddress",
};

export const countTitle = {
	nepali: "संख्या  ",
	english: "count Title",
};
export const wood = {
	nepali: "काठ ",
	english: "",
};
export const daura = {
	nepali: "दाउरा  ",
	english: "",
};

export const annualPro = {
	nepali: "वन पैदावरको बार्षिक उत्पादन  ",
	english: "",
};
export const ownersName = {
	nepali: "समेटेको घरधुरी  ",
	english: "",
};
export const mainJungle = {
	nepali: "वनको किसिम   ",
	english: "",
};
export const jungleName = {
	nepali: "वनको नाम ",
	english: "jungleName",
};
export const production = {
	nepali: "उत्पादन  ",
	english: "production",
};
export const annualCollection = {
	nepali: "बार्षिक राजस्व संकलन  ",
	english: "annualCollection",
};
export const annualProductions = {
	nepali: "बार्षिक आम्दानी ",
	english: "annualProductions",
};
export const jungleType = [
	{
		nepali: "राष्ट्रिय",
		english: "national",
		value: "national",
	},
	{
		nepali: "सामुदायिक",
		english: "samudayik",
		value: "samudayik",
	},
	{
		nepali: "साझेदारी",
		english: "sajhedari",
		value: "sajhedari",
	},
	{
		nepali: "निजी",
		english: "niji",
		value: "niji",
	},
	{
		nepali: "कबुलियती",
		english: "kabuliyat",
		value: "kabuliyat",
	},
	{
		nepali: "धार्मिक ",
		english: "religious",
		value: "religious",
	},
];

export const boka = {
	nepali: "बोका   ",
	english: "Boka",
};
export const bhakhra = {
	nepali: "बाख्रा    ",
	english: "bhakhra",
};

export const pathyangri = {
	nepali: "पठ्यान्ग्री",
	english: "pathyangri",
};

export const khasi = {
	nepali: "खसी    ",
	english: "khasi",
};

export const pathapathi = {
	nepali: "पाठापाठी",
	english: "pathapathi",
};

export const annualExpenditure = {
	nepali: "वार्षिक खर्च",
	english: "annualExpenditure",
};
// export const annualIncome = {
// 	nepali: "वार्षिक आम्दानि",
// 	english: "annualIncome"
// };

export const cow = {
	nepali: "गाई ",
	english: "cow",
};

export const raga = {
	nepali: "राँगा ",
	english: "raga",
};

export const korali = {
	nepali: "कोरली ",
	english: "korali",
};

export const pada = {
	nepali: "पाडा ",
	english: "pada",
};

export const goru = {
	nepali: "गोरु ",
	english: "goru",
};
export const meatProduction = {
	nepali: "मासु उत्पादन  ",
	english: "meatProduction",
};

export const milkProduction = {
	nepali: "दुध उत्पादन  ",
	english: "milkProduction",
};

export const employeeTitle = {
	nepali: "रोजगारी संख्या  ",
	english: "employee Title",
};

export const presidentTitle = {
	nepali: "अध्यक्ष/प्रोपाईटर",
	english: "President Title",
};

export const irrigationMediumQn = {
	nepali: "सिंचाईको माध्यम ",
	english: "Irrigation medium",
};

export const irrigationMedium = [
	{
		nepali: "कुलो",
		english: "kulo",
		value: "kulo",
	},
	{
		nepali: "नहर",
		english: "nahar",
		value: "nahar",
	},
	{
		nepali: "पाइप ",
		english: "pipe",
		value: "pipe",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];

export const irrigationTypeQn = {
	nepali: "सिंचाईको किसिम ",
	english: "irrigationType",
};

export const sourceArea = {
	nepali: "स्रोतको क्षेत्रफल",
	english: "area",
};
export const sanchitaAreaType = {
	nepali: "सिंचित क्षेत्रको क्षेत्रफल",
	english: "",
};
export const sourceRate = {
	nepali: "परिमाण",
	english: "Rate",
};
export const source = {
	nepali: "कोषको स्रोत",
	english: "source",
};
export const areaPlace = {
	nepali: "सिंचित क्षेत्र",
	english: "areaPlace",
};
export const owner = {
	nepali: "लाभान्वित घरधुरी",
	english: "owner",
};

export const irrigationType = [
	{
		nepali: "सतह (कुलो/नहर )",
		english: "kulo",
		value: "kulo",
	},
	{
		nepali: "लिफटिंङ्ग ",
		english: "lifting",
		value: "lifting",
	},
	{
		nepali: "बाँध",
		english: "badh",
		value: "badh",
	},
	{
		nepali: "ट्युवेल /बोरिङ्ग ",
		english: "tubewell",
		value: "tubewell",
	},
	{
		nepali: "पोखरी",
		english: "pokhari",
		value: "pokhari",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];

export const industryName = {
	nepali: "उधोगको नाम ",
	english: "industry Name",
};

export const workerCount = {
	nepali: "कार्यरहत मजदुर संख्या ",
	english: "worker Count",
};

export const areaList = [
	{
		nepali: "वटा",
		english: "wota",
		value: "wota",
	},
	{
		nepali: "भारी",
		english: "bhari",
		value: "bhari",
	},
	{
		nepali: "विघा",
		english: "Bigha",
		value: "bigha",
	},
	{
		nepali: "कट्ठा",
		english: "katha",
		value: "katha",
	},
	{
		nepali: "हेक्टर",
		english: "Hectar",
		value: "hectar",
	},
	{
		nepali: "धुर",
		english: "dhur",
		value: "dhur",
	},
	{
		nepali: "दाम",
		english: "ropani",
		value: "ropani",
	},
	{
		nepali: "रोपनी",
		english: "ropani",
		value: "ropani",
	},
	{
		nepali: "आना",
		english: "aana",
		value: "aana",
	},
	{
		nepali: "पैसा",
		english: "paisa",
		value: "paisa",
	},
	{
		nepali: "पाथी",
		english: "Pathi",
		value: "pathi",
	},
	{
		nepali: "माना",
		english: "Mana",
		value: "mana",
	},
	{
		nepali: "वर्ग किलोमिटर",
		english: "barghakm",
		value: "bargha_km",
	},
	{
		nepali: "वर्ग मिटर",
		english: "Meter",
		value: "bargha_meter",
	},
	{
		nepali: "किलोमिटर",
		english: "km",
		value: "km",
	},
	{
		nepali: "मिटर",
		english: "Meter",
		value: "meter",
	},
	{
		nepali: "मन",
		english: "Maan",
		value: "maan",
	},
	{
		nepali: "कुइन्टल",
		english: "Quintal",
		value: "quintal",
	},
	{
		nepali: "धार्नी",
		english: "Dharni",
		value: "dharni",
	},
	{
		nepali: "के.जि",
		english: "kg",
		value: "kg",
	},
];

export const areaTypeQn = { nepali: "क्षेत्रफलको प्रकार", english: "" };

export const owners = { nepali: "लाभान्वित घरधुरी", english: "owners" };
export const midArea = {
	nepali: "मध्यवर्ती क्षेत्रको क्षेत्रफल  ",
	english: "midArea",
};

export const service = {
	nepali: "उपलब्ध सेवा",
	english: "Service",
};
export const ownerName = {
	nepali: "स्वामित्व(कोसंग)",
	english: "Owner Name",
};
export const annualPalikaFee = {
	nepali: "गाउँ/नगरपालिकामा बार्षिक शुल्क",
	english: "Owner Name",
};
export const pondArea = {
	nepali: "पोखरी को क्षेतफल कठा बिघा धुर (१-१-१) ",
	english: "PondArea",
};
export const annualProduction = {
	nepali: "बार्षिक माछा उत्पादन ",
	english: "AnnualProduction",
};
export const annualIncome = {
	nepali: "वार्षिक आम्दानी",
	english: "AnnualIncome",
};
export const genderQn = {
	nepali: "लिङ्ग",
	english: "Gender",
};
export const age = {
	nepali: "उमेर",
	english: "Age",
};
export const gameName = {
	nepali: "खेलको नाम",
	english: "Game Name",
};
export const sportsLevelQn = {
	nepali: "खेलकुदको स्तर",
	english: "Sports Level",
};
export const sportsLevel = [
	{
		nepali: "रास्ट्रिय ",
		english: "",
		value: "national",
	},
	{
		nepali: "क्षेत्रीय  ",
		english: "",
		value: "area",
	},
	{
		nepali: "जिल्लास्तर  ",
		english: "",
		value: "district",
	},
];

export const prize = {
	nepali: "पाएको पुरस्कार",
	english: "prize",
};
export const pondAddress = {
	nepali: "पोखरी रहेको स्थान",
	english: "PondAddress",
};

export const mgmtType = {
	nepali: "चरन व्यवस्थापन प्रणाली ",
	english: "mgmtSystem",
};

export const moderatorInfo = { nepali: "माछा पोखरी संचालक", english: "" };

export const address = { nepali: "ठेगाना", english: "Address" };
export const phone = { nepali: "फोन नम्बर", english: "phone" };
export const designation = { nepali: "पद", english: "designation" };
export const count = { nepali: "संख्या", english: "count" };
export const latitude = { nepali: "अक्षांश", english: "latitude" };
export const longitude = { nepali: "देशान्तर", english: "longitude" };
export const wardQuestion = { nepali: "वडा नम्बर", english: "ward number" };
export const ward = [
	{
		nepali: "१",
		english: "1",
		value: "1",
	},
	{
		nepali: "१",
		english: "1",
		value: "1",
	},
	{
		nepali: "१",
		english: "1",
		value: "1",
	},
	{
		nepali: "१",
		english: "1",
		value: "1",
	},
	{
		nepali: "१",
		english: "1",
		value: "1",
	},
	{
		nepali: "१",
		english: "1",
		value: "1",
	},
	{
		nepali: "१",
		english: "1",
		value: "1",
	},
	{
		nepali: "१",
		english: "1",
		value: "1",
	},
	{
		nepali: "१",
		english: "1",
		value: "1",
	},
	{
		nepali: "१",
		english: "1",
		value: "1",
	},
	{
		nepali: "१",
		english: "1",
		value: "1",
	},
];
export const drinkingWaterName = {
	nepali: "खानेपानी योजनाको नाम",
	english: "drinkingWaterName",
};

export const helperInstitute = {
	nepali: "सहयोगी सस्था",
	english: "helperInstitute",
};
export const beneficialHousehold = {
	nepali: "लाभान्वित घरपरिवार तथा घर धुरी",
	english: "beneficialHousehold",
};
export const establishedDate = {
	nepali: "स्थापना मिति",
	english: "establishedDate",
};
export const establishedYearQn = {
	nepali: "स्थापना बर्ष",
	english: "establishedYearQn",
};

export const photo = { nepali: "फोटो", english: "photo" };
export const specName = { nepali: "विशेषता", english: "photo" };
export const specValue = { nepali: "विशेषता", english: "photo" };
export const add = { nepali: "थप्नुहोस", english: "Add" };

export const landField = {
	nepali: "ल्याण्डफिल्ड साईड (जग्गा)को व्यवस्था ",
	english: "landField",
};

// २.११ कृषि बजार तथा हाट बजार सम्बन्धी विवरण
export const agriMarketName = {
	nepali: "कृषि बजारस्थलको नाम",
	english: "Agriculture Market Name",
};
export const agriMarketInfra = {
	nepali: "उपलब्ध प्रमुख पूर्वाधारहरु",
	english: "Main Infrastructure",
};
export const agriMarketHaatName = {
	nepali: "हाटबजार स्थलको नाम",
	english: "Haat bazar name",
};
export const agriMarketHaatWard = {
	nepali: "हाटबजारको वडा नम्बर",
	english: "Haat bazar Ward Number",
};
export const agriHaatMarketOpen = {
	nepali: "हाटबजार लाग्ने दिन वा वार",
	english: "Haat bazar Working days",
};
export const agriMarketTypeQn = {
	nepali: "बजार",
	english: "Haat bazar Working days",
};

export const agriMarketType = [
	{
		nepali: "थोक बजार",
		english: "Thok Bazar",
		value: "thok",
	},
	{
		nepali: "खुद्रा बजार",
		english: "Khudra Bazar",
		value: "khudra",
	},
];
// ends

// ३.२.६ पालिका भित्र देखा परेका प्रमुख रोग र तिनको उपचार सम्बन्धी विवरण
export const palikaDiseaseName = {
	nepali: "रोगको नाम",
	english: "Disease Name",
};
export const sickCount = {
	nepali: "विरामी संख्या",
	english: "Sick Count",
};
export const treatmentCount = {
	nepali: "उपचार भएको संख्या",
	english: "Treatment Count",
};
export const followupCount = {
	nepali: "फलोअप गरिएको संख्या",
	english: "Followup Count",
};
export const referCount = {
	nepali: "रेफर गरिएको संख्या",
	english: "Followup Count",
};
export const diseaseDeathCount = {
	nepali: "मृत्यु भएको संख्या",
	english: "Death Count",
};
export const diseaseList = [
	{
		nepali: "निमोनिया",
		english: "Pneumonia",
		value: "pneumonia",
	},
	{
		nepali: "झाडा पखाला",
		english: "Diarrhea",
		value: "diarrhea",
	},
	{
		nepali: "मलेरिया",
		english: "Malaria",
		value: "malaria",
	},
	{
		nepali: "कालाजार",
		english: "Kalazar",
		value: "Kalazar",
	},
	{
		nepali: "क्षय रोग (टि.वी)",
		english: "tb",
		value: "tb",
	},
	{
		nepali: "कुष्ठ रोग",
		english: "leprosy",
		value: "leprosy",
	},
	{
		nepali: "मुटु रोग",
		english: "heartDisease",
		value: "heartDisease",
	},
	{
		nepali: "रक्तचाप",
		english: "bloodPressure",
		value: "bloodPressure",
	},
	{
		nepali: "ग्यास्ट्रिक/अल्सर",
		english: "gastritis",
		value: "gastritis",
	},
	{
		nepali: "पक्षघात",
		english: "heartAttack",
		value: "heartAttack",
	},
	{
		nepali: "एच आइभिएड्स",
		english: "hiv",
		value: "hiv",
	},
	{
		nepali: "मधुमेह",
		english: "sugar",
		value: "sugar",
	},
	{
		nepali: "दम",
		english: "dum",
		value: "dum",
	},
	{
		nepali: "प्रसुतिजन्य रोग",
		english: "maternityRelated",
		value: "maternityRelated",
	},
	{
		nepali: "अन्य",
		english: "other",
		value: "other",
	},
];
//ends

//क. मानविय श्रोत
export const institutionName = { nepali: "संस्थाको नाम", english: "" };
export const rescueManpower = {
	nepali: "गर्न सक्ने दक्ष जनशक्ति",
	english: "",
};
export const fireRescue = {
	nepali: "आगलागी नियन्त्रण गर्न दक्षजनशक्ति",
	english: "",
};
export const psychologist = {
	nepali: "प्राथमिक उपचार गर्न सक्ने",
	english: "",
};
export const firstAid = { nepali: "मनोसामा जिक परामशकर्ता", english: "" };
//ends

//गाउँपालिका/नगरपालिका,संघीय तथा प्रदेश कार्यालय तथा निकायहरु
export const palikaOffice = {
	nepali: "सरकारी कार्यालय",
	english: "Palika Office",
};
export const palikaAddress = { nepali: "स्थान", english: "Address" };
export const manpower = { nepali: "जनशक्ति", english: "Man power" };
export const workArea = { nepali: "कार्यक्षेत्र", english: "Work Area" };
export const governmentOfficeList = [
	{ nepali: "स्थानीय तह", english: "Local level", value: "local_level" },
	{
		nepali: "स्वास्थ्य संस्थाहरु",
		english: "Health Institute",
		value: "health_institute",
	},
	{ nepali: "कृषि", english: "Agriculture", value: "agriculture" },
	{ nepali: "पशु सेवा", english: "Animal Service", value: "animal_service" },
	{ nepali: "हुलाक", english: "Post office", value: "post_office" },
	{ nepali: "वन", english: "forest", value: "forest" },
	{
		nepali: "प्रहरी कार्यालय",
		english: "Police Station",
		value: "police_station",
	},
	{ nepali: "अन्य कार्यालयहरु", english: "Others", value: "others" },
	{
		nepali: "सरकारी स्वामित्वकासंस्थान,समिति आदि",
		english: "Government",
		value: "government",
	},
];
//ends
export const remove = { nepali: "हटाउनुहोस", english: "Remove" };
export const darta = { nepali: "दर्ता भयो", english: "darta" };
export const itemTitle = { nepali: "", english: "" };
export const area = { nepali: "क्षेत्रफल", english: "" };
export const kachiCount = { nepali: "कच्ची संख्या", english: "" };
export const pakkiCount = { nepali: "पक्की संख्या", english: "" };
export const kachiRoomCount = { nepali: "कच्ची कोठा संख्या", english: "" };
export const pakkiRoomCount = { nepali: "पक्की कोठा संख्या", english: "" };
export const gharTitle = { nepali: "भवन सम्बन्धि", english: "No of House" };
export const roomTitle = { nepali: "कोठा संख्या", english: "No of room" };
export const benchCapacity = {
	nepali: "बेन्चको क्षमता",
	english: "Bench Capacity",
};
export const ground = { nepali: "खेल मैदान", english: "Play ground" };
export const compound = { nepali: "क्षेत्र", english: "Area" };
export const dead = { nepali: "मृत्यु", english: "Dead" };
export const injured = { nepali: "घाइते", english: "Injured" };
export const childplayground = {
	nepali: "बाल मैत्री मैदान",
	english: "Children playground",
};
export const cberga = {
	nepali: "CBERGA कार्यनयाँन भएको ?",
	english: "",
};
export const schoolFacilityTitle = {
	nepali: "विद्यालय सुविधा ",
	english: "School Facility",
};
export const admissionTitle = {
	nepali: "भर्ना दर , निरन्तरता दर , सिकाई उपलब्धी दर",
	english: "School Admission Detail",
};
export const childFriendlyTitle = {
	nepali: "बालमैत्री शिक्षा सम्बन्धि विवरण",
	english: "",
};
export const studentServiceTitle = {
	nepali: "विद्यार्थी उतिर्ण दर तथा विद्यार्थीले पाउने सेवा सुविधा सम्बन्धी",
	english: "",
};
export const healthInfo = [
	{
		nepali: "स्वास्थ्यकर्मी",
		english: "",
		value: "healthWorker",
	},
	{
		nepali: "बिशेषज्ञ चिकित्सक",
		english: "",
		value: "experienceDoctor",
	},
	{
		nepali: "सामान्य चिकित्सक",
		english: "",
		value: "normalDoctor",
	},
	{
		nepali: "हे.अ.",
		english: "",
		value: "heya",
	},
	{
		nepali: "नर्स",
		english: "",
		value: "nurse",
	},
	{
		nepali: "अहेव",
		english: "",
		value: "ahewo",
	},
	{
		nepali: "अनमी",
		english: "",
		value: "aanami",
	},
	{
		nepali: "ल्याव टेक्निसियन",
		english: "",
		value: "labTechnician",
	},
];

export const fiscalYear = { nepali: "मिति", english: "" };
export const rotInfo = {
	nepali: "कुहिने र नकुहिने फोहरको बर्गिकरण ",
	english: "",
};

export const consultant = { nepali: "मनोसामाजिक परामशकर्ता", english: "" };
export const fireManpower = {
	nepali: "आगलागी नियन्त्रण गर्न सक्ने दक्षजनशक्ति ",
	english: "",
};

export const searchManpower = {
	nepali: "खोज तथा उद्दार गर्न सक्ने दक्ष जनशक्ति",
	english: "",
};
export const healthManpower = {
	nepali: "प्राथमिक उपचार गर्न सक्ने ",
	english: "",
};

export const staffTitleList = [
	{
		nepali: "स्विकृत दरबन्दी",
		english: "",
		value: "accepted",
	},
	{
		nepali: "दरबन्दी अनुसार कार्यरत",
		english: "",
		value: "portionWork",
	},
	{
		nepali: "अन्य शिक्षक संख्या",
		english: "",
		value: "otherTeacher",
	},
	{
		nepali: "जम्मा शिक्षक शिक्षिका",
		english: "",
		value: "totalTeacher",
	},
	{
		nepali: "स्थायी शिक्षक, शिक्षिका",
		english: "",
		value: "permanentTeacher",
	},
	{
		nepali: "अस्थायी शिक्षक, शिक्षिका",
		english: "",
		value: "temporaryTeacher",
	},
	{
		nepali: "तालिम प्राप्त शिक्षक",
		english: "",
		value: "skillTraining",
	},
];

export const teacherTitleSecondaryList = [
	{
		nepali: "स्विकृत दरबन्दी संख्या",
		english: "",
		value: "accepted",
	},
	{
		nepali: "अस्थाई",
		english: "",
		value: "temporaryTeacher",
	},
	{
		nepali: "स्थाई",
		english: "",
		value: "permanentTeacher",
	},
];

export const teacherTitleList = [
	{
		nepali: "कार्यरत शिक्षक संख्या",
		english: "",
		value: "portionWork",
	},
	{
		nepali: "महिला शिक्षक संख्या",
		english: "",
		value: "femaleTeacher",
	},
	{
		nepali: "तालिम प्राप्त शिक्षक",
		english: "",
		value: "trainedTeacher",
	},
];

export const isLibrary = {
	nepali: "पुस्तकालय र अध्ययन केन्द्र",
	english: "",
};

export const isCompoundWall = {
	nepali: "कम्पाउण्ड वालको किसिम",
	english: "",
};

export const compoundWallType = [
	{
		nepali: "कच्ची",
		english: "",
		value: "kacchi",
	},
	{
		nepali: "पक्की",
		english: "",
		value: "pakki",
	},
	{
		nepali: "तारबार",
		english: "",
		value: "wired",
	},
];

export const incidentList = [
	{
		nepali: "जन्म ",
		english: "birth",
		value: "birth",
	},
	{
		nepali: "मृत्यु ",
		english: "death",
		value: "death",
	},
	{
		nepali: "विवाह ",
		english: "marriage",
		value: "marriage",
	},
	{
		nepali: "सम्बन्ध विच्छेद ",
		english: "divorce",
		value: "divorce",
	},
	{
		nepali: "बसाइँसराइ आएको",
		english: "inmigration",
		value: "inmigration",
	},
	{
		nepali: "बसाइँसराइ गएको",
		english: "outmigration",
		value: "outmigration",
	},
];
export const optionList = [
	{
		nepali: "ज्येष्ठ नागरिक  ",
		english: "Senior Citizen",
		value: "Senior Citizen",
	},
	{
		nepali: "एकल महिला  ",
		english: "Single Woman",
		value: "single_Woman",
	},
	{
		nepali: "पूर्ण अपाङ्गता    ",
		english: "Full Handicapped",
		value: "Full Handicapped",
	},
	{
		nepali: "आंशिक अपाङ्गता  ",
		english: "Partial Handicapped",
		value: "Partial Handicapped",
	},
	{
		nepali: "बाल सुरक्षा अनुदान  ",
		english: "Child Security",
		value: "Child Security",
	},
	{
		nepali: "दलित वर्ग",
		english: "Dalit",
		value: "Dalit",
	},
];
export const socialSecurityTitle = {
	nepali: "सामाजिक सुरक्षा कार्याक्रम विवरण",
	english: "Social Security Detail ",
};

export const populationMale = {
	nepali: "पुरुष",
	english: "male",
};
export const farmerOrg = {
	nepali: "कृषक समूह",
	english: "farmer",
};
export const forestOrg = {
	nepali: "वन समूह",
	english: "forestOrg",
};
export const communityOrg = {
	nepali: "सामुदायिक संस्था",
	english: "communityOrg",
};
export const governmentOrg = {
	nepali: "सरकारी संस्था ",
	english: "governmentOrg",
};
export const club = {
	nepali: "गैसस वा क्लब",
	english: "club",
};
export const traditionalOrg = {
	nepali: "परम्परागत समुह ",
	english: "traditionalOrg",
};
export const amaOrg = {
	nepali: "आमा समूह",
	english: "amaOrg",
};
export const citizenship = {
	nepali: "नागरिता सचेतना केन्द्र ",
	english: "citizenship",
};
export const tolSudhar = {
	nepali: "टोल सुधार समिति /टोल विकास संस्था ",
	english: "tolSudhar",
};
export const udhyami = {
	nepali: "उधमी समूह ",
	english: "udhyami",
};

export const dalit = {
	nepali: "दलित",
	english: "dalit",
};

export const populationFemale = {
	nepali: "महिला",
	english: "female",
};

export const helperOrganization = {
	nepali: "सहयोगी संस्था",
	english: "Helper Organization",
};

export const populationTitle = {
	nepali: "जनसंख्या विवरण ",
	english: "Population Detail",
};
export const incidentTitle = {
	nepali: "व्यक्तिगत घटना विवरण ",
	english: "Personal Incident Detail",
};

export const healthFacilityTitle = {
	nepali: "स्वास्थ्य संस्था र उपलब्ध सेवाहरुको विवरण",
	english: "",
};

export const healthLabel = {
	nepali: "स्वास्थ्य संस्थाका सेवाहरु",
	english: "",
};
export const shayaCountQn = { nepali: "शैया संख्या", english: "" };
export const vaccineTitle = {
	nepali: "५ वर्ष मुनीका खोप लिएका वाल बालिकाको विवरण",
	english: "",
};

export const vaccineQn = {
	nepali: "खोप लिने र ५ बर्ष मुनिका बालबालिका संख्या",
	english: "",
};
export const vaccineList = [
	{
		nepali: "१ बर्ष मूनिका",
		english: "",
		value: "1_year_below",
	},
	{
		nepali: "२ बर्ष मूनीका",
		english: "",
		value: "2_year_below",
	},
	{
		nepali: "५ वर्ष मूनिका",
		english: "",
		value: "5_year_below",
	},
	{
		nepali: "विसिजी",
		english: "",
		value: "vicious",
	},
	{
		nepali: "पेण्टाभ्यालेन्ट",
		english: "",
		value: "pentavalent",
	},
	{
		nepali: "पोलियो तेश्रो",
		english: "",
		value: "polioThird",
	},
	{
		nepali: "दादुरा र रुबेला",
		english: "",
		value: "dadura",
	},
	{
		nepali: "जापानिज इन्से फलाइटिस",
		english: "",
		value: "japanese",
	},
	{
		nepali: "ब्ृद्धि अनुगमन गरिएका",
		english: "",
		value: "monitoring",
	},
	{
		nepali: "ब्ृद्धि अनुगमन गरिएका मध्ये कुषोषित",
		english: "",
		value: "malnourishedMonitor",
	},
];

export const healthFacility = [
	{
		nepali: "एससिटि/USG",
		english: "",
		value: "sct",
	},
	{
		nepali: "प्रसुती सेवा",
		english: "",
		value: "delivery",
	},
	{
		nepali: "ल्याव",
		english: "",
		value: "lab",
	},
	{
		nepali: "पर्र्याप्त औषधिको व्यवस्था",
		english: "",
		value: "medicine",
	},
	{
		nepali: "क्लिनिक",
		english: "",
		value: "clinic",
	},
	{
		nepali: "एक्सरे सेवा",
		english: "",
		value: "xrays",
	},
	{
		nepali: "परिवार नियोजन",
		english: "",
		value: "familyPlanning",
	},
	{
		nepali: "खोप सेवा",
		english: "",
		value: "vaccine",
	},
	{
		nepali: "परामर्श सेवा",
		english: "",
		value: "consultation",
	},
	{
		nepali: "सुरक्षित मातृत्व सेवा",
		english: "",
		value: "safeMotherhood",
	},
	{
		nepali: "रक्त सञ्चार सेवा",
		english: "",
		value: "bloodBank",
	},
	{
		nepali: "एमबुलेन्स सेवा",
		english: "",
		value: "ambulance",
	},
	{
		nepali: "इमरजेन्सी सेवा",
		english: "",
		value: "emergency",
	},
];
export const healthTitle = {
	nepali: "चिकित्सक र अन्य कर्मचारीको ढरबन्दी /संख्या",
	english: "",
};
export const childFriendlyDetail = [
	{
		nepali: "विधालयको शान्ति क्षेत्र कार्यान्वयन",
		english: "",
		value: "schoolPeaceZone",
	},
	{
		nepali: "विधालय सुधार योजना अधावधिक",
		english: "",
		value: "improvementPlanning",
	},
	{
		nepali: "वाल क्लब गठन",
		english: "",
		value: "childClub",
	},
	{
		nepali: "वलमैत्री शिक्षण विधिको प्रयोग",
		english: "",
		value: "methodOfTeaching",
	},
];

export const admissionDetailName = [
	{
		nepali: "खुद भर्ना दर",
		english: "",
		value: "admissionRate",
	},
	{
		nepali: "निरन्तरता दर",
		english: "",
		value: "continuityRate",
	},
	{
		nepali: "सिकाई उपलब्धि दर",
		english: "",
		value: "learningRate",
	},
	{
		nepali: "उतीर्ण दर",
		english: "",
		value: "passRate",
	},
];

export const admissionLevelName = [
	{
		nepali: "पुर्वप्रथामिक",
		english: "",
		value: "prePrimary",
	},
	{
		nepali: "आधारभूत",
		english: "",
		value: "primary",
	},
	{
		nepali: "माध्यमिक",
		english: "",
		value: "secondary",
	},
	{
		nepali: "कक्षा ८",
		english: "",
		value: "classEight",
	},
	{
		nepali: "कक्षा १०",
		english: "",
		value: "classTen",
	},
	{
		nepali: "कक्षा ११",
		english: "",
		value: "classEleven",
	},
];

export const studentServiceName = [
	{
		nepali: "जम्मा विद्यार्थी",
		english: "",
		value: "totalStudents",
	},
	{
		nepali: "आधारभूत शिक्षा पुरा गर्ने विद्यार्थी",
		english: "",
		value: "primaryCompleted",
	},
	{
		nepali: "छात्रवृत्ति वा अन्य सुविधा पाउने विद्यार्थी",
		english: "",
		value: "scholarship",
	},
	{
		nepali: "दिवा खाजा खान पाउने विद्यार्थी",
		english: "",
		value: "lunchProvided",
	},
	{
		nepali: "कक्षा ९ मा प्राविधिक विषयमा भर्ना हुने विद्यार्थी",
		english: "",
		value: "technicalSubjectAdmission",
	},
	{
		nepali: "विज्ञान विषयमा कक्षा ११ मा भर्ना हुने",
		english: "",
		value: "classElevenScience",
	},
];

export const schoolBhogChalanHeader = {
	nepali: "विद्यालयले भोग चलन गरेको जग्गाको क्षेत्रफल",
	english: "",
};

export const isSchoolLandCertificate = {
	nepali: "विद्यालयले प्रयोग गरेको जग्गाको लालपुर्जा छ /छैन ?",
	english: "",
};

export const schoolArea = {
	nepali: "विद्यालको नाममा रहेको जग्गा क्षेत्रफल",
	english: "",
};

export const scholarshipFacilityHeading = {
	nepali: "छात्रवृत्ति तथा लक्षित सुविधाको विवरण",
	english: "",
};
export const scholarshipFacilityList = [
	{
		nepali: "दलित",
		english: "Dalit",
		value: "dalit",
	},
	{
		nepali: "अपाङ्ग",
		english: "Disable",
		value: "disable",
	},
	{
		nepali: "विपन",
		english: "Vipan",
		value: "vipan",
	},
	{
		nepali: "जेहन्दार",
		english: "Jehandar",
		value: "jehandar",
	},
	{
		nepali: "अन्य सुविशा",
		english: "other facility",
		value: "other_facility",
	},
];

export const maleStudentLeaving = { nepali: "छाडेका वालक", english: "" };
export const femaleStudentLeaving = { nepali: "छाडेका वालिका", english: "" };

export const schoolFacility = [
	{
		nepali: "शौचालय",
		english: "Toilet",
		value: "toilet",
	},
	{
		nepali: "अपङ्गमैत्री,लैङ्गिक मैत्री, शौचालय",
		english: "Disable Toilet",
		value: "disableToilet",
	},
	{
		nepali: "स्वच्छ खानेपानीको व्यवस्था",
		english: "Has Drinking Water",
		value: "hasDrinkingWater",
	},
	{
		nepali: "कक्षा कोठामा डस्टबिनको सुविधा",
		english: "",
		value: "hasDustbinInClass",
	},
	{
		nepali: "इन्टरनेटको सुविधा",
		english: "",
		value: "hasInternet",
	},
	{
		nepali: "हात्धुने ठाउँको व्यवस्था",
		english: "",
		value: "hasHandWash",
	},
	{
		nepali: "प्रयोगसाला",
		english: "",
		value: "hasLab",
	},
	{
		nepali: "फोहर विसर्जन गर्ने उचित ठाउँ",
		english: "Garbage Management",
		value: "garbage_disposal",
	},
	{
		nepali: "एकीकृत पाठ्यक्रम",
		english: "Intergrated Course",
		value: "intergratedCourse",
	},
	{
		nepali: "पुस्तकालय र अध्ययन केन्द्र",
		english: "",
		value: "library",
	},
];
export const schoolFacilityQn = {
	nepali: "विद्यालयमा भएको सुविधा",
	english: "",
};
export const classNineEducation = {
	nepali: "कक्षा ९ देखी प्राविधिक शिक्षा सन्चालन भएको",
	english: "",
};
export const classNineEducationYes = {
	nepali: "छ भने कुन विषयको हो",
	english: "",
};

export const vegetationAnimal = {
	nepali: "वनस्पति र जिवजन्तुहरु",
	english: "",
};
export const grazingTypeQn = {
	nepali: "पाटन चरनको प्रकार",
	english: "",
};

// const mgmtSystem = [
// 		{
// 			nepali: "घुम्ती",
// 			english: "Ghumti",
// 			value: "Ghumti",
// 		},
// 		{
// 			nepali: "चक्लाबन्दी",
// 			english: "ChaklaBandi",
// 			value: "ChaklaBandi",
// 		},
// 		{
// 			nepali: "सामान्य ",
// 			english: "simple",
// 			value: "simple",
// 		}

// ]
export const festAttribute = [
	{
		nepali: "धार्मिक ",
		english: "religious",
		value: "religious",
	},
	{
		nepali: "एतिहासिक ",
		english: "historic",
		value: "historic",
	},
	{
		nepali: "मनोरञ्जन ",
		english: "entertainment",
		value: "entertainment",
	},
];

export const grazingType = [
	{
		nepali: "निजी",
		english: "",
		value: "private",
	},
	{
		nepali: "सार्वजनिक",
		english: "",
		value: "public",
	},
];
export const gender = [
	{
		nepali: "पुरुष ",
		english: "",
		value: "male",
	},
	{
		nepali: "महिला ",
		english: "",
		value: "female",
	},
	{
		nepali: " अन्य",
		english: "",
		value: "others",
	},
];

export const collegeTypeQn = { nepali: "कलेजको प्रकार", english: "" };

export const collegeType = [
	{
		nepali: "सरकारी",
		english: "Government",
		value: "government",
	},
	{
		nepali: "निजी / संस्थागत",
		english: "Institution",
		value: "institution",
	},
	{
		nepali: "अन्य खुलाउने",
		english: "Others",
		value: "other",
	},
];

export const isCertifiedCollege = {
	nepali: "मान्यताप्राप्त विश्वविद्यालय",
	english: "",
};
export const collegeBhogChalanHeader = {
	nepali: "कलेजले भोग चलन गरेको जम्मा जग्गा क्षेत्रफल",
	english: "",
};
export const collegeElaani = { nepali: "ऐलानी", english: "" };
export const collegeNambari = { nepali: "नम्बरी", english: "" };
export const collegeArea = {
	nepali: "कलेजको नाममा रहेको जग्गा क्षेत्रफल",
	english: "",
};
export const collegeLandCertificate = {
	nepali: "कलेजले प्रयोग गरेको जग्गाको लालपुर्जा छ /छैन",
	english: "",
};
export const collegePhysicalStatus = { nepali: "भौतिक अवस्था", english: "" };

export const collegeSpecificationQn = { nepali: "विशिष्टता", english: "" };
export const compoundWallQn = { nepali: "कम्पाउण्ड िालको वकवसम", english: "" };
export const wasteManagementQn = {
	nepali: "फोहर विसजगन गने उवचत ठाउँ",
	english: "",
};

export const collegeSpecification = [
	{
		nepali: "शौचालय",
		english: "Toilet",
		value: "toilet",
	},
	{
		nepali: "अपाङ्गमैत्री, लैङ्गिक मैत्री,शौचालय",
		english: "Disable",
		value: "disable",
	},
	{
		nepali: "हात धनुे ठाउँको व्यवस्था",
		english: "Handwash",
		value: "hand_wash",
	},
	{
		nepali: "स्वच्छ खानेपानीको व्यवस्था",
		english: "fresh drinking water",
		value: "drinking_water",
	},
	{
		nepali: "पुस्तकालय र अध्ययन केन्द्र",
		english: "library",
		value: "library",
	},
	{
		nepali: "प्रयोगिाला(विज्ञान/कवम्पयटूर/अन्द्य)",
		english: "Lab",
		value: "lab",
	},
];
export const suggestionQn = { nepali: "कैफयत", english: "" };
export const permanentAgreement = { nepali: "स्थायी,करार", english: "" };
// export const college = {nepali: "कलेजलेप्रयोि िरेको जग्िाको लालपुजाषछ /छैन ?",english: ""};

export const furniture = { nepali: "आवश्यकता अनुसार्को फर्नीचर", english: "" };
export const facultyQn = { nepali: "संकाय", english: "Faculty" };
export const collegelevel = { nepali: "पढाई हुने तह", english: "" };
export const collegeSubject = {
	nepali: "पढाई हुने मुख्य विषयहरु",
	english: "",
};
export const toilet = { nepali: "शौचालय", english: "Toilet" };
export const hastoilet = { nepali: "शौचालय छ/छैन", english: "Toilet yes / no" };
export const teacherMale = { nepali: "पुरुष", english: "Male" };
export const teacherFemale = { nepali: "महिला", english: "Female" };
export const maleCount = {
	nepali: "पुरुष शौचालय संख्या",
	english: "No of jeans toilet",
};
export const male = {
	nepali: "पुरुष संख्या ",
	english: "male",
};
export const female = {
	nepali: "महिला  संख्या  ",
	english: "female",
};
export const femaleCount = {
	nepali: "महिला शौचालय संख्या",
	english: "No of female toilet",
};
export const staffCount = {
	nepali: "कर्मचारी शौचालय संख्या",
	english: "No of staff toilet",
};
export const staffTitle = {
	nepali: "कर्मचारी संख्या",
	english: "No of employee",
};
export const hasdrinking = {
	nepali: "खानेपानी छ/छैन",
	english: "Drinking water yes/no",
};
export const appointAs = { nepali: "नियुक्ती", english: "Appoint" };
export const staff = { nepali: "कर्मचारी", english: "Employee" };
export const student = { nepali: "विद्यार्थी संख्या", english: "Student" };
export const schoolClass = { nepali: "कक्षा", english: "Class" };
export const malestudent = { nepali: "छात्र", english: "Male student" };
export const femalestudent = { nepali: "छात्रा", english: "Female student" };
export const website = { nepali: "वेबसाईट", english: "Website" };
export const email = { nepali: "ईमेल", english: "Email" };
export const schooltype = {
	nepali: "बिद्यालय प्रकार",
	english: "Type of school",
};
export const schoollevel = { nepali: "बिद्यालय तह", english: "School level" };
export const specTitle = { nepali: "विशेषता भर्नुहोस", english: "" };
export const position = { nepali: "पद", english: "Postiton" };
export const emergencyNo = { nepali: "आकस्मिक नम्बर", english: "Emergency No" };
export const instituteTitle = {
	nepali: "संस्थान प्रकार",
	english: "Instution type",
};
export const buildingCount = {
	nepali: "भवनको संख्या",
	english: "No of building",
};
export const consumedUser = {
	nepali: "लाभान्वित संख्या",
	english: "Profitable family",
};
export const teacher = { nepali: "शिक्षक शिक्षीका", english: "" };
export const vehicleTitle = {
	nepali: "सवारी साधन",
	english: "Means of transportation",
};
export const memberTitle = { nepali: "सदस्य विवरण", english: "Member detail" };
export const clubActive = {
	nepali: "सकृय छ / छैन",
	english: "Active yes / no",
};
export const categoryTitle = { nepali: "वर्ग", english: "category" };
export const suffixCategoryList = {
	english: "'s details",
	nepali: " को विवरण",
};
export const fiscal = { nepali: "आर्थिक बर्ष", english: "Fiscal Year" };
export const dartachalani = {
	nepali: "दर्ता चलनी नं",
	english: "Darta Chalani No",
};
export const budget = { nepali: "बजेट /लागत", english: "Budget" };
export const timespan = { nepali: "समय अवधी", english: "Timespan" };
export const studentProd = {
	nepali: "वडगत लाभान्वित जन्संख्या",
	english: "Timespan",
};
export const staffProd = {
	nepali: "शिक्षक/शिक्षिका संख्या",
	english: "Timespan",
};
export const targetedCategory = {
	nepali: "लक्षित वर्ग",
	english: "Targeted Category",
};
export const helper = {
	nepali: "सहयोगी संस्था",
	english: "Helping Organization",
};
export const effectedHouse = {
	nepali: "लाभान्वित घरधुरी",
	english: "Effected Household",
};
export const pdf = { nepali: "पिडिएफ", english: "PDF" };
export const categoryList = [
	{
		nepali: "स्कूल",
		english: "School",
		value: "school",
	},
	{
		nepali: "कलेज",
		english: "College",
		value: "college",
	},
	{
		nepali: "प्राविधिक शिक्षा",
		english: "Technical Education",
		value: "technicalEducation",
	},
	{
		nepali: "प्रौड शिक्षा",
		english: "Prod Education",
		value: "prodEducation",
	},
	{
		nepali: "सरकारी संस्थान / गैर सरकारी संस्थान",
		english: "NGO/INGO",
		value: "ngo",
	},
	{
		nepali: "सुरक्षा निकाय विवरण",
		english: "Police Station",
		value: "police",
	},
	{
		nepali: "बैंक र वित्तीय संस्था",
		english: "Bank And Finance",
		value: "bank",
	},
	{
		nepali: "सामुदायिक भवन",
		english: "Community Hall",
		value: "communityHall",
	},
	{
		nepali: "सरकारी कार्यालय तथा कर्मचारीहरुको सरकारी बासस्थान",
		english: "Quarter",
		value: "quarter",
	},
	{
		nepali: "अस्पताल",
		english: "Hospital",
		value: "hospital",
	},
	{
		nepali: "स्वास्थ्य चौकी",
		english: "Health Post",
		value: "healthPost",
	},
	{
		nepali: "पशु (भेटेनरी)/उपचार केन्द्र",
		english: "Animal Veterinary",
		value: "animalVeterinary",
	},
	{
		nepali: "पर्यटकिय  क्षेत्र",
		english: "Tourist Place",
		value: "touristPlace",
	},
	{
		nepali: "पार्क",
		english: "Park",
		value: "park",
	},
	{
		nepali: "होटल र लज",
		english: "Hotel And Lodge",
		value: "hotel",
	},
	{
		nepali: "खानी",
		english: "Mine",
		value: "mine",
	},
	{
		nepali: "वनक्षेत्र",
		english: "Forest",
		value: "forest",
	},
	{
		nepali: "राष्ट्रिय निकुञ्ज",
		english: "Nikunja",
		value: "nikunja",
	},
	{
		nepali: "पुल",
		english: "Bridge",
		value: "bridge",
	},
	{
		nepali: "मैदान",
		english: "Ground",
		value: "ground",
	},
	{
		nepali: "हाट बजार मार्केट",
		english: "Market",
		value: "market",
	},
	{
		nepali: "जडीबुटी तथा वनस्पति",
		english: "Flora",
		value: "flora",
	},
	{
		nepali: "केबलकार",
		english: "Cable Car",
		value: "cableCar",
	},
	{
		nepali: "तुइन भएको खोला/नदी",
		english: "",
		value: "tuin",
	},
	{
		nepali: "प्रमुख खोला/नदी",
		english: "",
		value: "mainRiver",
	},
	{
		nepali: "संचार टावर",
		english: "Communication Tower",
		value: "communication",
	},
	{
		nepali: "एफ.एम",
		english: "Fm",
		value: "fm",
	},
	{
		nepali: "पत्र पत्रिका",
		english: "Newspaper",
		value: "newspaper",
	},
	{
		nepali: "केवल नेटवर्क",
		english: "Cable Network",
		value: "cableNetwork",
	},
	{
		nepali: "मठ मन्दिर",
		english: "Temple",
		value: "temple",
	},
	{
		nepali: "मुख्य व्यवसाय",
		english: "Main Business",
		value: "mainBusiness",
	},
	{
		nepali: "प्रमुख मेला, चाडपर्व तथा जात्राहरु",
		english: "Festival",
		value: "festival",
	},
	{
		nepali: "मुख्य बस टर्मिनल",
		english: "Bus Park",
		value: "busPark",
	},
	{
		nepali: "सार्वजनिक शौचालय",
		english: "Public Toilet",
		value: "publicToilet",
	},

	{
		nepali: "विपद घटनाहरुमा क्षति (विगत ३ वर्ष)",
		english: "Incident Loss",
		value: "incidentLoss",
	},
	{
		nepali: "वाढी, पहिरोको जोखिम क्षेत्र",
		english: "Risk Management",
		value: "possibleRisk",
	},
	{
		nepali: "पशुपन्छी",
		english: "Animal",
		value: "animal",
	},
	{
		nepali: "चरण क्षेत्र",
		english: "Animal Place",
		value: "animalPlace",
	},
	{
		nepali: "कृषि तथा पशु फार्म",
		english: "Animal Farm",
		value: "animalFarm",
	},
	{
		nepali: "मुख्य मेकानिकल वर्कसप",
		english: "Service Center",
		value: "serviceCenter",
	},
	{
		nepali: "तालिम केन्द्र",
		english: "Trainning Institute",
		value: "trainningInstitute",
	},
	{
		nepali: "विधुत गृह",
		english: "Power House",
		value: "powerHouse",
	},
	{
		nepali: "विद्युतीय ट्रान्समिसन लाईन",
		english: "Transmission Line",
		value: "transmissionLine",
	},
	{
		nepali: "सडक सञ्जाल",
		english: "",
		value: "roadNetwork",
	},
	{
		nepali: "निशुल्क वाइफाइ उपलब्ध भएका स्थान",
		english: "",
		value: "freeWifi",
	},
	{
		nepali: "क्लबहरु",
		english: "Clubs",
		value: "club",
	},

	{
		nepali: "परिवारको विवरण",
		english: "",
		value: "villageCounilFamily",
	},
	{
		nepali: "कर्मचारीहरुको विवरण",
		english: "",
		value: "villageCounilStaff",
	},
];
export const organizations = [
	{
		nepali: "त्रिपाल  ",
		english: "Tripal",
		value: "Tripal",
	},
	{
		nepali: "म्याट्रेस ",
		english: "Matress",
		value: "Matress",
	},
	{
		nepali: "कम्बल, ब्ल्यान्केट  ",
		english: "blanket",
		value: "blanket",
	},
	{
		nepali: "दिगनिती  किट  ",
		english: "dignti",
		value: "dignti",
	},
	{
		nepali: "हाइजीन किट  ",
		english: "hygiene",
		value: "hygiene",
	},
	{
		nepali: "बेबी किट  ",
		english: "baby",
		value: "baby",
	},
	{
		nepali: "इसिडी किट  ",
		english: "ecd",
		value: "ecd",
	},
	{
		nepali: "स्टुडेन्ट किट  ",
		english: "student",
		value: "student",
	},
	{
		nepali: "सेल्टर किट  ",
		english: "shelter",
		value: "shelter",
	},
	{
		nepali: "फस्ट एडकिट  ",
		english: "first",
		value: "first",
	},
	{
		nepali: "डुंगा  ",
		english: "boat",
		value: "boat",
	},
	{
		nepali: "लाइफ ज्याकेट  ",
		english: "lifeJacket",
		value: "lifeJacket",
	},
	{
		nepali: "गौटी   ",
		english: "goti",
		value: "goti",
	},
	{
		nepali: "डोरी   ",
		english: "dori",
		value: "dori",
	},
	{
		nepali: "साबेल   ",
		english: "shabel",
		value: "shabel",
	},
	{
		nepali: "फोल्डिंग भर्र्याङ   ",
		english: "folding",
		value: "folding",
	},
	{
		nepali: "फाईर इसिटनगुइसर   ",
		english: "fire",
		value: "fire",
	},
	{
		nepali: "सिमेन्ट कटर    ",
		english: "cement",
		value: "cement",
	},
	{
		nepali: "गल   ",
		english: "gal",
		value: "gal",
	},
	{
		nepali: "हेल्मेट   ",
		english: "helmet",
		value: "helmet",
	},
];

export const physicalTitle = {
	nepali: "उपलब्ध सामग्री विवरण ",
	english: "",
};
export const organizationName = {
	nepali: "संस्थाको नाम  ",
	english: "",
};
export const municipality = [
	{
		nepali: "गाउँ",
		english: "gau",
		value: "gau",
	},
	{
		nepali: "नगरपालिका ",
		english: "Nagarpalika",
		value: "Nagarpalika",
	},
];

export const municipalityTitle = {
	nepali: "गाउँ/ नगरपालिका   ",
	english: "",
};

export const availableResource = {
	nepali: "उपलब्ध सामग्री    ",
	english: "",
};

export const healthInstituteType = [
	{
		nepali: "संस्थागत",
		english: "private",
		value: "private",
	},
	{
		nepali: "सामुदायिक",
		english: "community",
		value: "community",
	},
];

// export const irrigationMedium = [
// 	{
// 		nepali: "कुलो",
// 		english: "kulo",
// 		value: "kulo"
// 	},
// 	{
// 		nepali: "नहर",
// 		english: "nahar",
// 		value: "nahar"
// 	},
// 	{
// 		nepali: "पाइप ",
// 		english: "pipe",
// 		value: "pipe"
// 	},
// 	{
// 		nepali: "अन्य",
// 		english: "others",
// 		value: "others"
// 	},
// ];

export const instituteType = [
	{
		nepali: "मदरसा",
		english: "Madarsa",
		value: "madarasa",
	},
	{
		nepali: "गुम्बा",
		english: "Gumba",
		value: "gumba",
	},
	{
		nepali: "संस्थागत",
		english: "private",
		value: "private",
	},
	{
		nepali: "सामुदायिक",
		english: "community",
		value: "community",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];
export const hotelType = [
	{
		nepali: "आबासीय",
		english: "Residental",
		value: "residental",
	},
	{
		nepali: "रिसोर्ट",
		english: "Resort",
		value: "resort",
	},
	{
		nepali: "होमस्टे",
		english: "Homestay",
		value: "homestay",
	},
	{
		nepali: "रेस्टुरेन्ट",
		english: "Resturant",
		value: "resturant",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];
export const schoolLevel = [
	{
		nepali: "बाल विकास केन्द्र",
		english: "Kinder Garden",
		value: "kinder_garden",
	},
	// {
	// 	nepali: "पूर्व प्राथमिक",
	// 	english: "Pre Primary",
	// 	value: "pre_primary",
	// },
	{
		nepali: "आधारभूत तह",
		english: "Primary",
		value: "primary",
	},
	{
		nepali: "माध्यमिक तह",
		english: "Secondary",
		value: "secondary",
	},
	{
		nepali: "प्राविधिक एस.एल.सी. (Overseer)",
		english: "SLC",
		value: "slc",
	},
	// {
	// 	nepali: "स्नातक तह",
	// 	english: "Bachelor",
	// 	value: "bachelor",
	// },
];

export const isEarthquakeResistance = {
	nepali: "सुधारिएको मापदण्ड (भूकम्प प्रतिरोधी ) अनुसार निर्माण",
	english: "",
};

export const isDisableClassProvide = {
	nepali: "अपाङ्गता तथा विशेष शिक्षा स्रोत कक्षा संचालन",
	english: "",
};
export const isRetrofittingDone = {
	nepali: "रेट्रोफिटिङ्ग गरेको",
	english: "",
};

export const bhayeko = {
	nepali: "भएको",
	english: "Yes",
	value: "yes",
};
export const nabhayeko = {
	nepali: "नभएको",
	english: "No",
	value: "no",
};

export const land = {
	nepali: "जग्गा ",
	english: "land",
};
export const office = {
	nepali: "भवन  ",
	english: "office",
};
export const watertype = [
	{
		nepali: "संरक्षित पानी मुहान",
		english: "water resource",
		value: "water resource",
	},
	{
		nepali: "नदी नियन्त्रण तथा उकासबाट संरक्षित क्षेत्रको नाम ",
		english: "river",
		value: "river",
	},
	{
		nepali: "संरक्षित खेवारीको क्षेत्रफल ",
		english: "khewari",
		value: "khewari",
	},
	{
		nepali: "भिरालो रोकथाम तथा संरक्षित संरचन",
		english: "bhiralo",
		value: "bhiralo",
	},
	{
		nepali: "संरक्षित गल्छी र पहिरो  ",
		english: "flood",
		value: "flood",
	},
];

export const officeNameType = {
	nepali: "कार्यालयको नाम   ",
	english: "officeNameType",
};
export const areaType = {
	nepali: "क्षेत्रफल (वर्ग. मी.) ",
	english: "areaType",
};
export const statuskind = {
	nepali: "अवस्था ",
	english: "statuskind",
};
export const useQn = {
	nepali: "उपयोग  ",
	english: "useQn",
};
export const use = [
	{
		nepali: "खेलकुद  ",
		english: "game",
		value: "game",
	},
	{
		nepali: "पर्दशनी",
		english: "pradarsi",
		value: "pradarsi",
	},
	{
		nepali: "मनोरञ्जन  ",
		english: "entertainment",
		value: "entertainment",
	},
	{
		nepali: "पिकनिक   ",
		english: "picnic",
		value: "picnic",
	},
];

export const benefittedHouse = {
	nepali: "लाभान्वित घरधुरी  ",
	english: "benefittedHouse",
};

export const status = [
	{
		nepali: "राम्रै   ",
		english: "ramrai",
		value: "ramrai",
	},
	{
		nepali: "ठिकै    ",
		english: "thikai",
		value: "thikai",
	},
	{
		nepali: "जिर्ण    ",
		english: "jirn",
		value: "jirn",
	},
];

export const statusToilet = [
	{
		nepali: "कच्ची ",
		english: "kacchi",
		value: "kacchi",
	},
	{
		nepali: "पक्की  ",
		english: "pakki",
		value: "pakki",
	},
];
export const studentLevel = [
	{
		nepali: "विद्यार्थी संख्या",
		english: "Total Student",
		value: "totalStudent",
	},
	{
		nepali: "पूर्व प्राथमिक (१ कक्षा भन्दा तल )",
		english: "Pre Primary",
		value: "pre_primary",
	},
	{
		nepali: "आधारभूत तह",
		english: "Primary",
		value: "primary",
	},
	{
		nepali: "माध्यमिक तह",
		english: "Secondary",
		value: "secondary",
	},
	{
		nepali: "अपाङ्गता भएको विद्यार्थी",
		english: "Disable Students",
		value: "disable",
	},
	{
		nepali: "छात्रवृत्ति पाउने विद्यार्थी",
		english: "scholarship",
		value: "scholarship",
	},
];

export const fieldType = { nepali: "कुन बिभाग", english: "Which depart" };

export const instituteList = ["School"];

export const deleteMsg = {
	nepali: "के तपाई प्रयोगकर्ता हटाउन चहानुहुन्छ ?",
	english: "Are you sure you want to delete the user?",
};

export const agree = { nepali: "हुन्छ ", english: "Agree" };

export const disagree = { nepali: "हुदैन", english: "Disagree" };

export const mainAnimalQn = {
	nepali: "प्रमुख जनावर तथा चरा चुरुंगी हरु",
	english: "",
};

export const forestInfoQn = { nepali: "वनको सेवा", english: "" };

export const forestInfoList = [
	{
		nepali: "वन उपभोक्ता समिति",
		english: "",
		value: "committee",
	},
	{
		nepali: "वन उपभोक्ता महासंघमा आवद्धता",
		english: "",
		value: "consistency",
	},
	{
		nepali: "वन कार्ययोजना",
		english: "",
		value: "planning",
	},
	{
		nepali: "नियमित बैठक बस्ने गरेको",
		english: "",
		value: "regularMeeting",
	},
	{
		nepali: "वन भित्र सामुहिक खेति",
		english: "",
		value: "agricultureInForest",
	},
	{
		nepali: "आम्दानी र खर्चको पारदर्शिता",
		english: "",
		value: "incomeExpense",
	},
];

export const presidentName = { nepali: "अध्यक्षको नाम", english: "" };
export const presidentContact = {
	nepali: "अध्यक्षको सम्पर्क नम्बर",
	english: "",
};

export const farmTypeQn = { nepali: "किसिम", english: "" };

export const animal = {
	nepali: "उत्पादित वस्तु ",
	english: "animal",
};

export const traditionalCount = {
	nepali: "परम्परागत ",
	english: "",
};

export const renovateCount = {
	nepali: "सुधारिएको  ",
	english: "",
};
export const waterMill = {
	nepali: "पानी मिल",
	english: "",
};

export const dieselMill = {
	nepali: "डिजेल मिल   ",
	english: "",
};

export const electricityMill = {
	nepali: "विधुत मिल   ",
	english: "",
};

export const agriculture = {
	nepali: "कृषि   ",
	english: "",
};

export const ayurvedic = {
	nepali: "जडिबुटी   ",
	english: "",
};

export const industry = {
	nepali: "उधोग   ",
	english: "",
};
export const koseliGhar = {
	nepali: "विक्री केन्द्र वा कोसेली घर",
	english: "",
};
export const agricultureTitle = {
	nepali: "संकलन तथा प्रसोधन केन्द्र ",
	english: "",
};

export const millCount = {
	nepali: "पानी घट्ट संख्या  ",
	english: "",
};

export const millTitle = {
	nepali: "कुटानी, पिसानी र पेलानी (मिल) संख्या  ",
	english: "",
};

export const moistureCenter = {
	nepali: "चिस्यान केन्द्र",
	english: "",
};

export const family = {
	nepali: "परिवार ",
	english: "",
};
export const descriptions = [
	{
		nepali: "घरगोठ",
		english: "ghar",
		value: "ghar",
	},
	{
		nepali: "पशुपन्छी",
		english: "animal",
		value: "animal",
	},
	{
		nepali: "बाली र जग्गा जमिन",
		english: "crop",
		value: "crop",
	},
	{
		nepali: "अन्य क्षति",
		english: "others",
		value: "others",
	},
];
export const disasterTitle = {
	nepali: "क्षति संख्या",
	english: "",
};
export const disasterEffectTitle = {
	nepali: "विपदको क्षेत्र अनुसार क्षति विवरण",
	english: "",
};
export const amount = {
	nepali: "क्षति रकम  ",
	english: "",
};

export const citizenTitle = {
	nepali: "प्रकार  ",
	english: "",
};
export const citizenType = [
	{
		nepali: "टोल बिकास संस्था ",
		english: "tolBikas",
		value: "tolBikas",
	},
	{
		nepali: "नागरिक सचेतना केन्द्र  ",
		english: "nagarik",
		value: "nagarik",
	},
	{
		nepali: "नागरिक मंच   ",
		english: "manch",
		value: "manch",
	},
];

export const amountQn = {
	nepali: "रकम (रु.)",
	english: "",
};
export const disasterQn = {
	nepali: "क्षति विवरण  ",
	english: "",
};

export const disasterType = [
	{
		nepali: "बाढि डुवान",
		english: "Flood",
		value: "flood",
	},
	{
		nepali: "हावाहुरी",
		english: "Wind",
		value: "wind",
	},
	{
		nepali: "जंगली जनावरबाट क्षति",
		english: "Animal Terror",
		value: "animalTerror",
	},
	{
		nepali: "शीतलहर",
		english: "SheetalLahar",
		value: "sheetalalahar",
	},
	{
		nepali: "रोगको महामारी",
		english: "Disease",
		value: "disease",
	},
	{
		nepali: "आगलागि",
		english: "Fire",
		value: "fire",
	},
	{
		nepali: "सर्प दंश",
		english: "Snake Sting",
		value: "snakeSting",
	},
	{
		nepali: "असिना र हिमपात",
		english: "asina",
		value: "asina",
	},
	{
		nepali: "भूकम्प",
		english: "Earthquake",
		value: "earthquake",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];

export const productionNameQn = {
	nepali: "उधोगको नाम  ",
	english: "",
};
export const disasterEffectQn = {
	nepali: "क्षति विवरण  ",
	english: "",
};

export const productionName = [
	{
		nepali: "मझौला",
		english: "",
		value: "maijhaula",
	},
	{
		nepali: "ठुलो ",
		english: "thulo",
		value: "thulo",
	},
	{
		nepali: "मध्यम  ",
		english: "madhyam",
		value: "madhyam",
	},
	{
		nepali: "साना ",
		english: "sana",
		value: "sana",
	},
	{
		nepali: "घरेलु ",
		english: "household",
		value: "household",
	},
	{
		nepali: "लघु ",
		english: "laghu",
		value: "laghu",
	},
];

export const bed = {
	nepali: "वेड संख्या",
	english: "bed",
};

export const room = {
	nepali: "कोठा संख्या",
	english: "room",
};
export const everyQn = {
	nepali: "वार्षिक पर्यटक संख्या ",
	english: "everyQn",
};

export const areaQn = {
	nepali: "लगानीको मुख्य क्षेत्र ",
	english: "areaQn",
};
export const domestic = {
	nepali: "आन्तरिक ",
	english: "domestic",
};
export const foreigner = {
	nepali: "विदेशी   ",
	english: "foreigner",
};
export const leveltype = {
	nepali: "होटेलको स्तर    ",
	english: "leveltype",
};
export const capacityQn = {
	nepali: "क्षमता",
	english: "leveltype",
};
export const typeTitle = {
	nepali: "प्रकार",
	english: "typeTitle",
};

export const ownership = [
	{
		nepali: "सरकारी ",
		english: "government",
		value: "government",
	},
	{
		nepali: "पब्लिक  ",
		english: "public",
		value: "public",
	},
	{
		nepali: "निजी  ",
		english: "niji",
		value: "niji",
	},
	{
		nepali: "सहकारी     ",
		english: "sahakari",
		value: "sahakari",
	},
	{
		nepali: "सामुदायिक ",
		english: "samudayik",
		value: "samudayik",
	},
];
export const maleQn = {
	nepali: "प्रत्यक्ष रोजगारी जना ",
	english: "",
};
export const ownershipQn = {
	nepali: "उधोगको स्वामित्व  ",
	english: "",
};
export const ownershipTitle = {
	nepali: "स्वामित्व  ",
	english: "",
};

export const roadQn = {
	nepali: "सडकको पहुँच",
	english: "",
};

export const hotelCountQn = {
	nepali: "होटल, लाज, रेस्टुरा संख्या",
	english: "",
};

export const totalQn = {
	nepali: "वार्षिक पर्यटक आगमन संख्या  ",
	english: "",
};

export const meterQn = {
	nepali: "वार्षिक पर्यटक आगमन संख्या  ",
	english: "",
};

export const typeQn = {
	nepali: "उधोगको प्रकार   ",
	english: "",
};
export const productName = {
	nepali: "उत्पादन हुने वस्तु र सेवाको नाम    ",
	english: "",
};

export const farmType = [
	{
		nepali: "गाई",
		english: "cow",
		value: "cow",
	},
	{
		nepali: "भैसी",
		english: "buffalo",
		value: "buffalo",
	},
	{
		nepali: "बाख्रा",
		english: "bhakra",
		value: "bhakra",
	},
	{
		nepali: "बंगुर ",
		english: "pig",
		value: "pig",
	},
	{
		nepali: "कुखुरा",
		english: "chicken",
		value: "chicken",
	},
];

export const animalFarmProduction = [
	{
		nepali: "मासु",
		english: "Meat",
		value: "meat",
	},
	{
		nepali: "तरकारी",
		english: "Vegetable",
		value: "vegetable",
	},
	{
		nepali: "अन्डा",
		english: "Egg",
		value: "egg",
	},
	{
		nepali: "दुध",
		english: "Milk",
		value: "milk",
	},
	{
		nepali: "फलफुल",
		english: "Fruits",
		value: "fruits",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];

export const animalFarmType = [
	{
		nepali: "एकल",
		english: "Single",
		value: "single",
	},
	{
		nepali: "सझेदरी",
		english: "sajhedari",
		value: "sajhedari",
	},
	{
		nepali: "सहकारी",
		english: "sahakari",
		value: "sahakari",
	},
];

export const policeTitle = { nepali: "सुरक्षा निकाय प्रमुखको", english: "" };
export const contact = { nepali: "सम्पर्क नम्बर", english: "" };
export const stationType = { nepali: "सुरक्षा निकायको प्रकार", english: "" };
export const stationTypeList = [
	{
		nepali: "प्रहरी चौकी",
		english: "Police Station",
		value: "police_station",
	},
	{
		nepali: "ससत्र प्रहरी",
		english: "Armed Police",
		value: "armed_police",
	},
	{
		nepali: "सैनिक ब्यारेक",
		english: "Army Barrack",
		value: "army_barrack",
	},
	{
		nepali: "ट्राफिक बिट",
		english: "Traffic Beat",
		value: "traffic_beat",
	},
	{
		nepali: "सामुदायक प्रहरी",
		english: "Community Police",
		value: "community_police",
	},
];

export const clubType = [
	// {
	// 	nepali: 'फुटबल क्लब',
	// 	english: '',
	// 	value: 'footballClub',
	// },
	{
		nepali: "बाल समूह",
		english: "",
		value: "childClub",
	},
	{
		nepali: "युथ क्लब / युवा क्लब",
		english: "",
		value: "youthClub",
	},
	{
		nepali: "कृषक समूह",
		english: "",
		value: "farmerClub",
	},
	{
		nepali: "आमा समूह",
		english: "",
		value: "mothersClub",
	},
	// {
	// 	nepali: 'सामाजिक समूह',
	// 	english: '',
	// 	value: 'socialClub',
	// },
	// {
	// 	nepali: 'उधमी समूह',
	// 	english: '',
	// 	value: 'businessClub',
	// },
	// {
	// 	nepali: 'वचत समूह',
	// 	english: '',
	// 	value: 'savingClub',
	// },
	// {
	// 	nepali: 'व्यवसायिक कृषक समूह',
	// 	english: '',
	// 	value: 'farmerBusiness',
	// },
	// {
	// 	nepali: 'परम्परागत समूह',
	// 	english: '',
	// 	value: 'traditionalClub',
	// },
];
export const farmer = { nepali: "अव्यावसायिक /अगुवा कृषक ", english: "" };
export const farmerOrganization = {
	nepali: "सक्रिय ग्रामिण कृषि कार्यक्रता  (जे. टि. ए )",
	english: "",
};
export const animalOrganization = {
	nepali: "सक्रिय ग्रामिण पशु स्वास्थ्य कार्यकर्ता  ",
	english: "",
};
export const nurseryModerator = {
	nepali: "व्यावसायिक नर्सरी संचालक  ",
	english: "",
};

export const clubUpdateQn = { nepali: "अध्यावधिक गरिएको", english: "" };

export const clubMainWork = {
	nepali: "विगत एक वर्ष भित्र गरिएका  प्रमुख कार्यहरु",
	english: "",
};

export const clubTypeQn = { nepali: "समूहको प्रकार", english: "" };

export const clubHead = { nepali: "समूहको अध्यक्षको", enlgish: "" };

export const templeGroup = { nepali: "सञ्चालनको लागि गुठी", english: "" };

export const templeLegend = {
	nepali: " मठ/मन्दिर सम्बन्धि ऐतिहाँसिक किम्वदन्ती",
	english: "",
};

export const type = { nepali: "प्रकार", english: "" };
export const mgmtSystem = [
	{
		nepali: "घुम्ती",
		english: "Ghumti",
		value: "Ghumti",
	},
	{
		nepali: "चक्लाबन्दी",
		english: "ChaklaBandi",
		value: "ChaklaBandi",
	},
	{
		nepali: "सामान्य ",
		english: "simple",
		value: "simple",
	},
];

export const tourismType = [
	{
		nepali: "प्राकृतिक",
		english: "",
		value: "natural",
	},
	{
		nepali: "मानव निर्मित",
		english: "",
		value: "builtByPeople",
	},
];

export const mainBenefit = { nepali: "प्रमुख विशेषता", english: "" };

export const institutionType = [
	{
		nepali: "सरकारी",
		english: "",
		value: "government",
	},
	{
		nepali: "अर्ध सरकारी",
		english: "",
		value: "semiGovernment",
	},
	{
		nepali: "गैर सरकारी",
		english: "",
		value: "nonGovernment",
	},
	{
		nepali: "अन्तराष्ट्रिय गैर सरकारी",
		english: "",
		value: "internationalGov",
	},
	{
		nepali: "निजी",
		english: "",
		value: "private",
	},
];

export const workingField = { nepali: "काम गर्ने क्षेत्र", english: "" };

export const instituteService = { nepali: "उपलब्ध सेवाहरु", english: "" };

export const instituteHead = { nepali: "संस्था प्रमुखको ", english: "" };

export const bankType = [
	{
		nepali: "बाणिज्य",
		english: "Banijya",
		value: "banijya",
	},
	{
		nepali: "विकास",
		english: "Development",
		value: "development",
	},
	{
		nepali: "लघुवित्त संस्था",
		english: "Finance",
		value: "finance",
	},
	{
		nepali: "सहकारी संस्था",
		english: "Cooperative",
		value: "cooperative",
	},
];

export const industryOwnershipList = [
	{
		nepali: "सरकारी",
		english: "Government",
		value: "government",
	},
	{
		nepali: "पब्लिक",
		english: "Public",
		value: "public",
	},
	{
		nepali: "निजि",
		english: "Private",
		value: "private",
	},
	{
		nepali: "सहकारी",
		english: "Cooperative",
		value: "cooperative",
	},
];

export const industryTypeList = [
	{
		nepali: "लघु",
		english: "Laghu",
		value: "laghu",
	},
	{
		nepali: "घरेलु",
		english: "Gharelu",
		value: "gharelu",
	},
	{
		nepali: "साना",
		english: "Small",
		value: "Small",
	},
	{
		nepali: "मझौला",
		english: "medium",
		value: "medium",
	},
	{
		nepali: "ठुला",
		english: "large",
		value: "large",
	},
	{
		nepali: "अन्य",
		english: "other",
		value: "other",
	},
];

export const buildingType = [
	{
		nepali: "कच्ची",
		english: "",
		value: "kacchi",
	},
	{
		nepali: "पक्की",
		english: "",
		value: "pakki",
	},
];

export const buildingUseQn = { nepali: "प्रयोग", english: "" };

export const buildingUse = [
	{
		nepali: "भाडामा दिएको",
		english: "",
		value: "rent",
	},
	{
		nepali: "आफैंले प्रयोग गरेको",
		english: "",
		value: "self",
	},
];

export const buildingCondition = { nepali: "भवनको अवस्था", english: "" };
export const bhautik = { nepali: "भौतिक पूर्वाधार ", english: "" };
export const dis = { nepali: "नजिकको बजार केन्द्र सम्मको दूरी  ", english: "" };

export const roadType = { nepali: "सडकको प्रकार", english: "" };
export const roadLength = { nepali: "सडकको लम्बाई", english: "" };

export const kacchiKm = { nepali: "कच्ची किमि", english: "" };
export const gravelKm = { nepali: "ग्राभेल किमि", english: "" };
export const kaloPatraKm = { nepali: "कालो पत्रे किमि", english: "" };

export const wardRoad = { nepali: "सडकले जोडेको वार्डहरु", english: "" };

export const workingFrom = { nepali: "सञ्चालन हुने स्थान देखि", english: "" };
export const workingTo = { nepali: "सञ्चालन हुने स्थान सम्म", english: "" };
export const height = { nepali: "लम्बाई", english: "" };

export const capacity = { nepali: "क्षमता", english: "" };

export const workingBody = { nepali: "सहयोगी निकाय", english: "" };

export const establishDate = { nepali: "स्थापना मिति", english: "" };

export const injuredCount = {
	nepali: "घाईते हुनेको संख्या",
	english: "",
};

export const deathCount = {
	nepali: "मृत्युहुनेको संख्या",
	english: "",
};

export const wariWard = { nepali: "वारी वडा नं.", english: "" };
export const pariWard = { nepali: "पारी वडा नं.", english: "" };

export const nationWideElectricity = {
	nepali: "राष्ट्रिय प्रसारणमा जोडिएको",
	english: "",
};

export const electricityType = [
	{
		nepali: "जलविद्युत योजना",
		english: "",
		value: "planning",
	},
	{
		nepali: "पेल्ट्रिक सेट",
		english: "",
		value: "peltrickSet",
	},
	{
		nepali: "लघु जलविद्युत",
		english: "",
		value: "smallHydroPower",
	},
	{
		nepali: "सोलार प्लान्ट/वायु  ",
		english: "",
		value: "solarPlant",
	},
];

export const towerType = [
	{
		nepali: "एन.टि.सी.",
		english: "",
		value: "ntc",
	},
	{
		nepali: "एन.सेल",
		english: "",
		value: "ncell",
	},
	{
		nepali: "स्काई",
		english: "",
		value: "sky",
	},
	{
		nepali: "स्मार्ट सेल",
		english: "",
		value: "smartCell",
	},
	{
		nepali: "अन्य",
		english: "",
		value: "other",
	},
];

export const isDamageByTower = {
	nepali: "टावरको कारणले समुदायमा पुगेको क्षति",
	english: "",
};

export const isReconstruct = {
	nepali: "नियमित मर्मत सम्भारको व्यवस्था	",
	english: "",
};
export const isWater = { nepali: "आवश्यक पानीको व्यवस्था ", english: "" };
export const isHygiene = {
	nepali: "दैनिक सफा सुग्घर गर्ने व्यवस्था",
	english: "",
};
export const isToilet = { nepali: "सार्वजनिक शौंचालयको व्यवस्था", english: "" };
export const isWaterInPark = { nepali: "खानेपानीको  व्यवस्था", english: "" };

export const isStableNetwork = {
	nepali: "वाईफाईको सेवा सुविधा भरपर्दो",
	english: "",
};

export const isExcavation = { nepali: "खानी उत्खनन भएको", english: "" };

export const isPollution = { nepali: "खानी बाट वातावरण प्रदुषण", english: "" };

export const wasAccident = {
	nepali: "ट्रान्समिसन लाईनले दुर्र्घटना भएको",
	english: "",
};

export const addressFromTo = {
	nepali: "ट्रान्समिसन लाईन भएको क्षेत्र (ठेगाना)",
	english: "",
};

export const from = { nepali: "जोडिने वस्तीहरु देखि", english: "" };
export const to = { nepali: "जोडिने वस्तीहरु सम्म", english: "" };

export const isGivenToStay = { nepali: "सरकारी बासस्थान उपलब्ध", english: "" };

export const districtListening = {
	nepali: "एफ.एम. सुनिने जिल्लाहरु",
	english: "",
};
export const fmRange = { nepali: "रेन्ज", english: "" };
export const perPublication = { nepali: "प्रकाशन प्रति", english: "" };
export const officeLocation = { nepali: "कार्यालय रहेको स्थान", english: "" };
export const newsPaperType = [
	{
		nepali: "दैनिक",
		english: "",
		value: "daily",
	},
	{
		nepali: "साप्ताहिक",
		english: "",
		value: "weekly",
	},
	{
		nepali: "पाक्षिक",
		english: "",
		value: "fortnightly",
	},
	{
		nepali: "मासिक",
		english: "",
		value: "yearly",
	},
];

export const industryLocation = { nepali: "उद्योग रहेको स्थान", english: "" };
export const mainProduction = { nepali: "प्रमुख उत्पादन", english: "" };
export const mainSalePlace = { nepali: "प्रमुख विक्रि स्थल", english: "" };
export const industryType = { nepali: "उद्योगको प्रकार", english: "" };
export const industryOwnership = { nepali: "उद्योगको स्वामित्व", english: "" };
export const employmentProvide = {
	nepali: "उद्योगले दिएको रोजगारी संख्या",
	english: "",
};
export const employment = { nepali: "प्रत्यक्ष  रोजगारी ", english: "" };

export const marketTypeQn = { nepali: "हाटबजारको किसिम", english: "" };

export const marketOpenDayQn = { nepali: "हाटबजार लाग्ने दिन", english: "" };

export const marketOpenDay = [
	{
		nepali: "आइतबार ",
		english: "Sunday",
		value: "sunday",
	},
	{
		nepali: "सोमबार ",
		english: "Monday",
		value: "monday",
	},
	{
		nepali: "मंगलबार ",
		english: "Tuesday",
		value: "tuesday",
	},
	{
		nepali: "बुधबार",
		english: "Wednesday",
		value: "wednesday",
	},
	{
		nepali: "बिहिबार",
		english: "Thursday",
		value: "thursday",
	},
	{
		nepali: "शुक्रबार",
		english: "Friday",
		value: "friday",
	},
	{
		nepali: "शनिबार",
		english: "Saturday",
		value: "saturday",
	},
];

export const mainSaleProduct = {
	nepali: "कारोवार हुने प्रमुख वस्तुहरु",
	english: "",
};

export const providedSerivce = {
	nepali: "हाट बजारले सेवा दिएको क्षेत्र",
	english: "",
};

export const groundTypeQn = { nepali: "खेल मैदानको किसिम	", english: "" };
export const groundType = [
	{
		nepali: "भलिबल",
		english: "VolleyBall",
		value: "volleyball",
	},
	{
		nepali: "फुटबल",
		english: "FootBall",
		value: "football",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];
export const investment = {
	nepali: "लगानी रकम ",
	english: "",
};
export const annualBudget = {
	nepali: "वार्षिक बजेट  ",
	english: "",
};
export const mainfield = {
	nepali: "विषयगत क्षेत्र ",
	english: "",
};
export const worker = {
	nepali: "जनशक्ति संख्या ",
	english: "",
};
export const annualTransaction = {
	nepali: "वार्षिक कारोबार रकम  ",
	english: "",
};
export const mainArea = {
	nepali: "लगानीको मुख्य क्षेत्र  ",
	english: "",
};

export const riskArea = { nepali: "जोेखिम भएका क्षेत्रहरु", english: "" };

export const effectFromDisasterQn = {
	nepali: "सम्भावित जोखिमबाट भएका असरहरु (विगत ३ वर्षको)	",
	english: "",
};

export const level = [
	{
		nepali: "स्टार ",
		english: "star",
		value: "star",
	},
	{
		nepali: "नन स्टार  ",
		english: "nonStar",
		value: "nonStar",
	},
	{
		nepali: "अन्य  ",
		english: "others",
		value: "others",
	},
];
export const informations = [
	{
		nepali: "बिद्यालय ",
		english: "ghar",
		value: "ghar",
	},
	{
		nepali: "पुल पुलेसा ",
		english: "animal",
		value: "animal",
	},
	{
		nepali: "बाटोघाटो ",
		english: "crop",
		value: "crop",
	},
	{
		nepali: "कुलो नहर खानेपानी",
		english: "others",
		value: "others",
	},
	{
		nepali: "अन्य ",
		english: "others",
		value: "others",
	},
];

export const newEffectFromDistaters = [
	{
		nepali: "बाढि",
		english: "Flood",
		value: "flood",
	},
	{
		nepali: "पहिरो",
		english: "Landslide",
		value: "landslide",
	},
	{
		nepali: "शितलहर",
		english: "shitlahar",
		value: "shitlahar",
	},
	{
		nepali: "रोगमहामारी",
		english: "pandamic",
		value: "pandamic",
	},
	{
		nepali: "आगलागि",
		english: "Fire",
		value: "fire",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];

export const effectFromDisasters = [
	{
		nepali: "बाढि",
		english: "Flood",
		value: "flood",
	},
	{
		nepali: "पहिरो",
		english: "Landslide",
		value: "landslide",
	},
	{
		nepali: "जनावर आतंक",
		english: "Animal Terror",
		value: "animalTerror",
	},
	{
		nepali: "अती भिरालो",
		english: "downFall",
		value: "downFall",
	},
	{
		nepali: "दलदल",
		english: "quickSand",
		value: "quickSand",
	},
	{
		nepali: "आगलागि",
		english: "Fire",
		value: "fire",
	},
	{
		nepali: "नदी कटान",
		english: "river",
		value: "river",
	},
	{
		nepali: "चट्याङ",
		english: "Lightning",
		value: "lightning",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];

export const effectofDeath = {
	nepali: "मृत्यु",
	english: "Death",
	value: "death",
};
export const effectofInjured = {
	nepali: "घाइते",
	english: "Injured",
	value: "injured",
};

export const janjati = {
	nepali: "जनजाती",
	english: "janjati",
	value: "janjati",
};

export const descriptionTitle = {
	nepali: "समुहमा आबध परिवार तथा सदस्य विवरण ",
	english: "descriptionTitle",
	value: "descriptionTitle",
};
export const familyTitle = {
	nepali: "समेटेको परिबार ",
	english: "familyTitle",
	value: "familyTitle",
};
export const others = {
	nepali: "अन्य ",
	english: "others",
	value: "others",
};

export const possibleRisk = { nepali: "सम्भावित जोखिमहरु", english: "" };

export const festivalName = { nepali: "मेला, चाडपर्व तथा जात्रा", english: "" };

export const festivalMonthsQn = { nepali: "मनाउने महिना", english: "" };

export const celebrateReligion = { nepali: "मनाउने जातजाती", english: "" };

export const unit = { nepali: "इकाई", english: "unit" };
export const heightUnit = { nepali: "लम्बाईको इकाई", english: "unit" };

export const businessType = {
	nepali: " पसलको प्रकार  ",
	english: "business Type",
};
export const businessQn = {
	nepali: "व्यापार र व्यवसायको किसिम ",
	english: "business Qn",
};
export const businessName = [
	{
		nepali: "किराना वा व्यापरिक वस्तु  ",
		english: "kirana",
		value: "kirana",
	},
	{
		nepali: "मासु विक्री केन्द्र   ",
		english: "meat",
		value: "meat",
	},
	{
		nepali: "तरकारी तथा फलफूल विक्री केन्द्र",
		english: "tarkari",
		value: "tarkari",
	},
	{
		nepali: "दुध चिस्यान तथा विक्री केन्द्र",
		english: "milk",
		value: "milk",
	},
	{
		nepali: "निर्माण सामाग्री  ",
		english: "develop",
		value: "develop",
	},
	{
		nepali: "औषधी पसल वा क्लिनिक ",
		english: "medicine",
		value: "medicine",
	},
	{
		nepali: "मर्मत सेवा केन्द्र   ",
		english: "maintenance",
		value: "maintenance",
	},
	{
		nepali: "इलेक्ट्रिकल तथा इलेक्ट्रोनिक्स पसल     ",
		english: "electronics",
		value: "electronics",
	},
	{
		nepali: "होटेल तथा रेष्टुरेन्ट",
		english: "hotel",
		value: "hotel",
	},
	{
		nepali: "कपडा तथा फेन्सी",
		english: "fancy",
		value: "fancy",
	},
	{
		nepali: "ब्युटीपार्लर",
		english: "Beauty Parlor",
		value: "beauty_parlor",
	},
	{
		nepali: "विउविजन",
		english: "",
		value: "buevision",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];
export const partnershipQn = {
	nepali: "ब्यापार व्यवसायको स्वामित्व",
	english: "partner ",
};
export const partner = [
	{
		nepali: "सरकारी",
		english: "government",
		value: "government",
	},
	{
		nepali: "निजी",
		english: "personal",
		value: "personal",
	},
	{
		nepali: "पब्लिक लि. वा",
		english: "public",
		value: "public",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];

export const partnerTitle = {
	nepali: "स्वामित्व    ",
	english: "partnerTitle",
};

export const isRoadLengthList = [
	{
		nepali: "पुगेको",
		english: "yes",
		value: "yes",
	},
	{
		nepali: "नपुगेको",
		english: "no",
		value: "no",
	},
];

export const partnership = [
	{
		nepali: "एकल",
		english: "alone",
		value: "alone",
	},
	{
		nepali: "साझेदारी ",
		english: "partner",
		value: "partner",
	},
	{
		nepali: "सहकारी",
		english: "sahakari",
		value: "sahakari",
	},
];

export const companyName = {
	nepali: "व्यापार तथा व्यसाय संचालक तथा कम्पनीको नाम ",
	english: "",
};
// export const annualIncome = {
// 	nepali: "वार्षिक कारोवार रु. हजारमा",
// 	english: ""
// };

export const festivalAttribute = {
	nepali: "मेला, चाडपर्व तथा जात्राको विशेषता",
	english: "",
};

export const rate = {
	nepali: "परिमाण ",
	english: "rate",
};

export const festivalMonths = [
	{
		nepali: "बैशाख",
		english: "Baishakh",
		value: "baishakh",
	},
	{
		nepali: "जेष्ठ ",
		english: "Jestha",
		value: "jestha",
	},
	{
		nepali: "असार",
		english: "Asar",
		value: "asar",
	},
	{
		nepali: "श्रावण",
		english: "Srawan",
		value: "srawan",
	},
	{
		nepali: "भदौ",
		english: "Bhadau",
		value: "bhadau",
	},
	{
		nepali: "असोज",
		english: "Asoj",
		value: "asoj",
	},
	{
		nepali: "कार्तिक",
		english: "Kartik",
		value: "kartik",
	},
	{
		nepali: "मंसिर",
		english: "Mangsir",
		value: "mangsir",
	},
	{
		nepali: "पौष",
		english: "Poush",
		value: "poush",
	},
	{
		nepali: "माघ",
		english: "Magh",
		value: "magh",
	},
	{
		nepali: "फाल्गुन",
		english: "Falgun",
		value: "falgun",
	},
	{
		nepali: "चैत्र",
		english: "Chaitra",
		value: "chaitra",
	},
];

export const electionParty = { nepali: "निर्वाचित पार्टी", english: "" };

export const department = { nepali: "शाखा", english: "" };

export const remarks = { nepali: "कैफियत", english: "" };

export const patron = {
	nepali: "संरक्षक",
	english: "Patron",
};

export const helplessWomen = {
	nepali: "वेवारिसे तथा असहाय महिला",
	english: "Helpless and helpless women",
};

export const helplessChild = {
	nepali: "वेवारिसे तथा असहाय बालबालिका",
	english: "Helpless and helpless child",
};

export const helplessOld = {
	nepali: "वेवारिसे तथा असहाय जेष्ठ नागरिक",
	english: "Helpless and helpless child",
};

export const helplessDiable = {
	nepali: "वेवारिसे तथा असहाय अपाङ्गता भएका व्यक्ति",
	english: "Helpless and helpless disable",
};

export const beating = {
	nepali: "कुटपिट",
	english: "Beating",
};

export const mentalAbuse = {
	nepali: "मानसिक वा गालीगलौज",
	english: "Mental Abuse",
};

export const contempt = {
	nepali: "अपहेलना",
	english: "Contempt",
};

export const gharnikala = {
	nepali: "घरनिकाला",
	english: "Kicked from home",
};

export const divorce = {
	nepali: "सम्बन्ध बिच्छ्द",
	english: "Divorce",
};

export const sale = {
	nepali: "वेचविखन",
	english: "Sale",
};

export const roadList = [
	{
		nepali: "कच्ची ",
		english: "Kachi",
		value: "kachi",
	},
	{
		nepali: "ग्राभेल",
		english: "Gravel",
		value: "gravel",
	},
	{
		nepali: "कालो पत्रे",
		english: "Kalo Patre",
		value: "kaloPatre",
	},
];

export const benefittedPopulation = {
	nepali: "लाभान्वित जनसंख्या",
	english: "",
};

export const bridgeType = [
	{
		nepali: "झोलुंगे",
		english: "jholunge",
		value: "jholunge",
	},
	{
		nepali: "काठे",
		english: "kaathe",
		value: "kaathe",
	},
	{
		nepali: "पकी",
		english: "pakki",
		value: "pakki",
	},
	{
		nepali: "ट्रस्ट",
		english: "trust",
		value: "trust",
	},
];

export const disasterInstituteType = [
	{
		nepali: "पालिका",
		english: "palika",
		value: "palika",
	},
	{
		nepali: "नेपाल रेडक्रस सोसाइटी",
		english: "Nepal red cross society",
		value: "red_cross",
	},
	{
		nepali: "नेपाल प्रहरी",
		english: "Nepal Police",
		value: "nepal_police",
	},
];

export const memberMaleCount = {
	nepali: "आवद्ध सदस्य संख्या (पुरुष)",
	english: "",
};
export const memberFemaleCount = {
	nepali: "आवद्ध सदस्य संख्या (महिला)",
	english: "",
};

export const helperName = {
	nepali: "सहयोगी विकास साझेदारको नाम",
	english: "",
};

export const fishFarmOwnership = [
	{
		nepali: "निजि",
		english: "private",
		value: "private",
	},
	{
		nepali: "साजेदारी",
		english: "sajhedari",
		value: "sajhedari",
	},
	{
		nepali: "सरकारी",
		english: "government",
		value: "government",
	},
	{
		nepali: "सामुदायिक",
		english: "community",
		value: "community",
	},
];

export const riverName = {
	nepali: "पुल भएको खोला वा खोल्साको नाम",
	english: "",
};

export const statusBridge = {
	nepali: "अवस्था",
	english: "",
};
export const functions = { nepali: "प्रमुख विशेषता ", english: "" };
export const types = [
	{
		nepali: "मानव निर्मित ",
		english: "manMade",
		value: "manMade",
	},
	{
		nepali: "प्राकृतिक",
		english: "natural",
		value: "natural",
	},
];

export const manpowers = [
	{
		nepali: "कृषी र पशु",
		english: "Agriculture and Livestock",
		value: "agricultureLivestock",
	},
	{
		nepali: "इन्जिनियरिङ्ग",
		english: "Emgineering",
		value: "engineering",
	},
	{
		nepali: "वन",
		english: "Forest",
		value: "forest",
	},
	{
		nepali: "मेडिसिन चिकित्सा",
		english: "Pharmacy",
		value: "pharmacy",
	},
	{
		nepali: "खाध प्रशोधन",
		english: "Food Industry",
		value: "foodIndustry",
	},
	{
		nepali: "वकिल",
		english: "Lawyer",
		value: "lawyer",
	},
	{
		nepali: "पत्रकार",
		english: "Journalist",
		value: "journalist",
	},
	{
		nepali: "अन्य",
		english: "Others",
		value: "others",
	},
];

export const womenInstituteType = [
	{
		nepali: "आमा तथा महिला",
		english: "Mother and Women",
		value: "motherAndWomen",
	},
	{
		nepali: "परमपरागत समूह",
		english: "Traditional Group",
		value: "traditionalGroup",
	},
	{
		nepali: "कृषक तथा उधमी समूह",
		english: "Agriculture Group",
		value: "agricultureGroup",
	},
	{
		nepali: "बचत समूह",
		english: "Saving Group",
		value: "savingGroup",
	},
];

export const previousYearPregnant = {
	nepali: "गत बर्षको गर्भवती/प्रसूति भएका महिला संख्या",
	english: "",
};

export const ttFirstPregnant = {
	nepali: "टी.टी.१ (पहिलो जाँचमा)",
	english: "",
};

export const ttSecondPregnant = {
	nepali: "टी.टी.२ (पहिलो खोप लिएको एक महिना पछि)",
	english: "",
};

export const fourTestPregnant = {
	nepali: "गर्भावस्थाको ४ पटक जाँच गराउने गर्भवती संख्या",
	english: "",
};

export const maternityHealthPost = {
	nepali: "स्वास्थ्य संस्थामा",
	english: "",
};

export const maternityProfessional = {
	nepali: "दक्ष स्वास्थ्य कर्मीको सहयोगमा घरमा",
	english: "",
};

export const maternityWithoutProfessional = {
	nepali: "स्वास्थ्यकर्मीको अनुपस्थितिमा घरमा",
	english: "",
};

export const maternityDeathSixWeek = {
	nepali:
		"गर्भावस्था/प्रसूति अवस्था/सुत्केरी पछिको ६ हप्ताभित्र मृत्यु भएका महिला",
	english: "",
};

export const aliveChild = {
	nepali: "जीवित नवजात शिशु संख्या",
	english: "",
};

export const deathChild = {
	nepali: "मृत जन्म नवजात शिशु",
	english: "",
};

export const deathChildTwnetyNineDays = {
	nepali: "जन्मेको २८ दिनभित्र मृत्यु भएका नवजात शिशु",
	english: "",
};

export const femaleCountQs = {
	nepali: "१५–५९ बर्षका महिलाको संख्या",
	english: "",
};

// export const maternityWithoutProfessional = {
// 	nepali: "परिवार नियोजनको साधन प्रयोग गर्ने",
// 	english: ""
// };

export const permanent = {
	nepali: "स्थायी",
	english: "",
};

export const temporary = {
	nepali: "अस्थायी",
	english: "",
};

export const femaleHealthWorker = {
	nepali: "महिला स्वास्थ्य स्वयंसेविका संख्या",
	english: "",
};

export const nameQs = {
	nepali: "साबिक गबिस नगरपालिका वा वडा",
	english: "",
};

export const areaQs = {
	nepali: "वडको हालको क्षेत्रफल",
	english: "",
};

export const addressQs = {
	nepali: "हालवडा केन्द्र रहेको स्थान",
	english: "",
};

export const swamitwo = {
	nepali: "भवनको स्वमित्व",
	english: "",
};

// export default institute({
// 	industryOwnershipList,
// 	industryTypeList,
// 	department,
// 	remarks,
// 	electionParty,
// 	festivalName,
// 	festivalMonthsQn,
// 	celebrateReligion,
// 	festAttribute,
// 	festivalMonths,
// 	possibleRisk,
// 	riskArea,
// 	effectFromDisasterQn,
// 	effectFromDisasters,
// 	effectofDeath,
// 	effectofInjured,
// 	groundTypeQn,
// 	groundType,
// 	marketTypeQn,
// 	marketOpenDayQn,
// 	marketOpenDay,
// 	providedSerivce,
// 	mainSaleProduct,
// 	industryLocation,
// 	mainProduction,
// 	mainSalePlace,
// 	industryType,
// 	industryOwnership,
// 	employmentProvide,
// 	perPublication,
// 	officeLocation,
// 	newsPaperType,
// 	districtListening,
// 	fmRange,
// 	isGivenToStay,
// 	addressFromTo,
// 	from,
// 	to,
// 	wasAccident,
// 	isExcavation,
// 	isPollution,
// 	isStableNetwork,
// 	isToilet,
// 	isWaterInPark,
// 	isHygiene,
// 	isReconstruct,
// 	isWater,
// 	towerType,
// 	isDamageByTower,
// 	electricityType,
// 	nationWideElectricity,
// 	wariWard,
// 	pariWard,
// 	injuredCount,
// 	deathCount,
// 	establishDate,
// 	workingBody,
// 	workingFrom,
// 	workingTo,
// 	height,
// 	kacchiKm,
// 	gravelKm,
// 	kaloPatraKm,
// 	wardRoad,
// 	roadTypeLength,
// 	buildingType,
// 	buildingUseQn,
// 	buildingUse,
// 	buildingCondition,
// 	bankType,
// 	institutionType,
// 	workingField,
// 	instituteHead,
// 	instituteService,
// 	type,
// 	tourismType,
// 	mainBenefit,
// 	templeLegend,
// 	templeGroup,
// 	clubTypeQn,
// 	clubHead,
// 	clubMainWork,
// 	clubUpdateQn,
// 	clubType,
// 	stationType,
// 	stationTypeList,
// 	contact,
// 	policeTitle,
// 	farmTypeQn,
// 	fieldType,
// 	name,
// 	categoryTitle,
// 	category,
// 	establish,
// 	description,
// 	member,
// 	address,
// 	phone,
// 	clubActive,
// 	designation,
// 	count,
// 	latitude,
// 	longitude,
// 	wardQuestion,
// 	photo,
// 	specName,
// 	specValue,
// 	add,
// 	remove,
// 	darta,
// 	itemTitle,
// 	area,
// 	kachiCount,
// 	pakkiCount,
// 	kachiRoomCount,
// 	pakkiRoomCount,
// 	gharTitle,
// 	roomTitle,
// 	benchCapacity,
// 	staffTitle,
// 	ground,
// 	compound,
// 	childplayground,
// 	toilet,
// 	hastoilet,
// 	maleCount,
// 	femaleCount,
// 	staffCount,
// 	hasdrinking,
// 	appointAs,
// 	staff,
// 	student,
// 	schoolClass,
// 	malestudent,
// 	femalestudent,
// 	website,
// 	email,
// 	schooltype,
// 	schoollevel,
// 	hotelType,
// 	specTitle,
// 	position,
// 	emergencyNo,
// 	instituteType,
// 	healthInstituteType,
// 	buildingCount,
// 	consumedUser,
// 	vehicleTitle,
// 	memberTitle,
// 	instituteTitle,
// 	schoolLevel,
// 	categoryList,
// 	suffixCategoryList,
// 	fiscal,
// 	budget,
// 	dartachalani,
// 	effectedHouse,
// 	pdf,
// 	timespan,
// 	studentProd,
// 	staffProd,
// 	targetedCategory,
// 	helper,
// 	deleteMsg,
// 	agree,
// 	disagree,
// 	studentLevel,
// 	cberga,
// 	studentServiceName,
// 	childFriendlyDetail,
// 	admissionDetailName,
// 	admissionLevelName,
// 	schoolFacility,
// 	schoolFacilityTitle,
// 	admissionTitle,
// 	childFriendlyTitle,
// 	studentServiceTitle,
// 	schoolFacilityQn,
// 	classNineEducation,
// 	classNineEducationYes,
// 	furniture,
// 	facultyQn,
// 	collegelevel,
// 	collegeSubject,
// 	teacher,
// 	teacherMale,
// 	teacherFemale,
// 	healthInfo,
// 	healthTitle,
// 	healthFacility,
// 	healthFacilityTitle,
// 	healthLabel,
// 	shayaCountQn,
// 	vaccineTitle,
// 	vaccineQn,
// 	vaccineList,
// 	teacherTitleList,
// 	isLibrary,
// 	isCompoundWall,
// 	compoundWallType,
// 	staffTitleList,
// 	fiscalYear,
// 	vegetationAnimal,
// 	grazingTypeQn,
// 	grazingType,
// 	mainAnimalQn,
// 	forestInfoQn,
// 	forestInfoList,
// 	presidentName,
// 	presidentContact,
// 	dead,
// 	injured,
// 	mgmtSystem,
// 	incidentList,
// 	incidentTitle,
// 	populationMale,
// 	populationFemale,
// 	populationTitle,
// 	optionList,
// 	socialSecurityTitle,
// 	drinkingWaterName,
// 	establishedDate,
// 	helperInstitute,
// 	capacity,
// 	beneficialHousehold,
// 	issuedNumber,
// 	service,
// 	ownerName,
// 	gender,
// 	genderQn,
// 	age,
// 	gameName,
// 	sportsLevel,
// 	prize,
// 	annualIncome,
// 	annualProduction,
// 	pondArea,
// 	pondAddress,
// 	owner,
// 	midArea,
// 	mgmtType,
// 	festAttribute,
// 	farmType,
// 	industryName,
// 	workerCount,
// 	male,
// 	female
// });
