import { Grid, IconButton, Modal } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
	Button,
	Divider,
	Form,
	Icon,
	Input,
	message,
	Select,
	Typography,
	Upload,
	InputNumber,
} from "antd";
import Axios from "axios";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addInstitutionSurvey } from "../../../actions/instituteAction";
import isEmpty from "../../../utils/validation/is-empty";
import { selectedLanguage, ward } from "../../../variable/global";
import {
	add,
	bridgeType,
	riverName,
	from,
	to,
	heightUnit,
	type,
	statusBridge,
	address,
	count,
	establishDate,
	workingBody,
	height,
	capacity,
	name,
	photo,
	injuredCount,
	deathCount,
	wariWard,
	pariWard,
	wardQuestion,
	areaList,
} from "../../../variable/institute";
import { getBridge } from "./api";
import moment from "react-moment";

const { TextArea } = Input;
const { Option } = Select;

class Bridge extends Component {
	random = (low, high) => {
		return Math.random() * (high - low) + low;
	};
	state = {
		// image upload state
		uniqueIdentifier:
			Number(this.random(10000, 99999)).toFixed(0) +
			Number(this.random(1000, 9999)).toFixed(0),
		previewVisible: false,
		previewImage: "",
		fileList: {
			bridgeImage: [],
		},
		loaded: 0,
		// other
		photo: "",
		name: "",
		ward: "",
		riverName: "",
		from: "",
		to: "",
		type: "",
		status: "",
		wariWard: "",
		address: "",
		pariWard: "",
		establishedAt: "",
		bridgeHeightType: "",
		bridgeHeight: "",
		workingBody: "",
	};

	// image functions uplocad
	handleCancel = () => this.setState({ previewVisible: false });

	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	};

	// imageUpload
	handleChangeFile = (name, value, imageName) => {
		//name ma house and value ma list of filelist
		console.log("CF", value);
		this.setState(
			(prevState) => ({
				...prevState,
				fileList: {
					...prevState.fileList,
					[name]: {
						image: value.fileList,
						fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg",
					},
				},
			}),
			() => {
				if (value.fileList.length > 0) this.handleUpload(name, imageName);
				if (value.fileList.length === 0) this.removeUpload(name, imageName);
			}
		);
	};

	// upload image
	handleUpload = (name, imageName) => {
		const fileList = this.state.fileList[name];
		const data = new FormData();
		const imageObject = fileList.image[0].originFileObj;

		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
		data.append("file", imageObject, fileName);

		Axios.post("/api/imageUpload/upload", data, {
			onUploadProgress: (ProgressEvent) => {
				this.setState({
					loaded: Math.round(
						(ProgressEvent.loaded / ProgressEvent.total) * 100
					),
				});
			},
		})
			.then((res) => {
				// then print response status
				message.success("upload successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	// remove uploaded image
	removeUpload = (imageName) => {
		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

		Axios.get(`/api/imageUpload/delete/${fileName}`)
			.then((res) => {
				// then print response status
				message.success("deleted successfully.");
				console.log(res.statusText);
			})
			.catch((err) => message.error("upload failed."));
	};

	componentDidMount() {
		let bridgeId = this.props.match.params.id;
		if (bridgeId) {
			getBridge(bridgeId).then((res) =>
				this.setState({
					name: res.data.name,
					ward: res.data.ward,
					riverName: res.data.riverName,
					from: res.data.community.from,
					to: res.data.community.to,
					type: res.data.type,
					status: res.data.status,
					address: res.data.address,
					wariWard: res.data.wariWard,
					pariWard: res.data.pariWard,
					establishedAt:
						res.data.establishedAt &&
						moment(res.data.establishedAt).format("YYYY-MM-DD"),
					bridgeHeight: res.data.bridgeHeight,
					bridgeHeightType: res.data.bridgeHeightType,
					workingBody: res.data.workingBody,
					// image
					fileList: {
						bridgeImage: {
							fileName: res.data.uniqueIdentifier + "_bridge.jpg",
							image: [
								{
									uid: "-1",
									name: `${res.data.uniqueIdentifier}_bridge.jpg`,
									status: "done",
									url: `/api/imageUpload/image/${res.data.uniqueIdentifier}_bridge.jpg`,
								},
							],
						},
					},
				})
			);
		}
	}

	onSubmit = (event) => {
		event.preventDefault();
		const newInstitute = {
			uniqueIdentifier: this.state.uniqueIdentifier,
			name: this.state.name,
			ward: this.state.ward,
			address: this.state.address,
			wariWard: this.state.wariWard,
			pariWard: this.state.pariWard,
			establishedAt: this.state.establishedAt,
			bridgeHeight: this.state.bridgeHeight,
			bridgeHeightType: this.state.bridgeHeightType,
			workingBody: this.state.workingBody,
			from: this.state.from,
			to: this.state.to,
			type: this.state.type,
			status: this.state.status,
			riverName: this.state.riverName,
		};

		if (this.props.isUpdate) {
			newInstitute.id = this.props.match.params.id;
			this.props.addInstitutionSurvey(
				"bridge",
				newInstitute,
				this.props.history,
				this.props.match.params.category
			);
		} else
			this.props.addInstitutionSurvey(
				"bridge",
				newInstitute,
				this.props.history,
				this.props.match.params.category
			);
	};
	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};
	handleSelectChange = (name, value) => {
		console.log("handle ", name, value);
		this.setState({
			[name]: value,
		});
	};

	handleNumberChange = (value) => {
		this.setState({
			bridgeHeight: value,
		});
	};

	render() {
		const { classes, title } = this.props;
		const { previewVisible, previewImage } = this.state;
		const fileList = this.state.fileList;
		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		return (
			<Paper className={classes.root} elevation={1}>
				<Typography variant="h5">{title}</Typography>
				<Form onSubmit={this.onSubmit}>
					<Grid
						container
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						spacing={3}
					>
						<Grid item xs={6}>
							<Form.Item label={name[selectedLanguage]}>
								<Input
									onChange={this.handleChange("name")}
									value={this.state.name}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={wardQuestion[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={(val) => this.handleSelectChange("ward", val)}
									value={this.state.ward}
								>
									{ward.map((woda) => (
										<Option value={woda.value}>{woda.ward}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={riverName[selectedLanguage]}>
								<Input
									onChange={this.handleChange("riverName")}
									value={this.state.riverName}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={from[selectedLanguage]}>
								<Input
									onChange={this.handleChange("from")}
									value={this.state.from}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={to[selectedLanguage]}>
								<Input
									onChange={this.handleChange("to")}
									value={this.state.to}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={type[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={(val) => this.handleSelectChange("type", val)}
									value={this.state.type}
								>
									{bridgeType.map((type) => (
										<Option value={type.value}>{type.nepali}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={statusBridge[selectedLanguage]}>
								<Input
									onChange={this.handleChange("status")}
									value={this.state.status}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						{/* <Grid item xs={6}>
							<Form.Item label={address[selectedLanguage]}>
								<Input
									onChange={this.handleChange("address")}
									value={this.state.address}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid> */}

						{/* <Grid item xs={6}>
							<Form.Item label={wariWard[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									onChange={this.handleChange("wariWard")}
									value={this.state.wariWard}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={pariWard[selectedLanguage]}>
								<Input
									type="number"
									min={0}
									onChange={this.handleChange("pariWard")}
									value={this.state.pariWard}
								/>
							</Form.Item>
						</Grid> */}

						<Grid item xs={6}>
							<Form.Item label={establishDate[selectedLanguage]}>
								<Input
									type="date"
									onChange={this.handleChange("establishedAt")}
									value={this.state.establishedAt}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={photo[selectedLanguage]}>
								<Fragment>
									{!this.state.isView ? (
										<Upload
											name="file"
											// beforeUpload={this.props.beforeUpload}
											multiple={false}
											// action="/api/imageUpload/upload"
											accept="image/*"
											listType="picture-card"
											fileList={
												fileList.bridgeImage && fileList.bridgeImage.image
											}
											onPreview={this.handlePreview}
											onChange={(fileList) =>
												this.handleChangeFile("bridgeImage", fileList, "bridge")
											}
											disabled={this.state.isView}
										>
											{fileList &&
											fileList.bridgeImage &&
											fileList.bridgeImage.image &&
											fileList.bridgeImage.image.length === 1
												? null
												: uploadButton}
										</Upload>
									) : (
										<Modal
											visible={previewVisible}
											footer={null}
											onCancel={this.handleCancel}
										>
											<img
												alt="example"
												style={{ width: "100%" }}
												src={previewImage}
											/>
										</Modal>
									)}
								</Fragment>
							</Form.Item>
						</Grid>

						<Grid item xs={4}>
							<Form.Item label={height[selectedLanguage]}>
								<InputNumber
									min={0}
									onChange={this.handleNumberChange}
									value={this.state.bridgeHeight}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={2}>
							<Form.Item label={heightUnit[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={(val) =>
										this.handleSelectChange("bridgeHeightType", val)
									}
									value={this.state.bridgeHeightType}
								>
									{areaList.map((type) => (
										<Option value={type.value}>{type[selectedLanguage]}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>

						{/* <Grid item xs={6}>
							<Form.Item label={workingBody[selectedLanguage]}>
								<Input
									onChange={this.handleChange("workingBody")}
									value={this.state.workingBody}
								/>
							</Form.Item>
						</Grid> */}

						<Grid item xs={12}>
							<Button
								type="primary"
								htmlType="submit"
								disabled={this.state.isView}
							>
								{add[selectedLanguage]}
							</Button>
						</Grid>
					</Grid>
				</Form>
			</Paper>
		);
	}
}

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
});

const mapStateToProps = (state) => ({});

export default withStyles(styles)(
	connect(mapStateToProps, { addInstitutionSurvey })(withRouter(Bridge))
);
