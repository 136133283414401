import Club from "./Club";
import Institute from "./Institute";
import PalikaOffice from "./PalikaOffice";
import School from "./School";
import TourismPlace from "./TourismPlace";
import ProdEducation from "./ProdEducation";
import College from "./College";
import Health from "./Health";
import Jungle from "./Jungle";
import Ayurvedic from "./Ayurvedic";
import CitizenAwareness from "./CitizenAwareness";
import FarmerSaving from "./FarmerSaving";
import CommunityOrganization from "./CommunityOrganization";
import SocialResources from "./SocialResources";
import EconomicalResources from "./EconomicalResources";
import WasteMgmt from "./WasteMgmt";
import DisasterOrganization from "./DisasterOrganization";
import DisasterInfrastructure from "./DisasterInfrastructure";
import DisasterWaterResource from "./DisasterWaterResource";
import PhysicalResource from "./PhysicalResource";
import SkilledManpower from "./SkilledManpower";
import WomenSaving from "./WomenSaving";
import PersonalIncident from "./PersonalIncident";
import CooperativeInstitute from "./CooperativeInstitute";
import Worker from "./Worker";
import BusRoute from "./BusRoute";
import BusStation from "./BusStation";
import BusinessInstitute from "./BusinessInstitute";
import Irrigation from "./Irrigation";
import FarmHr from "./FarmHr";
import Mill from "./Mill";
import ProductionTourism from "./ProductionTourism";
import AgricultureMarket from "./AgricultureMarket";
import Sabdaha from "./Sabdaha";
import DisasterEffect from "./DisasterEffect";

import DrinkingWater from "./DrinkingWater";
import FishFarm from "./FishFarm";
import Medical from "./Medical";
import SocialSecurity from "./SocialSecurity";
import HealthPost from "./HealthPost";
import GrazingField from "./GrazingField";
import Forest from "./Forest";
import Nikunja from "./Nikunja";
import AnimalFarm from "./AnimalFarm";
import Animal from "./Animal";
import Police from "./Police";
import Temple from "./Temple";
import Bank from "./Bank";
import CommunityBuilding from "./CommunityBuilding";
import Jadibuti from "./Jadibuti";
import RoadNetwork from "./RoadNetwork";
import CableCar from "./CableCar";
import Park from "./Park";
import Tuin from "./Tuin";
import Bridge from "./Bridge";
import MainRiver from "./MainRiver";
import Electricity from "./Electricity";
import Tower from "./Tower";
import PublicToilet from "./PublicToilet";
import FreeWifi from "./FreeWifi";
import Mines from "./Mines";
import TransmissionLine from "./TransmissionLine";
import Quarter from "./Quarter";
import FmStation from "./FmStation";
import Newspaper from "./Newspaper";
import CableNetwork from "./CableNetwork";
import BusTerminal from "./BusTerminal";
import ServiceCenter from "./ServiceCenter";
import Industry from "./Industry";
import MarketPlace from "./MarketPlace";
import Ground from "./Ground";
import Sports from "./Sports";
import NaturalDisaster from "./NaturalDisaster";
import DisasterInformation from "./DisasterInformation";
import Hotels from "./Hotels";
import Festival from "./Festival";
import VillageCouncilFamily from "./VillageCouncilFamily";
import VillageCouncilStaff from "./VillageCouncilStaff";
import TechnicalEducation from "./TechnicalEducation";
import GoatFarm from "./GoatFarm";
import CowFarm from "./CowFarm";
import ChildHome from "./ChildHome";
import OldAgeHome from "./OldAgeHome";
import RehabCenter from "./RehabCenter";
import Helpless from "./Helpless";
import GenderCrime from "./GenderCrime";
import Pavement from "./Pavement";
import SafeMaternity from "./SafeMaternity";
import InsidePalikaDisease from "./InsidePalikaDisease";
import FemaleChildHealth from "./FemaleChildHealth";
import WardDetail from "./WardDetail";

import Business from "@material-ui/icons/Business";
import Group from "@material-ui/icons/Group";
import SchoolIcon from "@material-ui/icons/School";
import Place from "@material-ui/icons/Place";
import Star from "@material-ui/icons/Star";

// import Item from "./Item";
// import Project from "./Project";

export const ngo = {
	nepali: "गैर सरकारी संस्था सम्बन्धी विवरण",
	english: "",
	route: "institution",
	Component: Institute,
	count: 2,
};
export const police = {
	nepali: "सुरक्षा निकाय विवरण",
	english: "",
	route: "police",
	count: 2,
	Component: Police,
};
export const palikaOffice = {
	nepali: "गाउँपालिका/नगरपालिका,संघीय तथा प्रदेश कार्यालय तथा निकायहरु",
	english: "",
	route: "palikaOffice",
	Component: PalikaOffice,
	count: 2,
};
export const bank = {
	nepali: "वैंक,वित्तिय संस्था तथा लघु वित्त सम्बन्धी विवरण",
	english: "",
	route: "bank",
	count: 2,
	Component: Bank,
};
export const jungle = {
	nepali: "वनको विवरण ",
	english: "",
	route: "jungle",
	count: 2,
	Component: Jungle,
};
export const cooperativeInstitute = {
	nepali: "सहकारी संस्था सम्बन्धी विवरण  ",
	english: "",
	route: "cooperativeInstitute",
	count: 2,
	Component: CooperativeInstitute,
};

export const citizenAwareness = {
	nepali: "नागरिक सचेतना केन्द्र र टोल विकास संस्थाको विवरण ",
	english: "",
	route: "citizenAware",
	count: 2,
	Component: CitizenAwareness,
};
export const farmerSaving = {
	nepali: "कृषक तथा उधमी तथा बचत समूहको विवरण ",
	english: "",
	route: "farmerSaving",
	count: 2,
	Component: FarmerSaving,
};
export const socialResources = {
	nepali: "(ग) सामाजिक श्रोत तथा सामाग्रीको व्यवस्था",
	english: "",
	route: "socialResources",
	count: 2,
	Component: SocialResources,
};
export const economicalResources = {
	nepali: "(घ) आर्थिक श्रोत सम्बन्धी",
	english: "",
	route: "economicalResources",
	count: 2,
	Component: EconomicalResources,
};

export const wasteMgmt = {
	nepali: "फोहरमैला व्यवस्थापन सम्बन्धी   ",
	english: "",
	route: "waste",
	count: 2,
	Component: WasteMgmt,
};
export const disasterOrganization = {
	nepali: "जोखिम पहिचान तथा क्षमता विश्लेषण (क)मानविय श्रोत",
	english: "",
	route: "disasterorg",
	count: 2,
	Component: DisasterOrganization,
};
export const disasterInfrastructure = {
	nepali: "विपदबाट क्षति सार्वजनिक संरचना तथा पूर्वाधार",
	english: "",
	route: "disasterInfrastructure",
	count: 2,
	Component: DisasterInfrastructure,
};
export const disasterEffect = {
	nepali: "विपद असर विवरण  ",
	english: "",
	route: "disasterEffect",
	count: 2,
	Component: DisasterEffect,
};
export const disasterWaterResource = {
	nepali: "संरक्षित पानिमुहान , जमिन र भौतिक संरचना",
	english: "",
	route: "disasterWater",
	count: 2,
	Component: DisasterWaterResource,
};
export const physicalresource = {
	nepali: "(ख) भौतिक श्रोत सम्बन्धी विवरण",
	english: "",
	route: "physicalresource",
	count: 2,
	Component: PhysicalResource,
};
export const communityOrg = {
	nepali: "सामुदायिक संस्था विवरण ",
	english: "",
	route: "communityOrg",
	count: 2,
	Component: CommunityOrganization,
};
export const ayurvedic = {
	nepali: "जडिबुटीको विवरण  ",
	english: "",
	route: "ayurvedic",
	count: 2,
	Component: Ayurvedic,
};
export const sports = {
	nepali: "व्यावसायिक खेलाडीको विवरण",
	english: "",
	route: "sports",
	count: 2,
	Component: Sports,
};
export const communityHall = {
	nepali: "सार्वाजनिक तथा सामुदायिक भवन",
	english: "",
	route: "communityBuilding",
	count: 2,
	Component: CommunityBuilding,
};
export const quarter = {
	nepali: "सरकारी भवन",
	english: "",
	route: "quarter",
	count: 2,
	Component: Quarter,
};
export const club = {
	nepali: "बाल, युवा, अपाङ्गता संजाल/क्लब सम्बधी विवरण",
	english: "",
	route: "club",
	count: 2,
	Component: Club,
};

export const school = {
	nepali: "स्कुल",
	english: "",
	route: "school",
	count: 2,
	Component: School,
};
export const college = {
	nepali: "कलेज",
	english: "",
	route: "college",
	count: 2,
	Component: College,
};
export const technicalEducation = {
	nepali: "प्राविधिक शिक्षा",
	english: "",
	route: "technicalEducation",
	count: 2,
	Component: TechnicalEducation,
};
export const medical = {
	nepali: "मेडिकल सम्बन्धि",
	english: "",
	route: "medical",
	count: 2,
	Component: Medical,
};
export const insidePalikaDisease = {
	nepali: "पालिका भित्र देखा परेका प्रमुख रोग र तिनको उपचार सम्बन्धी विवरण",
	english: "",
	route: "insidePalikaDisease",
	count: 2,
	Component: InsidePalikaDisease,
};
export const farmHr = {
	nepali: "२.५ कृषि तथा पशुसेवासँग सम्बन्धित मानव संसाधन (संख्या)",
	english: "",
	route: "farmHr",
	count: 2,
	Component: FarmHr,
};

export const mill = {
	nepali: "२.६ घट्ट, मिल तथा संकलन तथा प्रसोधन सम्बन्धी विवरण",
	english: "",
	route: "mill",
	count: 2,
	Component: Mill,
};

export const productionTourism = {
	nepali: "२.७ उत्पादन तथा सेवामूलक उधोग सम्बन्धी विवरण",
	english: "",
	route: "productionTourism",
	count: 2,
	Component: ProductionTourism,
};
export const agricultureMarket = {
	nepali: "२.११ कृषि बजार तथा हाट बजार सम्बन्धी विवरण",
	english: "",
	route: "agricultureMarket",
	count: 2,
	Component: AgricultureMarket,
};
export const sabdaha = {
	nepali: "शवदाह स्थल सम्बन्धी विवरण",
	english: "",
	route: "sabdaha",
	count: 2,
	Component: Sabdaha,
};
// export const disasterEffect = {
//      nepali: 'विपदको क्षेत्र अनुसार क्षति विवरण ',
//      english: '',
//      route: 'disasterEffect',
//      count: 2,
//      Component: DisasterEffect,
// };

export const irrigation = {
	nepali: "२.३ सिचाई सुबिधाको उपलब्धता",
	english: "",
	route: "irrigation",
	count: 2,
	Component: Irrigation,
};
export const worker = {
	nepali: "२.७.१ इँटा भट्टा वा ठुला उधोगमा कार्यरहत मजदुरहरुको विवरण",
	english: "",
	route: "worker",
	count: 2,
	Component: Worker,
};
export const busRoute = {
	nepali: "रुट परमिट लिएर संचालन भएका सवारी साधन",
	english: "",
	route: "busRoute",
	count: 2,
	Component: BusRoute,
};
export const busStation = {
	nepali: "वसपार्क तथा बस विसौनी सम्बन्धी विवरण",
	english: "",
	route: "busStation",
	count: 2,
	Component: BusStation,
};
export const goatFarm = {
	nepali: "२.४.२ खसी वा बाख्रा फर्म",
	english: "",
	route: "goatFarm",
	count: 2,
	Component: GoatFarm,
};

export const cowFarm = {
	nepali: "२.४.३ गाई /भैसी फर्म   ",
	english: "",
	route: "cowFarm",
	count: 2,
	Component: CowFarm,
};
export const businessInstitute = {
	nepali: "२.८ व्यापार तथा व्यावसाय सम्बन्धी विवरण",
	english: "",
	route: "businessInstitute",
	count: 2,
	Component: BusinessInstitute,
};

export const personalIncident = {
	nepali: "१.२ व्यक्तिगत घटना विवरण",
	english: "",
	route: "personalIncident",
	count: 2,
	Component: PersonalIncident,
};
export const drinkingWater = {
	nepali: "खानेपानी टयंकी सम्नन्धि",
	english: "",
	route: "drinkingWater",
	count: 2,
	Component: DrinkingWater,
};
export const socialSecurity = {
	nepali: "१.३ सामाजिक सुरक्षा कार्याक्रम विवरण",
	english: "",
	route: "socialSecurity",
	count: 2,
	Component: SocialSecurity,
};
export const fishFarm = {
	nepali: "२.२ सार्वजनिक पोखरी तथा माछापालन",
	english: "",
	route: "fishFarm",
	count: 2,
	Component: FishFarm,
};

// export const trainningInstitute = {
//      nepali: "तालिम केन्द्र",
// english: "",
//      route: "trainningInstitute",
//      count: 2,
//      Component: TrainningInstitute
// };

// export const prodEducation = {
//      nepali: "प्रोड्शिक्षा",
// english: "",
//      route: "prodEducation",
//      count: 2,
//      Component: ProdEducation
// };

export const touristPlace = {
	nepali: "२.९ धार्मिक, एतिहासिक तथा पर्यटकीय स्थल सम्बन्धी विवरण",
	english: "",
	route: "tourismPlace",
	count: 2,
	Component: TourismPlace,
};

export const park = {
	nepali: "पार्क",
	english: "",
	route: "park",
	count: 2,
	Component: Park,
};

export const hotel = {
	nepali: "२.१० होटेल, रिसोर्ट , रेस्टुरेन्ट र होमस्टे सम्बन्धी विवरण",
	english: "",
	route: "hotel",
	count: 2,
	Component: Hotels,
};

export const mine = {
	nepali: "खानी",
	english: "",
	route: "mine",
	count: 2,
	Component: Mines,
};
export const forest = {
	nepali: "सामुदायिक वन",
	english: "",
	route: "forest",
	count: 2,
	Component: Forest,
};
export const nikunja = {
	nepali: "राष्ट्रिय निकुन्ज, आरक्ष र मध्यवर्ती तथा संरक्षित क्षेत्र",
	english: "",
	route: "nikunja",
	count: 2,
	Component: Nikunja,
};
export const bridge = {
	nepali: "पुल तथा पुलेसा",
	english: "",
	route: "bridge",
	count: 2,
	Component: Bridge,
};
export const ground = {
	nepali: "खेलमैदान,पार्क,पिकनिक स्थल तथा मनोरञ्जन स्थल सम्बन्धी विवरण",
	english: "",
	route: "ground",
	count: 2,
	Component: Ground,
};
export const market = {
	nepali: "हाट बजार मार्केट",
	english: "",
	route: "market",
	count: 2,
	Component: MarketPlace,
};
export const flora = {
	nepali: "जडीबुटी तथा वनस्पती",
	english: "",
	route: "herbs",
	count: 2,
	Component: Jadibuti,
};
export const cableCar = {
	nepali: "केबलकार",
	english: "",
	route: "cableCar",
	count: 2,
	Component: CableCar,
};
export const tuin = {
	nepali: "तुइन भएको खोला/नदी",
	english: "",
	route: "tuin",
	count: 2,
	Component: Tuin,
};
export const mainRiver = {
	nepali: "प्रमुख खोला/नदी",
	english: "",
	route: "mainRiver",
	count: 2,
	Component: MainRiver,
};

export const communication = {
	nepali: "संचार टावर",
	english: "",
	route: "tower",
	count: 2,
	Component: Tower,
};

export const fm = {
	nepali: "एफ एम / T. V सम्बन्धी विवरण",
	english: "",
	route: "fmStation",
	count: 2,
	Component: FmStation,
};

export const newspaper = {
	nepali: "पत्रपत्रिका सम्बन्धी विवरण",
	english: "",
	route: "newspaper",
	count: 2,
	Component: Newspaper,
};
export const cableNetwork = {
	nepali: "केवल नेटवर्क",
	english: "",
	route: "cableNetwork",
	count: 2,
	Component: CableNetwork,
};
export const temple = {
	nepali:
		"२.९.१ पालिकामा अवस्थित मठ, मन्दिर, गुम्बा,चर्च, मस्जीद सम्बन्धी विवरण",
	english: "",
	route: "temple",
	count: 2,
	Component: Temple,
};
export const mainBusiness = {
	nepali: "उद्योग तथा कलकारखाना",
	english: "",
	route: "mainBusiness",
	count: 2,
	Component: Industry,
};
export const festival = {
	nepali: "मुख्य चाड, पर्व तथा मेला जात्रा सम्बन्धी विवरण",
	english: "",
	route: "festival",
	count: 2,
	Component: Festival,
};

export const busPark = {
	nepali: "मुख्य बस टर्मिनल",
	english: "",
	route: "busPark",
	count: 2,
	Component: BusTerminal,
};
export const publicToilet = {
	nepali: "सार्वजनिक शौचालय",
	english: "",
	route: "publicToilet",
	count: 2,
	Component: PublicToilet,
};
export const roadNetwork = {
	nepali: "सडक मार्ग",
	english: "",
	route: "roadNetwork",
	count: 2,
	Component: RoadNetwork,
};
export const freeWifi = {
	nepali: "सार्वजनिक वाइफाई भएको स्थान",
	english: "",
	route: "freeWifi",
	count: 2,
	Component: FreeWifi,
};

export const hospital = {
	nepali: "अस्पताल",
	english: "",
	route: "hospital",
	count: 2,
	Component: Health,
};
export const healthPost = {
	nepali: "स्वास्थ्य चौकी",
	english: "",
	route: "healthPost",
	count: 2,
	Component: HealthPost,
};
// export const animalVeterinary = {
//      nepali: "पशु (भेटेनरी)/उपचार केन्द्र",
//      english: "",
//      route: "animalVeterinary",
//      count: 2,
//      Component: Health
// };
export const disasterInformation = {
	nepali: "जोखिमयुक्त (संवेदनशील) स्थानमा वसोवास गरेका परिवार विवरण",
	english: "",
	route: "disasterInformation",
	count: 2,
	Component: DisasterInformation,
};
export const naturalDisaster = {
	nepali: "विपदबाट मानवीय क्षती (विगत ५ वर्षको )",
	english: "",
	route: "naturalDisaster",
	count: 2,
	Component: NaturalDisaster,
};
export const animal = {
	nepali: "जनावर तथा चराचुरुङ्गीहरुको बिबरण",
	english: "",
	route: "animal",
	count: 2,
	Component: Animal,
};
export const grazingField = {
	nepali: "चरन क्षेत्र",
	english: "",
	route: "grazingField",
	count: 2,
	Component: GrazingField,
};
export const animalFarm = {
	nepali: "२.४.१ कृषि तथा पशु फार्म सम्बन्धी विवरण",
	english: "",
	route: "animalFarm",
	count: 2,
	Component: AnimalFarm,
};
export const serviceCenter = {
	nepali: "मुख्य मेकानिकल वर्कसप",
	english: "",
	route: "serviceCenter",
	count: 2,
	Component: ServiceCenter,
};
export const villageCouncilFamily = {
	nepali: "वडागत निर्वाचित पदाधिकारीहरुको नामावली विवरणमा ",
	english: "",
	route: "rmFamily",
	count: 2,
	Component: VillageCouncilFamily,
};
export const villageCounilStaff = {
	nepali: "वडा कर्मचारी विवरण",
	english: "",
	route: "rmStaff",
	count: 2,
	Component: VillageCouncilStaff,
};
export const powerHouse = {
	nepali: "विधुत आयोजना सम्बन्धी विवरण",
	english: "",
	route: "electricity",
	count: 2,
	Component: Electricity,
};
export const transmissionLine = {
	nepali: "विद्युतीय ट्रान्समिसन लाईन",
	english: "",
	route: "transmissionLine",
	count: 2,
	Component: TransmissionLine,
};
export const childHome = {
	nepali: "बालगृह सम्बन्धी विवरण",
	english: "",
	route: "childHome",
	count: 2,
	Component: ChildHome,
};
export const oldAgeHome = {
	nepali: "संचालित वृद्धाश्रम सम्बन्धी विवरण",
	english: "",
	route: "oldAgeHome",
	count: 2,
	Component: OldAgeHome,
};
export const rehabCenter = {
	nepali: "संचालित पुन:संस्थापना केन्द्रको विवरण",
	english: "",
	route: "rehabCenter",
	count: 2,
	Component: RehabCenter,
};
export const helpless = {
	nepali: "वेवारिसे तथा असहाय नागरिकको विवरण",
	english: "",
	route: "helpless",
	count: 2,
	Component: Helpless,
};
export const genderCrime = {
	nepali: "लैंगिक हिँसा सम्बन्धी विवरण",
	english: "",
	route: "genderCrime",
	count: 2,
	Component: GenderCrime,
};
export const pavement = {
	nepali: "पैदल मार्ग विवरण",
	english: "",
	route: "pavement",
	count: 2,
	Component: Pavement,
};
export const skilledManpower = {
	nepali: "२.१ प्राविधिक, सीपयुक्त तथा विशेष दक्षता भएका मानव संसाधनको विवरण",
	english: "",
	route: "skilledManpower",
	count: 2,
	Component: SkilledManpower,
};

export const womenSaving = {
	nepali: "आमा, महिला समूह तथा परम्परागत समूहको विवरण",
	english: "",
	route: "womenSaving",
	count: 2,
	Component: WomenSaving,
};

export const safeMaternity = {
	nepali: "खोप सेवा तथा सुरक्षित मातृत्व सम्बन्धी विवरण",
	english: "",
	route: "safeMaternity",
	count: 2,
	Component: SafeMaternity,
};

export const femaleChildHealth = {
	nepali: "सुरक्षित मातृत्व सम्बन्धी विवरण",
	english: "",
	route: "femaleChildHealth",
	count: 2,
	Component: FemaleChildHealth,
};

export const wardDetail = {
	nepali: "पुनसंरचित गाउँ/नगर पालिकाको वडागत विवरण",
	english: "",
	route: "wardDetail",
	count: 2,
	Component: WardDetail,
};

// can rearrange list from here
export const instituteRoute = [
	{
		title: {
			english: "1. Population Detail ",
			nepali: "खण्ड क - जनसंख्या विवरण  ",
		},
		child: [personalIncident, socialSecurity],
		Icon: Star,
	},
	{
		title: {
			english: "21. Economic Development",
			nepali: "खण्ड ख - आर्थिक विकास स्थिति विवरण",
		},
		child: [
			skilledManpower,
			fishFarm,
			irrigation,
			animalFarm,
			goatFarm,
			cowFarm,
			farmHr,
			mill,
			productionTourism,
			worker,
			businessInstitute,
			touristPlace,
			temple,
			hotel,
			agricultureMarket,
		],
		Icon: Star,
	},
	{
		title: { english: "1. Education", nepali: "खण्ड ग : सामाजिक विकास" },
		child: [
			school,
			college,
			technicalEducation,
			hospital,
			healthPost,
			medical,
			insidePalikaDisease,
			safeMaternity,
			femaleChildHealth,
			ground,
			sports,
			festival,
			childHome,
			oldAgeHome,
			rehabCenter,
			helpless,
			genderCrime,
		],
		Icon: SchoolIcon,
	},
	// {
	//      title: { english: "2. Health Related", nepali: "स्वास्थ्य संस्था विवरण" },
	//      // child: [hospital, healthPost, animalVeterinary],
	//      child: [],
	//      Icon: Star,
	// },
	{
		title: { nepali: "खण्ड घ : भौतिक पूर्वाधार स्थिति विवरण", english: "" },
		child: [
			roadNetwork,
			pavement,
			bridge,
			quarter,
			communityHall,
			publicToilet,
			freeWifi,
			powerHouse,
			fm,
			newspaper,
			sabdaha,
			busRoute,
			busStation,
		],
	},
	{
		title: { nepali: "खण्ड ङ : वन, जैविक विविधता तथा वातावरण", english: "" },
		child: [
			jungle,
			ayurvedic,
			nikunja,
			grazingField,
			disasterInformation,
			naturalDisaster,
			disasterEffect,
			disasterOrganization,
			physicalresource,
			socialResources,
			economicalResources,
			disasterInfrastructure,
			disasterWaterResource,
			wasteMgmt,
		],
	},
	{
		title: { nepali: "खण्ड च: संस्थागत विकास विवरण", english: "" },
		child: [
			palikaOffice,
			communityOrg,
			womenSaving,
			club,
			citizenAwareness,
			farmerSaving,
			wardDetail,
			bank,
			cooperativeInstitute,
			ngo,
			villageCouncilFamily,
			villageCounilStaff,
		],
	},
	// {
	//      title: {
	//              english: "3. Forest and animal area",
	//              nepali: "३. वन तथा बन्यजन्तु आरक्षण क्षेत्र",
	//      },
	//      child: [nikunja, forest, animal, grazingField, flora, jungle, ayurvedic],
	// },
	// {
	//      title: { english: "5. Policemen", nepali: "५. सुरक्षा निकाय सम्बन्धि" },
	//      child: [police],
	// },
	// {
	//      title: { english: "6. Club", nepali: "६. क्लब तथा सामुहिक विवरण" },
	//      child: [club],
	//      Icon: Group,
	// },
	// {
	//      title: {
	//              english: "7. Temple",
	//              nepali: "७. धार्मिक स्थल तथा चार्डपर्व सम्बन्धि",
	//      },
	//      child: [festival],
	//      Icon: Group,
	// },
	// {
	//      title: {
	//              english: "9. Institute",
	//              nepali: "९. संस्थागत विवरण",
	//      },
	//      child: [
	//              ngo,
	//              citizenAwareness,
	//              farmerSaving,
	//              communityOrg,
	//              cooperativeInstitute,
	//              palikaOffice,
	//              womenSaving,
	//      ],
	//      Icon: Business,
	// },
	// {
	//      title: {
	//              english: "10. Resources",
	//              nepali: "१०. भौतिक पूर्बाधार स्थिति विवरण",
	//      },
	//      child: [
	//              cableCar,
	//              tuin,
	//              bridge,
	//              powerHouse,
	//              transmissionLine,
	//              communication,
	//              market,
	//              mainRiver,
	//              fm,
	//              newspaper,
	//              cableNetwork,
	//              sabdaha,
	//      ],
	//      Icon: Star,
	// },
	// {
	//      title: { english: "11. Public Place", nepali: "११. सार्वजनिक स्थल" },
	//      child: [park, publicToilet, freeWifi],
	//      Icon: Star,
	// },
	// {
	//      title: { english: "12. Bank", nepali: "१२. बैंक तथा वित्तीय संस्था" },
	//      child: [bank],
	//      Icon: Star,
	// },
	// {
	//      title: { english: "13. Road Network", nepali: "१३. यातायात" },
	//      child: [busPark, roadNetwork, pavement, busRoute, busStation],
	//      Icon: Star,
	// },
	// {
	//      title: { english: "14. GROUND", nepali: "१४. युवा तथा खेलकुद" },
	//      child: [ground, sports],
	//      Icon: Star,
	// },
	// {
	//      title: {
	//              english: "15. Natural Disaster",
	//              nepali: "१५. विपद जोखिम तथा ब्यबस्थापन",
	//      },
	//      child: [
	//              disasterInformation,
	//              naturalDisaster,
	//              disasterEffect,
	//              disasterOrganization,
	//              socialResources,
	//              economicalResources,
	//              wasteMgmt,
	//              disasterInfrastructure,
	//              disasterWaterResource,
	//              physicalresource,
	//      ],
	//      Icon: Star,
	// },
	// {
	//      title: {
	//              english: "16. Community Building",
	//              nepali: "१६. सामुदायिक तथा सरकारी भवन",
	//      },
	//      child: [communityHall, quarter],
	//      Icon: Star,
	// },
	// {
	//      title: {
	//              english: "17. Project",
	//              nepali: "१७. गाउँपालिकाको कर्मचारी तथा परिवार विवरण",
	//      },
	//      child: [villageCouncilFamily, villageCounilStaff],
	//      Icon: Star,
	// },
	// {
	//      title: {
	//              english: "18. Business",
	//              nepali: "१८. व्यापार तथा व्यवसाय सम्बन्धी",
	//      },
	//      child: [mainBusiness],
	//      Icon: Star,
	// },
	// {
	//      title: {
	//              english: "20. Drinking Water Detail",
	//              nepali: "२०. खानेपानी टयंकी सम्नन्धि",
	//      },
	//      child: [drinkingWater],
	//      Icon: Star,
	// },
	// {
	//      title: {
	//              english: "23. Women, children and disable",
	//              nepali:
	//                      "२३. महिला, बालबालिका, अपाङ्ग भएका तथा लक्षित वर्ग सम्बन्धी विवरण",
	//      },
	//      child: [childHome, oldAgeHome, rehabCenter, helpless, genderCrime],
	//      Icon: Star,
	// },
	// {
	//      title: { english: "23. Others", nepali: "२३. अन्य" },
	//      child: [serviceCenter, mine],
	//      Icon: Star,
	// },
]; //survey ko card display garauda
